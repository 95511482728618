import { ICONS } from "assets/icons/index.js";
import { images } from "../../assets/images/index.js";
export const cardData = [
  {
    title: "Personal Brand",
    content:
      "Creators or individual brands aiming to expand growth across digital media.",
    image: images.personal,
    value: "PERSONAL_BRAND",
  },
  {
    title: "Business Entity",
    content:
      "Business poised to revolutionize online presence & supercharge growth.",
    image: images.business,
    value: "CREATOR",
  },
];

export const BrandCategory = [
  {
    id: 1,
    icon: ICONS?.Industry,
    value: "Retail Industry",
  },
  {
    id: 2,
    icon: ICONS?.HealthCare,
    value: "Health care",
    selected: false,
  },

  {
    id: 3,
    icon: ICONS?.eCommerce,
    value: "E-Commerce",
  },
  {
    id: 4,
    icon: ICONS?.DigitalAgencies,
    value: "Digital Agencies",
  },
  {
    id: 5,
    icon: ICONS?.Education,
    value: "Education",
  },
  // {
  //   id: 6,
  //   icon: ICONS?.FMCG,
  //   value: "FMCG",
  // },
  {
    id: 7,
    icon: ICONS?.travel,
    value: "Travel",
  },
  {
    id: 8,
    icon: ICONS?.other,
    value: "Other",
  },
];

export const BrandLocation = [
  {
    id: 1,
    value: "India",
    selected: false,
  },
  {
    id: 2,
    value: "United States",
  },
  {
    id: 3,
    value: "Japan",
  },
  {
    id: 4,
    value: "Brazil",
  },
  {
    id: 5,
    value: "Russia",
  },
  {
    id: 6,
    value: "Canada",
  },
  {
    id: 7,
    value: "Israel",
  },
];

export const BrandAbout = [
  {
    id: 1,
    value: "Facebook",
    selected: false,
  },
  {
    id: 2,
    value: "Instagram",
  },
  {
    id: 3,
    value: "Linked In",
  },
  {
    id: 4,
    value: "Website",
  },
  {
    id: 5,
    value: "Friend refer",
  },
];

export const BrandAge = [
  {
    id: 1,
    value: "Only Me",
    selected: false,
  },
  {
    id: 2,
    value: "1-10",
  },
  {
    id: 3,
    value: "11-25",
  },
  {
    id: 4,
    value: "26-50",
  },
  {
    id: 5,
    value: "50+",
  },
];
