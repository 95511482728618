export const updateEdgeCoordinatesFromAPI = ({ edges, coordinates }) => {
  return edges?.map((edge) => {
    const getCoordinate = coordinates?.find(
      (coordinate) => coordinate?.uniq_id === edge?.uniq_id
    );

    if (getCoordinate) {
      const { coordinates, ...rest } = edge;
      return {
        ...rest,
        coordinates: {
          start: {
            x: getCoordinate?.coordinates?.start?.x,
            y: getCoordinate?.coordinates?.start?.y,
          },
          end: {
            x: getCoordinate?.coordinates?.end?.x,
            y: getCoordinate?.coordinates?.end?.y,
          },
        },
      };
    }

    return edge;
  });
};

export const updateNodeCoordinatesFromAPI = ({
  nodes,
  coordinates,
  metrics,
  trigger_metrics,
}) => {
  const updatedNodes = nodes?.map((node) => {
    const getCoordinate = coordinates?.find(
      (coordinate) => coordinate?.uniq_id === nodes?.uniq_id
    );

    const getMetrics = metrics?.find(
      (metric) => metric?.sequence_node_uniq_id === node?.uniq_id
    );

    let data = {};

    if (getCoordinate) data['coordinate'] = getCoordinate;
    if (getMetrics) data['metrics'] = getMetrics;

    return {
      ...node,
      ...data,
    };
  });

  return updatedNodes?.map((n) => {
    if (n?.is_trigger)
      return {
        ...n,
        metrics: {
          triggered_count: trigger_metrics?.triggered_count || 0,
          failed_count: trigger_metrics?.failed_count || 0,
        },
      };
    return n;
  });
};

export const updatedEdgePosittion = ({ edges, startNodeId, coordinates }) => {
  const res = edges?.map((edge) => {
    if (edge?.start_id === startNodeId) return { ...edge };
    return edge;
  });
  return res;
};
