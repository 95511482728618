import { Modal, Popover } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { read, utils } from 'xlsx';

// api
import { whatsappBusinessNumbers } from '../../../../../../api/Api';
import { getSingleCampaign } from '../../../../../../api/apiClients/campaign';

// assets
import { ICONS } from '../../../../../../assets/icons';
import { images } from '../../../../../../assets/images';

// helper
import { cn } from '../../../../../../helper/cn';
import {
  formatTimeWithAmPm,
  getTimeString,
} from '../../../../../../helper/timeUtils';

// redux
import { updateToggleToast } from '../../../../../../reduxToolkit/appSlice';
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../../../test/jest-redux-hooks';

// hooks
import useToggle from 'hooks/useToggle';
import { useFileUploader } from '../../../../../../hooks/useFileUploader';

// components
import Xmark from '../../../../../../assets/customSVG/Xmark';
import Calendar from '../../../../../../components/commonComponents/calendar/Calendar';
import Loader from '../../../../../../components/commonComponents/Loader/Index';
import Header from '../../../../../../components/commonComponents/modals/Header';
import TemplateListContainer from '../../../../../../components/commonComponents/templates/TemplateListContainer';
import TemplatePreviewCard from '../../../../../../components/commonComponents/templates/TemplatePreviewCard';
import { Input } from '../../../../../../components/form/Input/Input';
import TimePickers from '../../../../../campaign/components/BroadcastSetting/TimePickers';
import Tabs from '../Tabs';

const SEND_SETTINGS = [
  {
    id: 1,
    label: 'Send Now',
    key: 'send_now',
  },
  {
    id: 2,
    label: 'Send Later',
    key: 'send_later',
  },
];

export default function New({
  data,
  onChange,
  open,
  onFilesSelected,
  sendLatterDate,
  setSendLatterDate,
  allowToEditFlow,
  resetSendLaterDate,
}) {
  const { campaign_id } = useParams();
  const { currentBrand } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const dropFilesref = useRef();
  const [isLoading, setIsLoading] = useToggle(false);
  const [isOpen, setIsOpen] = useToggle(false);
  const [isOpenModal, setIsOpenModal] = useToggle(false);
  const [isDatePopoupOpen, setIsDatePopoupOpen] = useToggle(false);
  const [isTimePopoupOpen, setIsTimePopoupOpen] = useToggle(false);
  const [list, setList] = useState([]);
  const [selectedTemplate, setSellectedTemplate] = useState(null);
  const [campaignDetails, setCampaignDetails] = useState(null);

  const [dateTimeError, setDateTimeError] = useState('');

  const {
    uploading,
    selectedFile,
    uploadFiles,
    handleDropFiles,
    error,
    clearFiles,
  } = useFileUploader();

  useEffect(() => {
    if (dropFilesref?.current?.focus()) {
      dropFilesref?.current?.addEventListener('drop', handleDrop);

      return () => {
        dropFilesref?.current?.removeEventListener('drop', handleDrop);
      };
    }
  }, []);

  useEffect(() => {
    if (currentBrand) {
      getwhatsappBusinessNumbers(currentBrand?.brand_id);
      getCampaignDetails(currentBrand?.brand_id);
    }
  }, [currentBrand]);

  const getwhatsappBusinessNumbers = async (brand_id) => {
    setIsLoading(true);
    try {
      const res = await whatsappBusinessNumbers(brand_id);
      setList(res?.data?.wapp_business_num);
      count = res?.data?.wapp_business_num_count;
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCampaignDetails = async (brand_id) => {
    setIsLoading(true);
    try {
      const res = await getSingleCampaign(brand_id, campaign_id);
      console.log('rwes', res?.data);
      setCampaignDetails(res?.data);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isOptionSelected = (option) => {
    return option?.id === data?.sendFrom?.id;
  };

  const handleFileChange = async (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const uploadFileRes = await uploadFiles(event, 'DOCUMENT');
      const { settings, broadcast_file_data } = await handleFile(
        selectedFiles[0]
      );

      onChange({
        type: 'UPDATE_NODE',
        id: data?.uniq_id,
        key: 'data',
        data: {
          ...data?.data,
          broadcast_file_data,
          file_path: uploadFileRes,
          file_name: event.target.files[0]?.name,
          settings: { ...data?.data?.settings, ...settings },
        },
      });
    }
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      const uploadFileRes = await handleDropFiles(newFiles, ['.xlsx']);
      const { settings, broadcast_file_data } = await handleFile(
        droppedFiles[0]
      );

      onChange({
        type: 'UPDATE_NODE',
        id: data?.uniq_id,
        key: 'data',
        data: {
          ...data?.data,
          broadcast_file_data,
          file_path: uploadFileRes,
          file_name: event.target.files[0]?.name,
          settings: { ...data?.data?.settings, ...settings },
        },
      });
    }
  };

  const handleClearFiles = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const newData = { ...data?.data };
    delete newData['broadcast_file_data'];
    delete newData['file_path'];
    delete newData['file_name'];

    onChange({
      type: 'UPDATE_NODE',
      id: data?.uniq_id,
      key: 'data',
      data: newData,
    });
  };

  const handleCanceltemplateModal = () => setIsOpenModal(false);

  const handleFile = async (file) => {
    const phoneRegex =
      /^(91 [0-9]{10}|65 [0-9]{8}|44 [0-9]{10}|971 [0-9]{9}|1 [0-9]{10})$/;

    try {
      const fileData = await file.arrayBuffer();
      const workbook = read(fileData, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const contacts = utils.sheet_to_json(worksheet);

      const {
        validData,
        invalidData,
        duplicateData,
        validContacts,
        duplicateContacts,
        invalidContacts,
      } = processContacts(contacts, phoneRegex);

      let segmentSettings = {};

      if (data?.data?.settings?.send_setting === 'send_later') {
        segmentSettings = {
          schedule_on: data?.data?.settings?.send_later_dt,
        };
      }

      return {
        broadcast_file_data: {
          valid_count: validContacts.length,
          duplicate_count: duplicateContacts.length,
          invalid_count: invalidContacts.length,
          file_date_valid: validData,
          file_date_in_valid: invalidData,
          file_date_duplicate: duplicateData,
        },
        settings: {
          segment_settings: [
            {
              segments: [
                {
                  id: 1,
                  end: validContacts.length,
                  flag: true,
                  delay: 0,
                  start: 0,
                  ...segmentSettings,
                },
              ],
            },
          ],
          contacts_count: validContacts.length,
          // send_from_phone_number: '555-015-6358',
          // send_from_phone_number_id: '251617158032885',
        },
      };
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const processContacts = (contacts, phoneRegex) => {
    const uniquePhoneNumbers = new Set();
    const validContacts = [];
    const duplicateContacts = [];
    const invalidContacts = [];

    const brandPlanLimits = {
      'Magnum Plan': 100000,
      'Growth Plan': 50000,
      'Starter Plan': 25000,
    };

    const limit =
      brandPlanLimits[currentBrand?.brand_billing_plan_name] || contacts.length;
    const contactsToValidate = contacts.slice(0, limit);

    contactsToValidate.forEach((contact) => {
      const phoneNumber = contact['mobile-number']
        ? String(contact['mobile-number']).trim()
        : null;

      // Validate only if there is a mobile number
      if (phoneNumber) {
        const countryCode = contact['country-code']
          ? String(contact['country-code']).trim()
          : '';

        if (!countryCode) {
          // Mark as invalid if either phone number or country code is missing
          invalidContacts.push({
            ...contact,
            reason: !countryCode
              ? 'Missing country code'
              : 'Missing phone number',
          });
          return;
        }

        const fullPhoneNumber = `${countryCode} ${phoneNumber}`;

        const isValid = phoneRegex.test(fullPhoneNumber);
        const isDuplicate = uniquePhoneNumbers.has(fullPhoneNumber);

        if (isValid && !isDuplicate) {
          uniquePhoneNumbers.add(fullPhoneNumber);
          validContacts.push(contact);
        } else if (isValid && isDuplicate) {
          duplicateContacts.push(contact);
        } else {
          invalidContacts.push(fullPhoneNumber);
        }
      }
    });
    const mapContactData = (contactList) =>
      contactList.map((contact) => {
        const getFieldValue = (field) => {
          let value = contact[field];

          if (value !== undefined && value !== null && value !== '') {
            value = String(value).trim();

            if (field === 'first-name' || field === 'last-name') {
              value = value.replace(/[^a-zA-Z\s]/g, '');
            }

            return value || null;
          }

          return null;
        };

        const details = {
          'email-id': getFieldValue('email-id'),
          'last-name': getFieldValue('last-name'),
          'media-url': getFieldValue('media-url'),
          'shop-name': getFieldValue('shop-name'),
          'first-name': getFieldValue('first-name'),
          'website-url': getFieldValue('website-url'),
          'mobile-number': getFieldValue('mobile-number'),
          'custom-variable1': getFieldValue('custom-variable1'),
          'custom-variable2': getFieldValue('custom-variable2'),
          'custom-variable3': getFieldValue('custom-variable3'),
          'custom-variable4': getFieldValue('custom-variable4'),
          'custom-variable5': getFieldValue('custom-variable5'),
          'otp-number': getFieldValue('otp-number'),
        };
        // Filter out keys with null values
        const filteredDetails = Object.fromEntries(
          Object.entries(details).filter(([_, value]) => value !== null)
        );

        return {
          row: contact.__rowNum__ || null,
          mobile1: getFieldValue('country-code')
            ? getFieldValue('country-code') +
              ' ' +
              getFieldValue('mobile-number')
            : getFieldValue('mobile-number') || null,
          details: filteredDetails,
        };
      });

    if (validContacts.length === 0 && contacts[0]['mobile-number']) {
      // setFileLoader(false);
      dispatch(
        updateToggleToast([
          ...toggleToast,
          {
            id: toggleToast.length + 1,
            content:
              'No contacts found. Add at least one contact to send a broadcast.',
            status: 'Error',
            duration: '',
          },
        ])
      );
      return;
    }

    const validData =
      validContacts.length > 0 ? mapContactData(validContacts) : 0;
    const invalidData =
      invalidContacts.length > 0 ? mapContactData(invalidContacts) : 0;
    const duplicateData =
      duplicateContacts.length > 0 ? mapContactData(duplicateContacts) : 0;

    return {
      validData,
      invalidData,
      duplicateData,
      validContacts,
      duplicateContacts,
      invalidContacts,
    };
  };

  const setHours = (value) => {
    setSendLatterDate((prev) => {
      const timeString = updateTime({
        date: prev?.selectedDate,
        hours: value,
        minutes: prev?.minutes,
        periods: prev?.periods,
      });
      return { ...prev, hours: value, selectedTime: timeString };
    });
  };

  const setMinutes = (value) => {
    setSendLatterDate((prev) => {
      const timeString = updateTime({
        date: prev?.selectedDate,
        hours: prev?.hours,
        minutes: value,
        periods: prev?.periods,
      });
      return { ...prev, minutes: value, selectedTime: timeString };
    });
  };

  const setPeriod = (value) => {
    setSendLatterDate((prev) => {
      const timeString = updateTime({
        date: prev?.selectedDate,
        hours: prev?.hours,
        minutes: prev?.minutes,
        periods: value,
      });
      return { ...prev, periods: value, selectedTime: timeString };
    });
  };

  const handleDateSelection = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');

    const startDate = campaignDetails?.starting_date_and_time;
    const endDate = campaignDetails?.ending_date_and_time;
    const datePart = moment(startDate?.split('T')[0], 'YYYY-MM-DD');
    const endDatePart = moment(endDate?.split('T')[0], 'YYYY-MM-DD');

    if (moment(formattedDate).isBefore(datePart, 'day')) return;

    if (moment(formattedDate).isAfter(endDatePart, 'day')) return;

    setSendLatterDate((prev) => {
      const timeString = updateTime({
        date: formattedDate,
        hours: prev?.hours,
        minutes: prev?.minutes,
        periods: prev?.periods,
      });

      return { ...prev, selectedDate: formattedDate, selectedTime: timeString };
    });

    setIsDatePopoupOpen(false);
  };

  const updateTime = ({ date, hours, minutes, periods }) => {
    const timeString = getTimeString(hours, minutes, periods);
    const sendLaterString = `${date} ${timeString}`;

    let segmentSettings = data?.data?.settings?.segment_settings;

    if (
      data?.data?.file_path &&
      data?.data?.file_name &&
      data?.data?.settings?.send_setting === 'send_later'
    ) {
      segmentSettings = [
        {
          segments: [
            {
              ...segmentSettings?.[0]?.['segments']?.[0],
              schedule_on: sendLaterString,
            },
          ],
        },
      ];
    }

    onChange({
      type: 'UPDATE_NODE',
      id: data?.uniq_id,
      primaryKey: 'data',
      key: 'settings',
      data: {
        ...data?.data?.settings,
        send_later_dt:
          data?.data?.settings?.send_setting === 'send_later'
            ? sendLaterString
            : '',
        segment_settings: segmentSettings,
      },
    });

    return timeString;
  };

  const handleTimeClick = () => {
    setIsTimePopoupOpen(false);
    setIsDatePopoupOpen(false);
    const newSelectedTime = getTimeString(
      sendLatterDate?.hours,
      sendLatterDate?.minutes,
      sendLatterDate?.periods
    );

    if (!sendLatterDate?.selectedTime) return;

    const currentTime = new Date();
    const selectedDateTime = new Date(
      `${sendLatterDate?.selectedDate} ${newSelectedTime}`
    );
    const timeDifference = selectedDateTime.getTime() - currentTime.getTime();

    if (sendLatterDate?.selectedDate && timeDifference < 60 * 60 * 1000) {
      setDateTimeError(
        'Scheduled time should be at least 60 minutes ahead of the current time'
      );
      return;
    }

    if (timeDifference >= 3600 * 1000) {
      const timeWithAmPm = formatTimeWithAmPm(newSelectedTime);
      setSendLatterDate((prev) => ({ ...prev, selectedTime: timeWithAmPm }));
    } else {
    }
  };

  const content = (
    <Calendar
      heading='Select duration'
      singleSelect={true}
      clickedDateFunc={handleDateSelection}
    />
  );

  return (
    <div>
      <div className='flex flex-col gap-3 py-5 border-b border-[var(--border-100)] '>
        <div className='flex flex-col gap-0.5'>
          <h5>Send From</h5>
          <p>Select a WhatsApp number you would like to send messages.</p>
        </div>
        <Popover
          open={isOpen}
          placement='bottomLeft'
          arrow={false}
          content={
            <ul
              className={`w-full min-w-[27rem] m-0 list-none rounded-2xl flex-col gap-1.5 overflow-y-scroll listScroll bg-[var(--white)] z-50 p-2.5 ${
                isOpen ? 'flex' : 'hidden'
              }`}>
              {list?.map((option) => {
                return (
                  <li
                    key={option?.id}
                    className='w-full h-[80px] flex items-center justify-between px-2.5 py-1.5 border-b border-[var(--border-100)] last:border-0 cursor-pointer'
                    onClick={() => {
                      setIsOpen(false);

                      onChange({
                        type: 'UPDATE_NODE',
                        id: data?.uniq_id,
                        primaryKey: 'data',
                        key: 'settings',
                        data: {
                          ...data?.data?.settings,
                          channel: 'whatsapp',
                          send_from_phone_name: option?.send_from_phone_name,
                          send_from_phone_number:
                            option?.send_from_phone_number,
                          send_from_phone_number_id:
                            option?.send_from_phone_number_id,
                        },
                      });
                    }}>
                    <div className='flex flex-col gap-2.5'>
                      <div className='flex items-center justify-between gap-5'>
                        <p>{option?.send_from_phone_name}</p>
                      </div>
                      <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-1.5 text-sm weight-medium text-[#616874] pr-3.5'>
                          {option?.country_code}
                          {option?.send_from_phone_number}
                        </div>
                        <div className='flex items-center gap-1.5 text-xs weight-semibold text-[var(--contentText)]  border-x border-[var(--border-100)] px-3.5'>
                          <p className='size-2.5 bg-[var(--primary)] rounded-sm'></p>
                          <p>High</p>
                        </div>
                        <div className='flex items-center gap-1.5 text-xs weight-semibold text-[var(--contentText)] px-3.5'>
                          {option?.daily_messaging_limit}
                        </div>
                      </div>
                    </div>
                    {isOptionSelected(option) && (
                      <img
                        src={ICONS?.Selected}
                        alt='selected'
                        className='size-5'
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          }>
          <div
            className='w-full h-12 flex items-center justify-between border boder-[var(--border-50)] px-5 py-1 rounded-lg cursor-pointer'
            onClick={() => {
              setIsOpen(!isOpen);
            }}>
            {data?.data?.settings?.send_from_phone_name ? (
              <div
                className={`flex-grow flex items-center gap-2.5 text-[var(--contentText)]`}>
                <p>{data?.data?.settings?.send_from_phone_name}</p>
                <p>
                  {' '}
                  {data?.data?.settings?.country_code}
                  {data?.data?.settings?.send_from_phone_number}
                </p>
              </div>
            ) : (
              <div className='w-full flex items-center font-md weight-medium text-[var(--font-300)]'>
                Please select
              </div>
            )}

            <div
              className={`transition-transform duration-200 ease-linear cursor-pointer ${
                isOpen ? '-rotate-180' : 'rotate-0'
              }`}>
              <div
                className={`border rounded-t-1.5 border-transparent border-t-[#777] translate-y-[25%]`}></div>
            </div>
          </div>
        </Popover>
      </div>

      <div
        ref={dropFilesref}
        className='flex flex-col gap-3 py-5 border-b border-[var(--border-100)]'
        onDrop={handleDrop}
        onDragOver={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}>
        <div className='flex flex-col gap-0.5'>
          <h5>Upload Audience</h5>
          <p>
            Upload a audience list to tailor your message to specific groups.
          </p>
        </div>
        {uploading ? (
          <div className='w-full h-32 bg-[var(--BG-50)] flex flex-col items-center justify-center border border-dashed rounded-2xl cursor-pointer'>
            <Loader
              Width={25}
              Height={25}
            />
          </div>
        ) : data?.data?.file_path ? (
          <div className='w-full h-auto flex items-center justify-between bg-gray-100 rounded-lg px-5 py-5'>
            <div className='flex items-center gap-2.5'>
              <img
                src={ICONS?.AudienceFileIcon}
                alt='csv icon'
                className='size-5'
              />
              <div>
                <h6 className='text-xs weight-medium text-[var(--contentBlack)]'>
                  {data?.data?.file_name}
                </h6>
              </div>
            </div>
            <button
              className='size-6 flex items-center justify-center rounded-full bg-gray-200 transition-all ease-linear duration-100 group/clearFiles hover:bg-red-200'
              onClick={handleClearFiles}>
              <Xmark
                width={9}
                height={9}
                color=''
                className='stroke-gray-600 group-hover/clearFiles:stroke-red-500'
              />
            </button>
          </div>
        ) : (
          <div className=''>
            <input
              type='file'
              hidden
              id='browse'
              onChange={handleFileChange}
              accept='.xlsx'
            />
            <label
              htmlFor='browse'
              className={cn(
                'w-full h-32 bg-[var(--BG-50)] flex flex-col items-center justify-center gap-1 border border-dashed rounded-2xl cursor-pointer',
                error ? 'border-[var(--fontRed)]' : 'border-[var(--border-100)]'
              )}>
              <img
                src={images?.File}
                alt='upload image'
              />
              <p className='text-center'>
                Click or Drag & Drop the file to upload <br /> .xlsv
              </p>
            </label>
          </div>
        )}
        {error && (
          <p className='text-[var(--fontRed)] text-sm weight-medium'>
            Unsupported format
          </p>
        )}
      </div>

      <div className='flex flex-col gap-3 py-5 border-b border-[var(--border-100)] '>
        <div className='flex flex-col gap-0.5'>
          <h5>Select Template</h5>
          <p>Select the approved templates to broadcast your audience.</p>
        </div>
        {selectedTemplate ? (
          <TemplatePreviewCard
            category={selectedTemplate?.category}
            categoryType={selectedTemplate?.subcategory_type}
            name={selectedTemplate?.name}
            language={selectedTemplate?.language}
            onChange={() => {
              if (allowToEditFlow) setIsOpenModal(true);
            }}
          />
        ) : (
          <div
            className='w-full h-auto py-1 flex items-center justify-center border border-[]'
            onClick={() => {
              setIsOpenModal(true);
            }}>
            Choose template
          </div>
        )}
      </div>

      <div className='flex flex-col gap-3 py-5 border-b border-[var(--border-100)] '>
        <div className='flex flex-col gap-0.5'>
          <h5>Send Settings</h5>
          <p>When should we send the broadcast?</p>
        </div>
        <Tabs
          tabs={SEND_SETTINGS}
          selected={{ key: data?.data?.settings?.send_setting || 'send_now' }}
          onTabChange={(value) => {
            // const selectedDateTime = `${sendLatterDate?.selectedDate} ${sendLatterDate?.selectedTime}`;

            if (value?.key === 'send_now') resetSendLaterDate();

            onChange({
              type: 'UPDATE_NODE',
              id: data?.uniq_id,
              primaryKey: 'data',
              key: 'settings',
              data: {
                ...data?.data?.settings,
                send_setting: value?.key,
                send_later_dt: value?.key === 'send_now' ? 'send_now' : '',
              },
            });
          }}
        />
        {data?.data?.settings?.send_setting === 'send_later' && (
          <div>
            <div className='w-100 Broadcast-Time flex-row align-center'>
              <div
                className='calender-card w-100 space-between flex-row'
                style={{ padding: '5px 10px' }}>
                <div className='Calendar-container flex-row w-100 space-between'>
                  <div className='aaaa flex-row  align-center'>
                    <span className='send-later-content pr-10'>Date </span>
                    <div>
                      <Popover
                        content={content}
                        trigger='click'
                        arrow={false}
                        open={isDatePopoupOpen}
                        onOpenChange={() => {
                          setIsDatePopoupOpen(!isDatePopoupOpen);
                          setIsTimePopoupOpen(false);
                        }}>
                        <div className='flex-row align-center pointer'>
                          <Input
                            type='none'
                            placeholder={'DD/MM/YY'}
                            iconPrefix={ICONS?.BroadcastCalendarIcon}
                            style={{
                              width: '7vw',
                              fontSize: '0.75vw',
                              fontWeight: '500',
                              padding: '10px',
                              color: 'var(--textBlack)',
                            }}
                            value={sendLatterDate?.selectedDate}
                            readOnly
                            className='pointer'
                            name='calender'
                          />
                        </div>
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className='input-container pointer pl-10'
                style={{ position: 'relative', marginLeft: '20px' }}>
                <div
                  className='flex-row align-center'
                  data-testid='Time-click'>
                  <span className='send-later-content pr-10'>Time</span>
                  <Popover
                    overlayClassName='time-popover'
                    content={
                      <TimePickers
                        hours={sendLatterDate?.hours}
                        minutes={sendLatterDate?.minutes}
                        periods={sendLatterDate?.periods}
                        setHours={setHours}
                        setMinutes={setMinutes}
                        setPeriod={setPeriod}
                        onOkClick={() => handleTimeClick(isTimePopoupOpen)}
                      />
                    }
                    trigger='click'
                    arrow={false}
                    open={isTimePopoupOpen}
                    onOpenChange={() => setIsTimePopoupOpen()}
                    data-testid='timeClick'>
                    <Input
                      placeholder='00:00 AM'
                      iconPrefix={ICONS?.BroadcastTimerIcon}
                      style={{
                        width: '7.5vw',
                        cursor: 'pointer',
                        fontSize: '0.75vw',
                        fontWeight: '500',
                        padding: '10px',
                      }}
                      value={`${sendLatterDate?.selectedTime}` || ''}
                      className='pointer1'
                    />
                  </Popover>
                </div>
              </div>
            </div>
            {error && (
              <div className='error-message flex-row align-center pl-10 ml-10 gap-2'>
                <img
                  src={ICONS?.alertIconError}
                  alt='alertIconError'
                />
                {error}
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        open={isOpenModal}
        centered
        footer={null}
        closable={false}
        mask={true}
        wrapClassName={'bg-[#00000095]'}
        onCancel={handleCanceltemplateModal}
        destroyOnClose
        width={'60vw'}
        style={{
          width: '100%',
          borderRadius: 20,
          boxShadow: '0px 10px 24px 0px rgba(79, 92, 128, 0.15)',
        }}>
        <Header
          title='Choose template'
          titleSize='largest'
          rootStyle='!px-[1.5vw] !py-[1vw]'
          handleCancel={handleCanceltemplateModal}
        />

        <TemplateListContainer
          className='h-[58vh]'
          sendTemplate={(templateData) => {
            setSellectedTemplate(templateData);

            onChange({
              type: 'UPDATE_NODE',
              id: data?.uniq_id,
              primaryKey: 'data',
              key: 'settings',
              data: {
                ...data?.data?.settings,
                template_id: templateData?.message_template_id,
                template_name: templateData?.name,
                template_subcategory_type: templateData?.subcategory_type,
                template_category: templateData?.category,
              },
            });

            handleCanceltemplateModal();
          }}
        />
      </Modal>
    </div>
  );
}
