import React, { useEffect, useReducer } from 'react';

// components
import MediaMessage from '../preview/MediaMessage';
import Template from '../preview/Template';
import {
  INITIAL_STATE,
  templateReducer,
} from '../../../../channels/reducers/templateReducer';
import TemplatePreview from '../../../../../components/commonComponents/templates/TemplatePreview';
import { cn } from '../../../../../helper/cn';

const NodeDetails = ({
  node,
  color,
  isConnected,
  onEdgeMouseDown,
  checkIsEdgeConnected,
  handleOnDeleteEdge,
  allowToEditFlow,
  nodeCurrentStatus,
  allowToShowEdge,
  previewMode,
}) => {
  const { type, component_type, data } = node;
  const [templateData, templateDispatch] = useReducer(
    templateReducer,
    INITIAL_STATE
  );

  const {
    id,
    category,
    categoryType,
    language,
    name,
    status,
    custom,
    authentication,
    carousel,
  } = templateData;

  const {
    header,
    headerVariable,
    body,
    bodyVariables,
    footer,
    buttons,
    buttonsOrder,
    ctaButtons,
    customButtons,
  } = custom;

  useEffect(() => {
    templateDispatch({
      type: 'update_template',
      data: {
        category: ['CUSTOM']?.includes(node?.data?.category)
          ? 'MARKETING'
          : node?.data?.category,
        subcategory_type: node?.data?.category,
        namespace: node?.data?.template_name,
        components: node?.data?.template,
        name: node?.data?.template_name,
        template_id: node?.data?.template_id,
        message_body: node?.data?.message_body,
      },
    });
  }, [node]);

  return (
    <div
      className={cn(
        'text-black z-0',
        previewMode ? 'w-full' : 'min-w-[270px] max-w-[270px]'
      )}>
      {node?.data?.type === 'template' ? (
        previewMode ? (
          <TemplatePreview
            category={category}
            header={header}
            headerVariable={headerVariable}
            body={body}
            bodyVariables={bodyVariables}
            footer={footer}
            bodyClassName={'min-w-[350px] max-w-[350px]'}
            carouseClassName={'w-full max-w-[450px] overflow-x-scroll'}
            buttons={buttons}
            buttonsOrder={buttonsOrder}
            ctaButtons={ctaButtons}
            customButtons={customButtons}
            useTemplates={true}
            authentication={authentication}
            // drawerType={ }
            carousel={carousel}
          />
        ) : (
          <Template
            data={data}
            color={color}
            category={node?.data?.category}
            categoryType={component_type}
            node={node}
            onEdgeMouseDown={onEdgeMouseDown}
            checkIsEdgeConnected={checkIsEdgeConnected}
            btnClassName='border border-[#E8E8EA]'
            btnIcon={false}
            allowToEditFlow={allowToEditFlow}
            rootClassName='min-w-full'
            nodeCurrentStatus={nodeCurrentStatus}
            allowToShowEdge={allowToShowEdge}
          />
        )
      ) : ['TEXT', 'MEDIA', 'BUTTON', 'LIST']?.includes(type) ? (
        <div
          className={cn(
            'text-black',
            previewMode
              ? 'min-w-[350px] max-w-[350px] p-2.5'
              : 'min-w-[270px] max-w-[270px]'
          )}>
          <MediaMessage
            previewMode={previewMode}
            node={node}
            color={color}
            onEdgeMouseDown={onEdgeMouseDown}
            checkIsEdgeConnected={checkIsEdgeConnected}
            allowToEditFlow={allowToEditFlow}
            allowToShowEdge={allowToShowEdge}
            nodeCurrentStatus={nodeCurrentStatus}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default NodeDetails;
