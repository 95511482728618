import { ICONS } from '../../../assets/icons/index';
import { images } from '../../../assets/images/index';
export const brandCategory = [
  {
    id: 1,
    icon: ICONS?.BillingMedicalIcon,
    value: 'Pharmacy & Medical Care',
    selected: false,
  },
  {
    id: 2,
    icon: ICONS?.BillingStoreIcon,
    value: 'Retail Industry',
    selected: false,
  },
  {
    id: 3,
    icon: ICONS?.BillingCartIcon,
    value: 'E-Commerce',
    selected: false,
  },
  {
    id: 4,
    icon: ICONS?.BillingCreatorIcon,
    value: 'Digital Agencies',
    selected: false,
  },
  {
    id: 5,
    icon: ICONS?.BillingCreatorIcon,
    value: 'Creator / Influencers',
    selected: false,
  },
  {
    id: 6,
    icon: ICONS?.BillingVideoIcon,
    value: 'Media Production',
    selected: false,
  },
  {
    id: 7,
    icon: ICONS?.BillingOtherIcon,
    value: 'Other',
    selected: false,
  },
];

export const BrandList = [
  {
    id: 1,
    name: 'Brand Owner',
    content: 'Enter brand name',
  },
  {
    id: 2,
    name: 'Phone number',
    content: 'Enter Phone number',
  },
  {
    id: 3,
    name: 'Country',
    content: 'Enter your country',
  },
  {
    id: 4,
    name: 'Zip/Postal code',
    content: 'Enter your Zip code',
  },
  {
    id: 5,
    name: ' Email address',
    content: 'Enter your Email id',
  },
  {
    id: 6,
    name: 'Address',
    content: 'Enter your address',
  },
  {
    id: 7,
    name: 'City ',
    content: 'Enter your city',
  },
];
export const BillingCardList = [
  {
    id: 1,

    name: 'Invoice-0001',
    Billing_date: 'Oct 6',
    plan_name: 'Starter',
    amt: '$25.00',
  },
  {
    id: 2,
    name: 'Invoice-0002',
    Billing_date: 'Sep 6',
    plan_name: 'Starter',
    amt: '$25.00',
  },
  {
    id: 3,
    name: 'Invoice-0003',
    Billing_date: 'Aug 6',
    plan_name: 'Starter',
    amt: '$25.00',
  },
  {
    id: 4,
    name: 'Invoice-0010',
    Billing_date: 'Jul 6',
    plan_name: 'Starter',
    amt: '$25.00',
  },
  {
    id: 5,
    name: 'Invoice-0010',
    Billing_date: 'Jul 6',
    plan_name: 'Starter',
    amt: '$25.00',
  },
  {
    id: 6,
    name: 'Invoice-0010',
    Billing_date: 'Jul 6',
    plan_name: 'Starter',
    amt: '$25.00',
  },
  {
    id: 7,
    name: 'Invoice-0010',
    Billing_date: 'Jul 6',
    plan_name: 'Starter',
    amt: '$25.00',
  },
];

export const BrandMemberRoleList = [
  // {
  //   id: 1,
  //   value: 'Owner',

  // },
  {
    id: 2,
    value: 'Account manager',
    subtitle: 'Has access to Account, billing & plans, brand and users',
  },
  {
    id: 3,
    value: 'Brand manager',
    subtitle: 'Has access to brand, goal & budget, users',
  },
  {
    id: 4,
    value: 'Campaign manager',
    subtitle: 'Has access to OKR, campaigns, reports & analytics, audience',
  },
  {
    id: 5,
    value: 'Content manager',
    subtitle: 'Has access to tasks, approval and posting',
  },
  {
    id: 6,
    value: 'Member',
    subtitle: 'Has access to workspace, action item',
  },
];

export const CreditList = [
  {
    id: 1,
    icon: images?.BrandMarketingIcon,
    name: 'Marketing',
    count: '1000',
  },
  {
    id: 2,
    icon: images?.BrandUtilityIcon,
    name: 'Utility',
    count: '1000',
  },
  {
    id: 3,
    icon: images?.BrandAuthenticationIcon,
    name: 'Authentication',
    count: '1000',
  },
  {
    id: 4,
    icon: images?.BrandServiceIcon,
    name: 'Services',
    count: '1000',
  },
];

export const YearList = [
  {
    id: 1,
    name: 'YEAR',
    subFilter: [
      {
        id: 1,
        year: 2026,
      },
      {
        id: 2,
        year: 2025,
      },
      {
        id: 3,
        year: 2024,
      },
      {
        id: 4,
        year: 2023,
      },
      {
        id: 5,
        year: 2022,
      },
      {
        id: 6,
        year: 2021,
      },
      {
        id: 7,
        year: 2020,
      },
      {
        id: 8,
        year: 2019,
      },
      {
        id: 9,
        year: 2018,
      },
    ],
  },
];

export const CreditsList = [
  {
    id: 1,
    amt: '₹50',
  },
  {
    id: 2,
    amt: '₹100',
  },
  {
    id: 3,
    amt: '₹200',
  },
  {
    id: 4,
    amt: '₹500',
  },
  {
    id: 6,
    amt: '₹1000',
  },
];
