import React, { useCallback, useEffect, useRef } from 'react';

// assets
import EditIcon from '../../../../../assets/customSVG/EditIcon';
import Loader from '../../../../../components/commonComponents/Loader/Index';

// constants
import { HEADER_DETAILS } from '../../../constants/sequence';

// helpers
import { cn } from '../../../../../helper/cn';

// hooks
import { useFileUploader } from '../../../../../hooks/useFileUploader';

// components
import AudioMessagePreview from '../../../../inbox/components/conversation/AudioMessagePreview';
import DocumentMessagePreview from '../../../../inbox/components/conversation/DocumentMessagePreview';
import ImageMessagePreview from '../../../../inbox/components/conversation/ImageMessagePreview';
import VideoMessagePreview from '../../../../inbox/components/conversation/VideoMessagePreview';

import Tabs from './Tabs';
import Input from '../Input';

const HeaderMessage = ({
  node,
  labelClassName,
  onChange,
  setError,
  selectedTab,
  setSelectedTab,
  headerList,
  setHeaderList,
  mediaRef,
}) => {
  const uploadRef = useRef();

  const { uploading, uploadFiles, error, clearFiles } = useFileUploader();

  useEffect(() => {
    setError(error);
  }, [error]);

  useEffect(() => {
    setError(uploading);
  }, [uploading]);

  const onTabChange = (data) => {
    clearFiles();

    const nodeType = node?.type;
    const dataKey = data?.key?.toLowerCase();

    let payload = { key: 'data', data: { type: dataKey } };

    if (nodeType === 'MEDIA') {
      payload.data[dataKey] = { link: '' };
    } else if (['BUTTON', 'LIST'].includes(nodeType)) {
      payload = {
        primaryKey: 'data',
        secondaryKey: 'interactive',
        key: 'header',
        data: {
          type: dataKey,
          [dataKey]: data?.key === 'TEXT' ? '' : { link: '' },
        },
      };
    }

    setSelectedTab(data);
    onChange({
      type: 'UPDATE_NODE',
      id: node?.uniq_id,
      ...payload,
    });
  };

  const key = useCallback(() => {
    return selectedTab?.key;
  }, [selectedTab, node]);

  const handleFileUpload = useCallback(
    async (e) => {
      const res = await uploadFiles(e, selectedTab?.key);

      let payload = {};

      if (['BUTTON', 'LIST']?.includes(node?.type)) {
        payload = {
          primaryKey: 'data',
          secondaryKey: 'interactive',
          key: 'header',
          data: {
            type: selectedTab?.key?.toLowerCase(),
            [`${selectedTab?.key?.toLowerCase()}`]: {
              link: res,
            },
          },
        };
      } else {
        payload = {
          key: 'data',
          data: {
            type: selectedTab?.key?.toLowerCase(),
            [`${selectedTab?.key?.toLowerCase()}`]: {
              link: res,
            },
          },
        };
      }

      onChange({
        type: 'UPDATE_NODE',
        id: node?.uniq_id,
        ...payload,
      });
    },
    [mediaRef, selectedTab, node, uploading]
  );

  const getMediaData = (type) => {
    return ['BUTTON', 'LIST']?.includes(node?.type)
      ? node?.data?.interactive?.header?.[type]
      : node?.data?.[type];
  };

  return (
    <div className=''>
      <div className=''>
        <h5 className={cn('', labelClassName)}>Header</h5>
        <div
          ref={mediaRef}
          className='flex items-center gap-3.5'>
          <Tabs
            tabs={headerList}
            selected={selectedTab}
            onTabChange={onTabChange}
          />
        </div>
      </div>

      {uploading ? (
        <div className='w-full h-44 flex items-center justify-center'>
          <Loader
            Width={20}
            Height={20}
          />
        </div>
      ) : key() !== 'NONE' ? (
        <>
          {selectedTab?.key !== 'TEXT' ? (
            <>
              {(['MEDIA']?.includes(node?.type) &&
                node?.data?.[selectedTab?.key?.toLowerCase()]?.link !== '') ||
              (['BUTTON']?.includes(node?.type) &&
                node?.data?.interactive?.header?.[
                  selectedTab?.key?.toLowerCase()
                ]?.link !== '') ? (
                <>
                  <div className='w-full flex mt-2.5 h-40 rounded-2xl'>
                    <div className='w-fit min-w-full relative group/header'>
                      {key() === 'IMAGE' ? (
                        <ImageMessagePreview
                          url={getMediaData('image')?.link}
                          caption={getMediaData('image')?.caption}
                          rootClassName={
                            'max-w-64 min-w-full h-full p-0 rounded-lg overflow-hidden'
                          }
                          className={'w-full p-0'}
                          imageClassName={'h-[320px]'}
                        />
                      ) : key() === 'VIDEO' ? (
                        <VideoMessagePreview
                          url={getMediaData('video')?.link}
                          caption={getMediaData('video')?.caption}
                          rootClassName={
                            'max-w-64 min-w-full h-full !p-0 rounded-lg overflow-hidden'
                          }
                          className={'w-full'}
                          videoClassName={'h-[320px]'}
                        />
                      ) : key() === 'DOCUMENT' ? (
                        <DocumentMessagePreview
                          url={getMediaData('document')?.link}
                          caption={getMediaData('document')?.caption}
                          fileName={node?.data?.document?.filename}
                          rootClassName={
                            'max-w-full min-w-full h-full !p-0 rounded-2xl'
                          }
                          className={'w-full'}
                          documentClassName={'h-[260px]'}
                        />
                      ) : key() === 'AUDIO' ? (
                        <AudioMessagePreview
                          url={getMediaData('audio')?.link}
                          caption={getMediaData('audio')?.caption}
                          rootClassName={
                            'max-w-full min-w-full h-full !p-0 rounded-lg !shadow-none'
                          }
                          className={'min-w-64 w-full'}
                          audioClassName={'w-full h-[80px] py-4'}
                        />
                      ) : (
                        ''
                      )}
                      <div
                        className={cn(
                          'w-full absolute hidden group-hover/header:!flex',
                          key() === 'AUDIO'
                            ? 'w-4 h-7 -top-3.5 right-5 z-[999]'
                            : 'size-8 top-1 right-1.5'
                        )}>
                        <button
                          className={cn(
                            'bg-[var(--primary)] text-white text-sm weight-medium rounded-lg',
                            key() === 'AUDIO'
                              ? 'px-1.5 py-0.5'
                              : 'px-2.5 py-0.5'
                          )}
                          onClick={() => {
                            uploadRef.current.value = null;
                            uploadRef?.current?.click();
                            // clearFiles();
                          }}>
                          <EditIcon
                            width={14}
                            height={14}
                            color='white'
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  {error && (
                    <p
                      className={cn(
                        'mt-2.5 text-sm weight-medium text-red-400'
                      )}>
                      {HEADER_DETAILS[selectedTab?.key]?.note}
                    </p>
                  )}
                </>
              ) : (
                <div
                  className='mt-6'
                  id='uploadImg'>
                  {uploading ? (
                    <div className='w-full h-36 flex items-center justify-center'>
                      <Loader
                        Width={20}
                        Height={20}
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        className={cn(
                          'w-full h-44 flex items-center justify-center border-[1.5px] border-dashed rounded-[10px]',
                          error
                            ? 'border-red-500 bg-red-200'
                            : 'border-[#D1D3D8] bg-white'
                        )}
                        onClick={() => {
                          uploadRef.current.value = null;
                          uploadRef?.current?.click();
                          clearFiles();
                        }}>
                        <p
                          className={cn(
                            'text-sm weight-medium',
                            error ? 'text-red-500' : 'text-[var(--font-700)]'
                          )}>
                          {HEADER_DETAILS[selectedTab?.key]?.label}
                        </p>
                      </div>
                      <p
                        className={cn(
                          'mt-2.5 text-xs weight-medium',
                          error ? 'text-red-400' : 'text-[var(--font-700)]'
                        )}>
                        {HEADER_DETAILS[selectedTab?.key]?.note}
                      </p>
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              {['LIST', 'BUTTON']?.includes(node?.type) &&
                node?.data?.interactive?.header?.[
                  selectedTab?.key?.toLowerCase()
                ]?.text !== '' && (
                  <div className='w-full mt-2.5 h-40'>
                    <Input
                      limit={60}
                      value={node?.data?.interactive?.header?.text}
                      onInputChange={(value) => {
                        console.log('object', node?.data?.interactive?.header);
                        onChange({
                          type: 'UPDATE_NODE',
                          id: node?.uniq_id,
                          primaryKey: 'data',
                          secondaryKey: 'interactive',
                          key: 'header',
                          data: {
                            ...node?.data?.interactive?.header,
                            text: value,
                          },
                        });
                      }}
                    />
                  </div>
                )}
            </>
          )}
        </>
      ) : (
        ''
      )}

      <input
        type='file'
        ref={uploadRef}
        accept={HEADER_DETAILS[selectedTab?.key]?.accept}
        onChange={handleFileUpload}
        id='uploadImg'
        hidden
      />
    </div>
  );
};

export default HeaderMessage;
