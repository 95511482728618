import React, { useCallback, useEffect, useReducer, useState } from "react";
import { ICONS } from "../../../assets/icons";
import SearchBar from "../../../components/commonComponents/searchBar";

import { cn } from "../../../helper/cn";

// hooks
import { useSearch } from "hooks/useSearch";
import { Popover } from "antd";

// reducers
import {
  INITIAL_STATE,
  onBoardingReducer,
} from "../../../hooks/auth/onBoardingReducer";

//constants
import {
  ROLELIST,
  ACCOUNT_STATUS_LIST,
  BRANDLIST,
  BRAND_STATUS_LIST,
} from "../constants/home";
import CheckBoxSqure from "../../../components/commonComponents/checkbox/CheckBoxSqure";
import ClearFilters from "../../../assets/customSVG/ClearFilters";
import AccountUsersList from "./AccountUsersList";

import {
  accountUsersListApi,
  brandAssignRoleListApi,
  brandUsersListApi,
} from "../api/Api";
import { updateCurrentBrand } from "../../../reduxToolkit/appSlice";
import { useAspSelector } from "../../../test/jest-redux-hooks";
import useToggle from "../../../hooks/useToggle";
import { useParams } from "react-router-dom";

const AccountUsersTable = ({ activeNav, setBrandPopover }) => {
  const [inviteModal, setInviteModal] = useState(false);
  const [accountUsersList, setAccountUsersList] = useState([]);
  const { searchKey, setSearchKey, onChange: onSearchChange } = useSearch("");
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useToggle(false);
  const [isLoading, setIsLoading] = useToggle(false);

  const [count, setCount] = useState(0);

  const { id } = useParams();

  let page = 1;
  let limit = 10;

  //account role filter state
  const [roleList, setRoleList] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState([]);

  //account status filter state
  const [accountStatusList, setAccountStatusList] = useState(null);
  const [selectedAccountStatusId, setAccountStatusId] = useState([]);

  // assigning users list
  const [assignUsersList, setAssignUsersList] = useState([]);

  const [data, dispatch] = useReducer(onBoardingReducer, INITIAL_STATE);
  const { currentAccount, currentBrand } = useAspSelector(
    (state) => state?.app
  );

  const onChange = (type, primaryKey, key, value) => {
    dispatch({ type, primaryKey, key, value });
  };

  const BRANDROLELISTS = activeNav ? BRANDLIST : ROLELIST;

  const statusAssignFilters = activeNav
    ? BRAND_STATUS_LIST
    : ACCOUNT_STATUS_LIST;

  useEffect(() => {
    setRoleList(BRANDROLELISTS);
    setAccountStatusList(statusAssignFilters);
  }, []);

  // useEffect(() => {
  //   // getSelectedRoleListId();
  //   getSelectedAccountStatusListId();
  // }, [BRANDROLELISTS, accountStatusList]);

  //-----------------------------role filter-------------------------

  // const getSelectedRoleListId = useCallback(
  //   () => setSelectedRoleId(roleList?.filter((t) => t?.isSelected)),
  //   [roleList]
  // );

  const onRoleChange = (data) => {
    const res = roleList?.map((s) => {
      if (s?.id === data?.id) {
        return { ...s, isSelected: !s?.isSelected }; // Toggle selection for the clicked item
      }
      return s;
    });
    const filterValues = res?.filter((item) => item?.isSelected && item);

    setSelectedRoleId(filterValues);
    setRoleList(res);
  };

  const clearRoleList = () => {
    const res = roleList?.map((t) => {
      return { ...t, isSelected: false };
    });

    setRoleList(res);
    setSelectedRoleId([]);
  };

  //--------------------------------status filter----------------------------

  // const getSelectedAccountStatusListId = useCallback(
  //   () => setAccountStatusId(accountStatusList?.filter((t) => t?.isSelected)),
  //   [accountStatusList]
  // );

  const onAccountStatusChange = (data) => {
    const res = accountStatusList?.map((s) => {
      if (s?.id === data?.id) return { ...s, isSelected: !s?.isSelected };
      return s;
    });
    setAccountStatusId(res?.filter((t) => t?.isSelected));
    setAccountStatusList(res);
  };

  const clearAccountStatusList = () => {
    const res = accountStatusList?.map((t) => {
      return { ...t, isSelected: false };
    });

    setAccountStatusList(res);
    setAccountStatusId([]);
  };

  useEffect(() => {
    if (searchKey) {
      const timeout = setTimeout(() => {
        const payload = {
          page: page,
          limit: limit,
          search: searchKey,
          brand_id: activeNav && id,
        };
        fetchAccountList(true, currentAccount?.account_id, payload);
      }, [500]);

      return () => clearTimeout(timeout);
    }
  }, [searchKey]);

  const fetchAccountList = async (loading, account_id, payload) => {
    if (loading) setIsLoadingSkeleton(true);
    try {
      const apiCall = activeNav
        ? () => brandUsersListApi(id, account_id, payload)
        : () => accountUsersListApi(account_id, payload);

      const res = await apiCall();
      setAccountUsersList(res?.data);
      setIsLoadingSkeleton(false);
      setCount(res?.data?.total_users_count);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoadingSkeleton(false);
    }
  };

  const fetchAccounts = useCallback(() => {
    if (!searchKey) {
      const payload = {
        page: page,
        limit: limit,
        search: searchKey,
        role_ids: selectedRoleId?.map((data) => data?.role_ids),
        status: selectedAccountStatusId?.map((data) => data?.status),
        brand_id: activeNav && id,
      };

      fetchAccountList(true, currentAccount?.account_id, payload);
    }
  }, [
    page,
    limit,
    selectedRoleId,
    selectedAccountStatusId,
    currentAccount,
    searchKey,
    currentBrand,
  ]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  const loadNextPage = async () => {
    setIsLoadingSkeleton(false);
    limit += 10;

    const payload = {
      page: page,
      limit: limit,
      search: searchKey,
    };
    if (activeNav) {
      await fetchAccountList(isLoading, id, payload);
    } else {
      await fetchAccountList(isLoading, currentAccount?.account_id, payload);
    }
  };

  const allowAllClear = useCallback(() => {
    return selectedRoleId?.length > 0 || selectedAccountStatusId?.length > 0;
  }, [selectedRoleId, selectedAccountStatusId]);

  // brand assign

  const fetchbrandAssignRoles = () => {
    const payLoad = {
      page: page,
      limit: 100,
      search: searchKey,
      role_ids: 3,
      status: [10, 5, 4],
    };
    brandAssignRoleListApi(currentAccount?.account_id, id, payLoad).then(
      (res) => {
        if (res?.status === 200) {
          setAssignUsersList(res?.data?.users);
        }
      }
    );
  };

  useEffect(() => {
    if (activeNav) {
      fetchbrandAssignRoles(currentAccount?.account_id, currentBrand?.brand_id);
    }
  }, [activeNav, inviteModal]);

  return (
    <>
      <div className="w-full h-full bg-white rounded-2xl px-5">
        {activeNav === "Users" && (
          <div className="flex pt-15">
            <span className="font16px-600 pr-2">Invited Users</span>
            {/* <img src={ICONS?.conversationAction} /> */}
          </div>
        )}

        <div className="flex justify-between pb-6 pl-3 border-b-[1px] items-center py-5">
          <SearchBar
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            onChange={onSearchChange}
            loading={false}
            expandable={true}
          />

          <div className="flex items-center gap-3">
            <Popover
              trigger={["click"]}
              arrow={false}
              placement="bottom"
              content={
                <div className="min-w-56 px-3.5 py-2.5">
                  <div className="w-full h-8 flex items-center justify-between gap-5 py-2.5 text-sm weight-medium">
                    <div className="text-[var(--font-400)]">Role</div>
                    <div
                      className={cn(
                        "text-[#F96056]",
                        selectedRoleId?.length > 0
                          ? "cursor-pointer opacity-100"
                          : "cursor-not-allowed opacity-50"
                      )}
                      onClick={() => {
                        if (selectedRoleId?.length > 0) clearRoleList();
                      }}
                    >
                      Clear
                    </div>
                  </div>
                  {roleList?.map((s) => {
                    return (
                      <div
                        className={cn(
                          "w-full h-12 flex items-center gap-2.5 py-1 cursor-pointer pl-10"
                        )}
                        onClick={() => {
                          onRoleChange(s);
                        }}
                        key={s?.id}
                      >
                        <CheckBoxSqure
                          value={s?.isSelected}
                          onChange={(e) => {}}
                          className="size-4"
                        />
                        <p>{s?.label}</p>
                      </div>
                    );
                  })}
                </div>
              }
            >
              <div className="group/goal bg-[var(--channelButton)] flex items-center gap-2.5 px-2 py-2 rounded-[0.4vw] cursor-pointer">
                <div className="campaign-filters flex items-center">
                  Role
                  <div
                    className={`ml-1 
              ${
                selectedRoleId?.length === roleList?.length ||
                selectedRoleId?.length === 0
                  ? ""
                  : "rounded-full bg-[#6940F2] text-white text-[0.812rem] !w-[1.3vw] !h-[1.3vw] flex justify-center items-center"
              }`}
                  >
                    {selectedRoleId?.length === roleList?.length ||
                    selectedRoleId?.length === 0
                      ? "(All)"
                      : selectedRoleId?.length}
                  </div>
                </div>
                <div
                  className={cn(
                    "transition-transform duration-200 ease-linear"
                    // false ? "-rotate-180" : "rotate-0",
                    // isLoading ? "cursor-not-allowed" : "cursor-pointer"
                  )}
                >
                  <div
                    className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                  ></div>
                </div>
              </div>
            </Popover>

            <Popover
              trigger={["click"]}
              arrow={false}
              placement="bottom"
              content={
                <div className="min-w-56 px-3.5 py-2.5">
                  <div className="w-full flex items-center justify-between gap-5 py-2.5 text-sm weight-medium">
                    <div className="text-[var(--font-400)]">Status</div>
                    <div
                      className={cn(
                        "text-[#F96056]",
                        selectedAccountStatusId?.length > 0
                          ? "cursor-pointer opacity-100"
                          : "cursor-not-allowed opacity-50"
                      )}
                      onClick={() => {
                        if (selectedAccountStatusId?.length > 0)
                          clearAccountStatusList();
                      }}
                    >
                      Clear
                    </div>
                  </div>

                  {accountStatusList?.map((s) => {
                    return (
                      <div
                        className={cn(
                          "w-full h-12 flex items-center gap-2.5 py-1 cursor-pointer pl-10"
                        )}
                        onClick={() => {
                          onAccountStatusChange(s);
                        }}
                        key={s?.id}
                      >
                        <CheckBoxSqure
                          value={s?.isSelected}
                          onChange={(e) => {}}
                          className="size-4"
                        />
                        <p>{s?.label}</p>
                      </div>
                    );
                  })}
                </div>
              }
            >
              <div className="group/goal bg-[var(--channelButton)] flex items-center gap-2.5 px-2 py-2 rounded-[0.4vw] cursor-pointer">
                <div className="campaign-filters flex items-center">
                  Status
                  <div
                    className={`ml-1
               ${
                 selectedAccountStatusId?.length ===
                   accountStatusList?.length ||
                 selectedAccountStatusId?.length === 0
                   ? ""
                   : "rounded-full bg-[#6940F2] text-white text-[0.812rem] !w-[1.3vw] !h-[1.3vw] flex justify-center items-center"
               }`}
                  >
                    {selectedAccountStatusId?.length ===
                      accountStatusList?.length ||
                    selectedAccountStatusId?.length === 0
                      ? "(All)"
                      : selectedAccountStatusId?.length}
                  </div>
                </div>
                <div
                  className={cn(
                    "transition-transform duration-200 ease-linear"
                    // false ? "-rotate-180" : "rotate-0",
                    // isLoading ? "cursor-not-allowed" : "cursor-pointer"
                  )}
                >
                  <div
                    className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                  ></div>
                </div>
              </div>
            </Popover>

            <div
              className={cn(
                `group/status flex items-center gap-2.5 hover:bg-[var(--BG-25)] px-2.5 py-2.5 rounded-lg ${
                  allowAllClear()
                    ? "cursor-pointer opacity-100"
                    : "cursor-not-allowed opacity-50"
                }`
              )}
              onClick={() => {
                if (allowAllClear()) {
                  clearRoleList();
                  clearAccountStatusList();
                }
              }}
            >
              <ClearFilters
                width={16}
                height={16}
                color={
                  allowAllClear() ? "var(--contentText)" : "var(--font-600)"
                }
              />
            </div>

            {currentAccount?.role !== "Account User" &&
              currentBrand?.role !== "Campaign Manager" && (
                <button
                  className={` border py-2  px-2 ${
                    activeNav && accountUsersList?.users?.length === 0
                      ? "disable-button cursor-not-allowed"
                      : !activeNav
                      ? "button-primary"
                      : "button-primary"
                  }`}
                  onClick={() => {
                    if (activeNav && accountUsersList?.users?.length > 0) {
                      setInviteModal(true);
                    } else if (!activeNav) {
                      setInviteModal(true);
                    }
                  }}
                >
                  {activeNav ? "Assign User(s)" : "Invite new user"}
                </button>
              )}
          </div>
        </div>
        <AccountUsersList
          list={accountUsersList}
          data={data?.teams}
          inviteModal={inviteModal}
          onChange={onChange}
          setInviteModal={setInviteModal}
          isLoadingSkeleton={isLoadingSkeleton}
          loadNextPage={loadNextPage}
          count={count}
          fetchAccounts={fetchAccounts}
          setSearchKey={setSearchKey}
          clearAccountStatusList={clearAccountStatusList}
          clearRoleList={clearRoleList}
          activeNav={activeNav}
          assignUsersList={assignUsersList}
          setBrandPopover={setBrandPopover}
          searchKey={searchKey}
          selectedAccountStatusId={selectedAccountStatusId}
          selectedRoleId={selectedRoleId}
        />
      </div>
    </>
  );
};

export default AccountUsersTable;
