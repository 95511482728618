import React from 'react';
import BroadcastTrigger from './broadcast';
import EditMessages from './EditMessages';
import useToggle from '../../../../../hooks/useToggle';
import TriggerModel from '../triggers/TriggerModel';
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../../test/jest-redux-hooks';
import { useParams } from 'react-router-dom';
import { updateToggleToast } from '../../../../../reduxToolkit/appSlice';

const NodeEditMode = ({
  originalData,
  node,
  onChange,
  onTriggerClick,
  findEmptySpace,
  allowToAddNode,
  toggleNodeEditMode,
  toggleLastNode,
  allowToEditFlow,
  handleOnDeleteEdge,
  removeEdges,
  updateEdgesCoordinates,
}) => {
  const [openTriggerModel, setOpenTriggerModel] = useToggle(false);

  return (
    <>
      {['TEXT', 'MEDIA', 'BUTTON', 'TEMPLATE', 'LIST']?.includes(
        node?.type
      ) && (
        <EditMessages
          originalData={originalData}
          node={node}
          onTriggerClick={onTriggerClick}
          onChange={onChange}
          findEmptySpace={findEmptySpace}
          toggleNodeEditMode={toggleNodeEditMode}
          toggleLastNode={toggleLastNode}
          allowToEditFlow={allowToEditFlow}
          handleOnDeleteEdge={handleOnDeleteEdge}
          removeEdges={removeEdges}
          updateEdgesCoordinates={updateEdgesCoordinates}
        />
      )}
      {node?.type === 'TRIGGER' && (
        <BroadcastTrigger
          node={node}
          onChange={onChange}
          toggleNodeEditMode={toggleNodeEditMode}
          allowToEditFlow={allowToEditFlow}
          removeEdges={removeEdges}
        />
      )}
      {node?.type === 'NEW' && (
        <TriggerModel
          type={'tab'}
          open={openTriggerModel}
          setOpen={setOpenTriggerModel}
          onTriggerClick={(data) => {
            if (allowToEditFlow) onTriggerClick({ data, node });
          }}
          allow={allowToEditFlow && allowToAddNode()?.allow}
        />
      )}
    </>
  );
};

export default NodeEditMode;
