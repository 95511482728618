import {
  onChangeObject,
  updateCommonReducerData,
  updateCommonReducerValue,
} from '../helpers';
import { updatedEdgePosittion } from '../helpers/sequence/edges';
import {
  addButtonToInteractiveMessage,
  addRowInSectionList,
  addSectionListNode,
  deleteButtonToInteractiveMessage,
  deleteListFromNode,
  deleteNode,
  updateButtonToInteractiveMessage,
  updateListNodeData,
  updateNode,
  updateNodeDefaults,
  updateNodesCoordination,
} from '../helpers/sequence/node';

export const INITIAL_STATE = {
  nodes: [
    {
      id: 1,
      label: 'Select Trigger',
      is_trigger: true,
      component_type: null,
      type: 'TRIGGER',
      trigger_type: null,
      order: 1,
      data: null,
      settings: {
        defaults: {
          isEditMode: false,
          isExpand: false,
          isSelected: false,
          showControlls: false,
        },
      },
      status: 'NEW',
      coordinates: {
        x: 200,
        y: 300,
      },
    },
  ],
  edges: [],
  selectedNode: null,
  undoStack: [],
  redoStack: [],
  view: { scale: 1, offsetX: 0, offsetY: 0 },
};

export function sequenceReducer(state, action) {
  const {
    type,
    primaryKey,
    secondaryKey,
    key,
    value,
    data = null,
    sectionId = null,
    rowId = null,
    id,
    coordinates,
    seqId,
  } = action;

  switch (type) {
    case 'UPDATE':
      if (!data)
        return updateCommonReducerValue({
          state,
          primaryKey,
          secondaryKey,
          key,
          value,
          id,
        });
      else if (data)
        return updateCommonReducerData({
          state,
          primaryKey,
          secondaryKey,
          key,
          data,
          id,
        });

    case 'ADD_NODE':
      return { ...state, newNode: null, nodes: [...state.nodes, data] };

    case 'ADD_SELECTED_NODE':
      return { ...state, ...data };

    case 'ADD_NEW_NODE_DATA':
      return { ...state, newNode: data };

    case 'UPDATE_NEW_NODE_DATA':
      return {
        ...state,
        newNode: onChangeObject({
          obj: state.newNode,
          primaryKey,
          secondaryKey,
          key,
          data,
        }),
      };

    case 'ADD_EDIT_DATA':
      return { ...state, newNode: data };

    case 'UPDATE_EDIT_DATA':
      return {
        ...state,
        newNode: onChangeObject({
          obj: state.newNode,
          primaryKey,
          secondaryKey,
          key,
          data,
        }),
      };

    case 'UPDATE_NODE_DATA':
      const updatedNodeData = updateNode({
        nodes: state.nodes,
        id,
        data,
        primaryKey,
        secondaryKey,
        key,
      });
      return { ...state, nodes: updatedNodeData };

    case 'UPDATE_NODE':
      const updatedNodes = updateNode({
        nodes: state.selectedNode,
        id,
        data,
        primaryKey,
        secondaryKey,
        key,
      });
      return { ...state, selectedNode: updatedNodes };

    case 'ADD_LIST_SECTION':
      return {
        ...state,
        selectedNode: addSectionListNode({ nodes: state.selectedNode, id }),
      };

    case 'ADD_LIST_ROW':
      return {
        ...state,
        selectedNode: addRowInSectionList({
          nodes: state.selectedNode,
          id,
          sectionId,
        }),
      };

    case 'UPDATE_LIST_NODE':
      const updated = updateListNodeData({
        nodes: state.selectedNode,
        id,
        sectionId,
        rowId,
        key,
        value,
      });
      return { ...state, selectedNode: updated };

    case 'DELETE_NODE':
      const deletedNode = deleteNode({
        nodes: state.nodes,
        id,
        key,
      });

      return { ...state, nodes: deletedNode };

    case 'DELETE_LIST_NODE':
      return {
        ...state,
        selectedNode: deleteListFromNode({
          nodes: state?.selectedNode,
          id,
          sectionId,
          rowId,
        }),
      };

    case 'UPDATE_NODE_COORDINATES':
      return {
        ...state,
        nodes: updateNodesCoordination({ nodes: state.nodes, id, data }),
      };
    case 'ADD_INTERACTIVE_BUTTON':
      return {
        ...state,
        selectedNode: addButtonToInteractiveMessage({
          nodes: state.selectedNode,
          id,
        }),
      };

    case 'UPDATE_INTERACTIVE_BUTTON':
      return {
        ...state,
        selectedNode: updateButtonToInteractiveMessage({
          nodes: state.selectedNode,
          id,
          key,
          value,
        }),
      };

    case 'DELETE_INTERACTIVE_BUTTON':
      return {
        ...state,
        selectedNode: deleteButtonToInteractiveMessage({
          nodes: state.selectedNode,
          id,
          key,
        }),
      };

    case 'UPDATE_NODE_REPLY':
      return {
        ...state,
        selectedNode: updateNodeDefaults({
          nodes: state.selectedNode,
          id,
          key,
          data,
        }),
      };

    case 'TOGGLE_LAST_NODE':
      const res = state.nodes.map((node) => {
        if (node?.uniq_id === id)
          return {
            ...node,
            is_last_node: value,
          };

        return node;
      });
      return { ...state, nodes: res };

    case 'ADD_EDGE':
      return { ...state, edges: [...state.edges, data] };

    case 'UPDATE_EDGE_POSITION':
      const updatedEdges = updatedEdgePosittion({
        edges: state?.edges,
        startNodeId: id,
        coordinates,
      });
      return { ...state };

    case 'UPDATE_DATA':
      return { ...state, ...data };

    case 'reset':
      return INITIAL_STATE;
  }
}
