import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// assets
import Icons from '../../Icons';
import Xmark from '../../../../../../assets/customSVG/Xmark';
import Tick from '../../../../../../assets/customSVG/Tick';

// api
import { createNewBoroadcast } from '../../../../api/sequence';
import { updateInitialTrigger } from '../../../../api/sequence';

// helper
import { cn } from 'helper/cn';
import { convertToCustomFormat } from '../../../../../../helper/dateConverter';

// constants
import { INITIAL_NEW_BROADCAST_DATA } from '../../../../constants/sequence';

// hooks
import useToggle from '../../../../../../hooks/useToggle';

// redux
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../../../test/jest-redux-hooks';
import { updateToggleToast } from '../../../../../../reduxToolkit/appSlice';

// components
import Alert from '../../../../../../components/commonComponents/modals/Alert';
import Existing from './Existing';
import New from './New';
import Loader from '../../../../../../components/commonComponents/Loader/Index';

const initialDateAndTime = {
  date: '',
  selectedDate: '',
  selectedTime: '',
  hours: '12',
  minutes: '00',
  periods: 'AM',
};

export default function BroadcastTrigger({
  node,
  onChange,
  toggleNodeEditMode,
  allowToEditFlow,
  removeEdges,
}) {
  const { id, uniq_id, trigger_type, data, settings } = node;

  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const { sequence_id, campaign_id } = useParams();
  const dispatch = useAspDispatch();

  const [isOpen, setIsOpen] = useToggle(false);
  const [isUpdating, setIsUpdating] = useToggle(false);

  const [showConfirm, setShowConfirm] = useToggle();
  const [selectedBroadcast, setSelectedBroadcast] = useState(null);
  const [broadcastData, setBroadcastData] = useState({
    send: null,
    file: null,
    template: null,
    settings: null,
  });

  const [existingData, setExistingData] = useState(null);
  const [sendLatterDate, setSendLatterDate] = useState(initialDateAndTime);

  const typeStyles =
    'w-1/2 h-full min-h-9 flex items-center justify-center text-sm weight-medium rounded-lg cursor-pointer';

  const selected = useCallback(
    (value) =>
      value
        ? '!border text-[#6564CA] border-[#6564CA]'
        : '!border border-[#E8E8EA] text-[var(--contentText)]',
    [node]
  );

  useEffect(() => {
    if (
      node?.data?.broadcast &&
      (!selectedBroadcast || selectedBroadcast?.length === 0)
    )
      setSelectedBroadcast(node?.data?.broadcast);

    if (!existingData) setExistingData(node?.data);
  }, [node]);

  const resetSendLaterDate = () => setSendLatterDate(initialDateAndTime);

  const onSelectType = useCallback(
    (type) => {
      if (type === 'existing')
        onChange({
          type: 'UPDATE_NODE',
          id: node?.uniq_id,
          key: 'data',
          data: existingData,
        });
      else
        onChange({
          type: 'UPDATE_NODE',
          id: node?.uniq_id,
          key: 'data',
          data: {
            settings: {
              country_code: '+91',
              segment_mode: 'individual',
              send_setting: 'send_now',
              send_later_dt: 'send_now',
            },
          },
        });

      console.log('type', type);

      if (toggleNodeEditMode)
        toggleNodeEditMode({
          id: id,
          uniqId: uniq_id,
          defaults: settings?.defaults,
          key: 'isExisting',
          value: type === 'existing' ? true : false,
        });

      // onChange({
      //   type: 'UPDATE_NODE',
      //   id: node?.uniq_id,
      //   PrimaryKey: 'data',
      //   secondaryKey: 'settings',
      //   key: 'defaults',
      //   data: {
      //     ...node?.settings?.defaults,
      //     isExisting: type === 'existing' ? true : false,
      //   },
      // });
    },
    [node]
  );

  const chackNewBroadcastChanges = useCallback(() => {
    const newData = {
      isExisting: data?.isExisting,
      file: data?.data?.file,
      fileUrl: data?.data?.fileUrl,
      sendFrom: data?.data?.sendFrom,
      template: data?.data?.template,
      settings: data?.data?.settings,
    };

    const compare =
      JSON.stringify(newData) === JSON.stringify(INITIAL_NEW_BROADCAST_DATA);

    return compare;
  }, [data]);

  const handleYes = async () => {
    setIsUpdating(true);
    try {
      const allowToSubmit = showSubmit();

      if (allowToSubmit?.isExisting && allowToSubmit?.isAlreadySelected) {
        // setIsOpen(false);
        toggleNodeEditMode({
          id: node?.id,
          uniqId: node?.uniq_id,
          value: false,
          defaults: node?.settings?.defaults,
        });
      } else {
        try {
          const date = convertToCustomFormat(new Date());
          const name = `broadcast_${date?.split('.')?.[0]}`;

          let payload = {
            brand_id: currentBrand?.brand_id,
            campaign_id: campaign_id,
          };

          if (allowToSubmit?.isExisting) {
            payload = {
              ...payload,
              trigger_type: trigger_type?.toLowerCase(),
              save: true,
              details: {
                broadcast_id: selectedBroadcast?.id,
              },
            };

            const res = await updateInitialTrigger(
              sequence_id,
              node?.uniq_id,
              payload
            );

            if (res?.status === 200) {
              setShowConfirm(false);
              onChange({
                type: 'UPDATE_NODE_DATA',
                id: node?.uniq_id,
                data: res?.data?.data?.[0],
              });
            } else {
              dispatch(
                updateToggleToast([
                  ...toggleToast,
                  {
                    id: toggleToast?.length + 1,
                    content: 'Trigger node updation failed',
                    status: 'Error',
                    duration: '',
                  },
                ])
              );
            }
          } else {
            payload = {
              ...payload,
              trigger_type: trigger_type?.toLowerCase(),
              name,
              ...data,
            };

            const res = await createNewBoroadcast(payload);
            if (res?.status === 200) {
              const payload = {
                brand_id: currentBrand?.brand_id,
                campaign_id: campaign_id,
                trigger_type: 'camp_wapp_bcast',
                save: true,
                details: {
                  broadcast_id: res?.data?.id,
                  start_broadcast_on_save: true,
                },
              };
              const updatedTrigger = await updateInitialTrigger(
                sequence_id,
                node?.uniq_id,
                payload
              );

              if (updatedTrigger?.status === 200) {
                onChange({
                  type: 'UPDATE_NODE_DATA',
                  id: updatedTrigger?.data?.data?.[0]?.uniq_id,
                  data: updatedTrigger?.data?.data?.[0],
                });

                toggleNodeEditMode({
                  id: id,
                  uniqId: uniq_id,
                  defaults: settings?.defaults,
                  key: 'isEditMode',
                  value: false,
                });
              }
            } else {
              dispatch(
                updateToggleToast([
                  ...toggleToast,
                  {
                    id: toggleToast?.length + 1,
                    content: 'Broadcast creation failed',
                    status: 'Error',
                    duration: '',
                  },
                ])
              );
            }
          }

          removeEdges(node?.uniq_id);
          setIsUpdating(false);
        } catch (error) {
          console.warn(error);
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const handleBroadcastData = ({ key, value }) => {
    const data = { ...broadcastData };
    data[key] = value;
    setBroadcastData(data);
  };

  const showSubmit = useCallback(() => {
    const isExisting = node?.settings?.defaults?.isExisting ? true : false;

    const isAlreadySelected =
      selectedBroadcast?.id === node?.data?.broadcast?.id;

    const isNewDataIsValid =
      node?.data?.file_name &&
      node?.data?.file_path &&
      node?.data?.settings?.template_name &&
      node?.data?.settings?.template_id &&
      node?.data?.settings?.send_from_phone_name &&
      node?.data?.settings?.send_from_phone_number &&
      node?.data?.settings?.send_from_phone_number_id;

    const isSendSettingValid = true;

    return {
      isExisting,
      isAlreadySelected,
      allow: isExisting
        ? isAlreadySelected
          ? true
          : selectedBroadcast
        : isNewDataIsValid && isSendSettingValid,
    };
  }, [node, selectedBroadcast]);

  return (
    <div className='w-full flex-1 min-w-full max-w-[19rem] h-full rounded-2xl overflow-hidden select-none'>
      <div className=''>
        <div
          className={cn(
            'flex items-center justify-between gap-5 px-5 py-2.5 bg-[#04B8A2] text-white'
          )}>
          <div className='flex items-center gap-5'>
            <Icons
              keyProp={node?.label?.toLowerCase()}
              bgProp={'transparent'}
            />
            <p className='capitalize'>{node?.label}</p>
          </div>
          <div
            className='size-4 flex items-center justify-center cursor-pointer'
            onClick={() => {
              toggleNodeEditMode({
                id: node?.id,
                uniqId: node?.uniq_id,
                value: false,
                defaults: node?.settings?.defaults,
              });
              onChange({
                type: 'UPDATE_NODE',
                id: node?.uniq_id,
                key: 'data',
                data: node?.status === 'NEW' ? null : existingData,
              });
            }}>
            <Xmark
              color='white'
              width={12}
              height={12}
            />
          </div>
        </div>

        <div className='w-full px-6 pt-6 pr-1 bg-white'>
          <div className='w-full max-h-[400px] pr-6 flex flex-col gap-5 overflow-y-scroll listScroll'>
            <p>Select or create broadcast for your sequence.</p>
            <div className='w-full h-9 flex items-center'>
              <div
                className={cn(
                  typeStyles,
                  'border-r-0 rounded-r-none',
                  selected(node?.settings?.defaults?.isExisting)
                )}
                onClick={() => {
                  onSelectType('existing');
                }}>
                Existing
              </div>
              <div
                className={cn(
                  typeStyles,
                  'rounded-l-none border-l-0',
                  selected(!node?.settings?.defaults?.isExisting)
                )}
                onClick={() => {
                  onSelectType('new');
                }}>
                Create New
              </div>
            </div>
            {node?.settings?.defaults?.isExisting ? (
              <Existing
                data={node}
                onChange={onChange}
                selectedBroadcast={selectedBroadcast}
                setSelectedBroadcast={setSelectedBroadcast}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                allowToEditFlow={allowToEditFlow}
              />
            ) : (
              <New
                data={node}
                onChange={onChange}
                sendLatterDate={sendLatterDate}
                setSendLatterDate={setSendLatterDate}
                allowToEditFlow={allowToEditFlow}
                resetSendLaterDate={resetSendLaterDate}
              />
            )}
          </div>
        </div>

        <div className='w-full h-fit flex justify-end px-6 py-2.5 bg-white'>
          <button
            className={cn(
              'w-14 h-8 bg-[var(--primary)] flex items-center justify-center text-white px-4 py-1 rounded-md',
              showSubmit()?.allow
                ? 'cursor-pointer opacity-100'
                : 'opacity-50 cursor-not-allowed'
            )}
            onClick={() => {
              if (showSubmit()?.allow) handleYes();
            }}>
            {isUpdating ? (
              <Loader
                Width={14}
                Height={14}
                loaderBg='white'
              />
            ) : (
              <div className='flex items-center justify-center gap-1.5'>
                <Tick
                  width='12'
                  height='10'
                  strokeWidth='2.5'
                  stroke='white'
                />
                <span>OK</span>
              </div>
            )}
          </button>
        </div>
      </div>
      <Alert
        open={showConfirm}
        setOpen={setShowConfirm}
        content='changes'
        yesContent='Ok'
        noContent='Cancel'
        handleNo={() => setShowConfirm(false)}
        handleYes={handleYes}
      />
    </div>
  );
}
