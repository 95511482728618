import React, { useCallback } from 'react';

// assets
import Delete from '../../../../../../assets/customSVG/Delete';

// helpers
import { getRowsCount } from '../../../../helpers/sequence/node';

// components
import Row from './Row';
import Input from '../../Input';
import AddIcon from '../../../../../../assets/customSVG/AddIcon';
import { cn } from '../../../../../../helper/cn';

const maxSectionCount = 10;
const maxRowCount = 10;

const ListMessage = ({
  nodeId,
  sections,
  onChange,
  btnText,
  allowToEditFlow,
  onDeleteEdge,
}) => {
  const counts = getRowsCount({ sections });

  const allowToAddRow = useCallback(() => {
    return counts?.row < maxRowCount;
  }, [counts, sections]);

  const allowToAddSection = useCallback(() => {
    return counts?.row < maxRowCount;
  }, [counts, sections]);

  return (
    <div className='flex flex-col gap-2.5'>
      {sections?.map((section, sectionIndex) => {
        return (
          <div className='bg-[#F8F9FC] p-4 rounded-[0.625rem] group/sectionDelete'>
            <div className='w-full h-5 flex items-center justify-between pb-2.5'>
              <div className='text-sm weight-semibold'>
                Section {sectionIndex + 1}
              </div>
              <div className=''>
                {counts?.section > 1 && (
                  <button
                    className={`size-6 bg-transparent flex items-center justify-center rounded-[0.4vw] hover:bg-red-200`}
                    onClick={async () => {
                      const ids = section?.rows?.map((r) => r?.id);
                      console.log('section', ids);
                      await onDeleteEdge(ids);
                      if (allowToEditFlow)
                        onChange({
                          type: 'DELETE_LIST_NODE',
                          id: nodeId,
                          sectionId: sectionIndex + 1,
                        });
                    }}>
                    <Delete
                      width='14'
                      height='14'
                      className={`stroke-[#898E99] group-hover/sectionDelete:stroke-red-500`}
                      color=''
                    />
                  </button>
                )}
              </div>
            </div>
            <div className='pb-2.5'>
              <Input
                placeholder='Enter section title'
                value={section?.title}
                onInputChange={(value) => {
                  if (allowToEditFlow)
                    onChange({
                      type: 'UPDATE_LIST_NODE',
                      id: nodeId,
                      sectionId: sectionIndex + 1,
                      key: 'title',
                      value: value,
                    });
                }}
              />
            </div>
            <div className='flex flex-col gap-2.5 pt-2.5 border-t border-t-gray-200'>
              {section?.rows?.map((row, rowIndex) => {
                return (
                  <Row
                    showDelete={counts?.row > 1}
                    key={rowIndex + 1}
                    sectionId={sectionIndex + 1}
                    rowId={rowIndex + 1}
                    nodeId={nodeId}
                    title={row?.title || ''}
                    description={row?.description || ''}
                    onChange={onChange}
                    deleteNode={async () => {
                      if (allowToEditFlow) {
                        await onDeleteEdge([row?.id]);
                        onChange({
                          type: 'DELETE_LIST_NODE',
                          id: nodeId,
                          sectionId: sectionIndex + 1,
                          rowId: rowIndex + 1,
                        });
                      }
                    }}
                  />
                );
              })}
            </div>
            <button
              className={cn(
                'w-full h-9 mt-2.5 flex items-center justify-center gap-2.5 text-sm weight-semibold border bg-white rounded-md',
                allowToAddRow()
                  ? 'text-[var(--primary)] border-[var(--primary)] cursor-pointer'
                  : 'text-[var(--font-600)] border-[var(--font-600)] cursor-not-allowed'
              )}
              onClick={() => {
                if (allowToAddRow() && allowToEditFlow)
                  onChange({
                    type: 'ADD_LIST_ROW',
                    id: nodeId,
                    sectionId: sectionIndex + 1,
                  });
              }}>
              <div
                className={cn(
                  'border border-[var(--primary)] flex items-center justify-center rounded-md',
                  allowToAddRow()
                    ? 'border-[var(--primary)]'
                    : 'border-[var(--font-600)]'
                )}>
                <AddIcon
                  stroke={allowToAddRow() ? 'var(--primary)' : 'var(--font-600'}
                  fill={allowToAddRow() ? 'var(--primary)' : 'var(--font-600'}
                  rect={false}
                  width={22}
                  height={22}
                />
              </div>
              <p>New row</p>
            </button>
          </div>
        );
      })}

      <button
        className={cn(
          'w-full h-9 flex items-center justify-center gap-2.5 text-sm weight-semibold border bg-white rounded-md',
          allowToAddSection()
            ? 'text-[var(--primary)] border-[var(--primary)] cursor-pointer'
            : 'text-[var(--font-600)] border-[var(--font-600)] cursor-not-allowed'
        )}
        onClick={() => {
          if (allowToAddSection() && allowToEditFlow)
            onChange({
              type: 'ADD_LIST_SECTION',
              id: nodeId,
            });
        }}>
        <div
          className={cn(
            'border border-[var(--primary)] flex items-center justify-center rounded-md',
            allowToAddSection()
              ? 'border-[var(--primary)]'
              : 'border-[var(--font-600)]'
          )}>
          <AddIcon
            stroke={allowToAddSection() ? 'var(--primary)' : 'var(--font-600'}
            fill={allowToAddSection() ? 'var(--primary)' : 'var(--font-600'}
            rect={false}
            width={22}
            height={22}
          />
        </div>
        <p>New section</p>
      </button>
    </div>
  );
};

export default ListMessage;
