import React, { useRef } from 'react';

// assets
import AddIcon from '../../../../../assets/customSVG/AddIcon';
import NodeBg from '../../../../../assets/customSVG/sequence/NodeBg';
import { ICONS } from '../../../../../assets/icons';

// constants
import { edgeDefault } from '../../../constants/defaults';

// helpers
import { cn } from '../../../../../helper/cn';

// components
import Icons from '../Icons';

const Node = ({
  uniqId,
  is_trigger,
  is_last_node,
  label,
  order,
  data,
  bgColor,
  newEdge,
  handleEdgeMouseUp,
}) => {
  const nodeRef = useRef();

  const onMouseUp = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // const rect = nodeRef?.current?.getBoundingClientRect();
    // const centerX = rect?.left + Math?.abs(rect?.right - rect?.left) / 2;
    // const centerY = rect?.top + Math?.abs(rect?.bottom - rect?.top) / 2;
    // const newPosition = {
    //   x: centerX - 39.5,
    //   y: centerY + 1,
    // };

    const rect = nodeRef.current.getBoundingClientRect();
    const centerX = rect.left - edgeDefault / 2;
    const centerY = rect.top + Math.abs(rect.bottom - rect.top) / 2;
    const newPosition = { x: centerX, y: centerY };

    const id = { uniq_id: uniqId };

    if (handleEdgeMouseUp)
      handleEdgeMouseUp({
        event,
        node: id,
        position: newPosition,
      });
  };

  return (
    <div className='w-full relative flex flex-col items-center justify-center gap-1 cursor-grab group/node_icon'>
      <div
        ref={nodeRef}
        className={cn(
          'w-full relative flex items-center justify-center',
          newEdge ? 'cursor-move' : 'cursor-grab'
        )}
        onMouseUp={onMouseUp}>
        <NodeBg
          width={data ? (is_trigger ? 110 : 86) : 158}
          height={data ? (is_trigger ? 110 : 86) : 158}
          color={data ? bgColor : '#6940F2'}
        />

        <div className='absolute rounded-[35%] overflow-hidden flex items-center justify-center'>
          {data ? (
            <div
              className={cn(
                'bg-white flex items-center justify-center',
                is_trigger ? 'size-16' : 'size-14'
              )}>
              <Icons
                keyProp={label === 'BROADCAST' ? 'BROADCAST' : 'WHATSAPP'}
                bgProp={'white'}
                colorProp={'#04B8A2'}
                strokeWidth={'1.5'}
              />
            </div>
          ) : (
            <div className='relative flex items-center'>
              <div className='relative flex flex-col items-center justify-center gap-2.5'>
                <div
                  className={cn(
                    'group/node_icon flex items-center justify-center rounded-2xl group-hover/node_icon:transition-all ease-linear duration-150',
                    'bg-[#6940F216] hover:bg-[#6940F2]'
                  )}>
                  <AddIcon
                    // width={40}
                    // height={40}
                    stroke=''
                    strokeWidth='2.5'
                    rect={false}
                    className='size-24 stroke-[#ffffff] scale-110 group-hover/node_icon:stroke-[#ffffff] group-hover/node_icon:transition-all ease-linear duration-150 group-hover/node_icon:scale-150 group-hover/node_icon:rotate-180'
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='w-fit h-fit !text-xs text-center text-nowrap text-[#2D3036] weight-medium'>
        <div className='w-full flex items-center gap-1.5 text-xs -ml-2.5'>
          <p className='min-w-6 min-h-6 w-6 h-6 p-1 flex items-center justify-center border border-[#2D3036] rounded-md'>
            {order}
          </p>
          <p className=''>{label}</p>
        </div>
      </div>

      {(is_last_node || is_trigger) && (
        <div
        // className='w-full absolute'
        // style={{ transform: `translate(25px, 125px)` }}
        >
          {is_last_node ? (
            <div className='w-fit flex items-center gap-1 bg-[#FF543E] px-1 py-0.5 rounded-md'>
              <img
                src={ICONS?.EndNodeFlag}
                alt=''
              />
              <p className='text-base weight-semibold text-white'>End</p>
            </div>
          ) : (
            <div className='w-fit flex items-center gap-1 bg-[#616874] px-1 py-0.5 rounded-md'>
              <img
                src={ICONS?.EndNodeFlag}
                alt=''
              />
              <p className='text-base weight-semibold text-white'>Start</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Node;
