import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Popover } from 'antd';

// hooks
import useToggle from '../../../../../hooks/useToggle';

// helper
import { cn } from '../../../../../helper/cn';

// components
import Switch from '../../../../../components/commonComponents/step/switch';
import { isObjectEmpty } from '../../../helpers';

const messageTypes = [
  { id: 1, label: 'Repeat this message', key: 'REPEAT_MESSAGE' },
  { id: 2, label: 'Send error message', key: 'ERROR_MESSAGE' },
];

const maxReplayTextLimit = 160;

const DefaultReplay = ({ nodeId, data, onChange }) => {
  const textareaRef = useRef();
  const [isChecked, setIsChecked] = useToggle(false);
  const [isPopupOpen, setIsPopupOpen] = useToggle(false);
  const [selectedReplaymessage, setSelectedReplaymessage] = useState(null);

  useEffect(() => {
    if (data) {
      const selectedReply =
        data?.type === 'compose' ? messageTypes?.[1] : messageTypes?.[0];
      setSelectedReplaymessage(selectedReply);
      setIsChecked(true);
    } else if (isObjectEmpty(data) || !data) {
      setSelectedReplaymessage(messageTypes?.[0]);
    } else {
      setIsChecked(false);
    }
  }, []);

  const toggleSwitch = () => {
    if (!isChecked) {
      onChange({
        type: 'UPDATE_NODE_REPLY',
        id: nodeId,
        key: 'REPEAT_MESSAGE',
        data: {
          body: '',
          type: 'repeat',
        },
      });
    } else {
      onChange({
        type: 'UPDATE_NODE_REPLY',
        id: nodeId,
        key: 'NONE',
        data: {},
      });
    }
    setIsChecked(!isChecked);
  };

  const handleSelectMessageType = ({ type }) => {
    onChange({
      type: 'UPDATE_NODE_REPLY',
      id: nodeId,
      key: type?.key,
      data: {
        body: '',
        type: 'repeat',
      },
    });
    setSelectedReplaymessage(type);
    setIsPopupOpen(false);
  };

  const totalTextCount = useCallback(() => {
    if (!data?.body) return 0;
    return data?.body?.length || 0;
  }, [data?.body]);

  return (
    <div className='mb-6'>
      <div className='flex items-center gap-5'>
        <Switch isChecked={isChecked} toggleSwitch={toggleSwitch} />
        <div
          className={cn(
            'text-xs weight-medium flex items-center gap-1',
            isChecked
              ? 'opacity-100 cursor-default'
              : 'opacity-50 cursor-not-allowed'
          )}
        >
          <p>If replied other answers</p>
          <Popover
            trigger={['click']}
            open={isPopupOpen}
            onOpenChange={(open) => {
              if (isChecked) setIsPopupOpen(open);
            }}
            content={
              <div className='w-full min-w-48 flex flex-col p-1'>
                {messageTypes?.map((t) => {
                  return (
                    <div
                      className='h-9 flex items-center justify-center cursor-pointer border-b border-gray-100 last:border-b-0'
                      onClick={() => {
                        handleSelectMessageType({ type: t });
                      }}
                    >
                      {t?.label}
                    </div>
                  );
                })}
              </div>
            }
          >
            <div
              className={cn(
                'text-[#6940F2] weight-semibold',
                isChecked ? 'cursor-pointer' : 'cursor-not-allowed'
              )}
              onClick={() => {
                if (isChecked) setIsPopupOpen(!isPopupOpen);
              }}
            >
              {selectedReplaymessage?.label}
            </div>
          </Popover>
        </div>
      </div>
      {data && selectedReplaymessage?.key === 'ERROR_MESSAGE' && (
        <div className='py-2.5'>
          <label className='pb-2.5 text-xs weight-semibold'>
            Error Message
          </label>
          <div className='border border-[var(--border-100)] rounded-md'>
            <textarea
              className='w-full h-14 resize-none bg-transparent text-xs weight-medium px-2.5 py-1.5 outline-none'
              placeholder={'Enter text'}
              value={data?.body}
              ref={textareaRef}
              onClick={(e) => textareaRef?.current?.focus()}
              maxLength={maxReplayTextLimit}
              onChange={(e) => {
                onChange({
                  type: 'UPDATE_NODE_REPLY',
                  id: nodeId,
                  key: selectedReplaymessage?.key,
                  data: {
                    body: e.target.value,
                    type:
                      selectedReplaymessage?.key === 'REPEAT_MESSAGE'
                        ? 'repeat'
                        : 'compose',
                  },
                });
              }}
            />
            <div className='w-auto h-5 px-2.5 flex items-center justify-end text-[0.688rem] weight-semibold text-[#898E99]'>
              {totalTextCount()}/{maxReplayTextLimit}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DefaultReplay;
