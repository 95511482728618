import React, { useCallback, useEffect, useReducer } from 'react';
import {
  allowedCtaButtonTypes,
  allowedCustomButtonTypes,
  getCTAButtons,
} from '../../../../../components/commonComponents/templates/Buttons/utils';
import { replaceVariables } from '../../../../../helper/templates';
import {
  INITIAL_STATE,
  templateReducer,
} from '../../../../channels/reducers/templateReducer';
import {
  addLineBreake,
  replaceFormating,
  replaceVariablesForPreview,
} from '../../../../inbox/helper';
import Edge from '../edge/Edge';
import CarouselCard from './CarouselCard';
import { cn } from '../../../../../helper/cn';
import { ICONS } from '../../../../../assets/icons';

const Template = ({
  category,
  categoryType,
  data,
  node,
  onEdgeMouseDown,
  checkIsEdgeConnected,
  allowToShowEdge = true,
  onButtonClick,
  btnIcon = true,
  rootClassName = '',
  className = '',
  btnClassName = '',
  rootBtnStyle = '',
  customBtnStyle,
  color,
  allowToEditFlow,
}) => {
  const [templateData, templateDispatch] = useReducer(
    templateReducer,
    INITIAL_STATE
  );

  const { custom, authentication, carousel } = templateData;

  const {
    header,
    headerVariable,
    body,
    bodyVariables,
    footer,
    buttons,
    buttonsOrder,
    ctaButtons,
    customButtons,
  } = custom;

  useEffect(() => {
    templateDispatch({
      type: 'update_template',
      data: {
        category: ['CUSTOM', 'CAROUSEL']?.includes(category)
          ? 'MARKETING'
          : 'category',
        subcategory_type: category,
        components: data?.template,
      },
    });
  }, [data.template]);

  const showFooter = useCallback(() => {
    return getCTAButtons(buttons, 'OPT_OUT')?.length < 1 ? true : false;
  }, [buttons]);

  const btnStyle =
    'w-full h-[1.875rem] flex-1 text-[0.688rem] weight-medium text-[#007AFF] flex items-center justify-center gap-2.5';

  return (
    <div className={cn('w-full min-w-72 h-full relative', rootClassName)}>
      <div
        className={cn(
          'w-auto h-auto overflow-y-scroll flex flex-col gap-2.5 p-3 bg-white rounded-xl overflow-hidden',
          className
        )}>
        <div className='w-full h-auto'>
          {category === 'AUTHENTICATION' ? (
            <div className='min-h-16 bg-white rounded-2.5 overflow-y-scroll'>
              <div className='text-sm text-[#2D3036] weight-medium break-words bg-white'>
                {authentication?.body}
              </div>
              <p className='w-full font-sm weight-small bg-white text-[var(--font-600)] pt-2'>
                {authentication?.content?.expiryTimeForCode
                  ? `This code expires in ${
                      authentication?.content?.expiryTime >= 1 &&
                      authentication?.content?.expiryTime <= 90
                        ? authentication?.content?.expiryTime
                        : 0
                    } Minutes.`
                  : ''}
              </p>
              <div className='h-auto bg-white rounded-b-lg mt-2.5 text-[#007AFF]'>
                {authentication?.buttons?.map((b, i) => {
                  return (
                    <div key={`authentication-preview-button-${i}`}>
                      <button className={`${btnStyle}`}>{b?.text}</button>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className='bg-white rounded-lg overflow-y-scroll flex flex-col gap-2'>
              {/* header preview */}
              <div className='w-full bg-white rounded-md h-full overflow-hidden'>
                {header?.format === 'TEXT' && (
                  <div className='h-full text-base weight-semibold break-words'>
                    {header?.example?.header_text?.[0] !== 0
                      ? replaceVariables(
                          header?.text,
                          header?.example?.header_text
                        )
                      : header?.text}
                    {/* {header?.text} */}
                  </div>
                )}
                {header?.format === 'IMAGE' &&
                  header?.example?.header_handle?.length > 0 && (
                    <img
                      src={header?.example?.header_handle?.[0]}
                      className='w-full h-full max-h-32 object-cover rounded'
                    />
                  )}
                {header?.format === 'VIDEO' &&
                  header?.example?.header_handle?.length > 0 && (
                    <video
                      className='object-cove rounded'
                      controls
                      autoplay='autoplay'
                      // loop
                    >
                      <source
                        src={header?.example?.header_handle?.[0]}
                        type='video/mp4'
                      />
                    </video>
                  )}
                {header?.format === 'DOCUMENT' &&
                  header?.example?.header_handle?.length > 0 && (
                    <iframe
                      title='cover'
                      className='imagePreview'
                      src={header?.example?.header_handle?.[0]}
                      type='application/pdf'
                      width='100%'
                      height='150px'></iframe>
                  )}
              </div>
              {/* body preview */}
              <p
                className='h-auto text-[0.688rem] text-[#2D3036] weight-medium break-words bg-white'
                dangerouslySetInnerHTML={{
                  __html:
                    bodyVariables?.length > 0
                      ? replaceVariablesForPreview(
                          addLineBreake(replaceFormating(body)),
                          bodyVariables
                        )
                      : addLineBreake(replaceFormating(body)),
                }}></p>
              {/* footer preview */}
              {footer?.text && showFooter() && (
                <p className='w-full text-[0.688rem] text-[var(--font-400)] weight-medium bg-white'>
                  {footer?.text}
                </p>
              )}
              {footer?.text && !showFooter() && (
                <p className='w-full text-[0.688rem] text-[var(--font-400)] weight-medium bg-white'>
                  Not interested? Tap Stop promotions
                </p>
              )}
              {/* buttons preview */}
              <div
                className={cn(
                  'h-auto bg-white rounded-b-2.5 text-[#007AFF] flex flex-col gap-2.5',
                  rootBtnStyle
                )}>
                {buttons.map((b, i) => {
                  const connection_point = {
                    id: b?.id,
                    type: 'action',
                  };
                  let isConnected = false;
                  if (checkIsEdgeConnected) {
                    isConnected = checkIsEdgeConnected({
                      start_node_id: node?.uniq_id,
                      connection_point,
                    });
                  }
                  return (
                    <div
                      className={cn(
                        'relative w-full h-[1.875rem] flex items-center justify-center bg-[#F8F9FC] rounded',
                        btnClassName
                      )}>
                      <div
                        className='w-full'
                        key={`preview-button-${i}`}
                        onClick={() => {
                          if (b?.type === 'QUICK_REPLY' && onButtonClick)
                            onButtonClick(b);
                        }}>
                        {allowedCtaButtonTypes.includes(b?.type) && (
                          <div className='w-full flex-1 opacity-50 cursor-not-allowed'>
                            {b.type === 'URL' && (
                              <button
                                className={cn(
                                  'cursor-not-allowed',
                                  btnStyle,
                                  customBtnStyle
                                )}>
                                {btnIcon && (
                                  <img
                                    src={ICONS?.nounLink}
                                    alt='shareBtn'
                                    className='size-[0.9vw]'
                                  />
                                )}
                                <span>{b.text}</span>
                              </button>
                            )}
                            {b.type === 'PHONE_NUMBER' && (
                              <button
                                className={cn(
                                  'cursor-not-allowed',
                                  btnStyle,
                                  customBtnStyle
                                )}>
                                {btnIcon && (
                                  <img
                                    src={ICONS?.call}
                                    alt='shareBtn'
                                    className='size-[0.9vw]'
                                  />
                                )}
                                <span>{b.text}</span>
                              </button>
                            )}

                            {b.type === 'COPY_CODE' && (
                              <button
                                className={cn(
                                  'cursor-not-allowed',
                                  btnStyle,
                                  customBtnStyle
                                )}>
                                {btnIcon && (
                                  <img
                                    src={ICONS?.CopyLeft}
                                    alt='shareBtn'
                                    className='size-[0.9vw]'
                                  />
                                )}
                                <span>Copy Offer Code</span>
                              </button>
                            )}
                          </div>
                        )}
                        {allowedCustomButtonTypes?.includes(b?.type) && (
                          <div
                            className={cn(
                              'cursor-pointer',
                              btnStyle,
                              customBtnStyle
                            )}>
                            {btnIcon && (
                              <img
                                src={ICONS?.shareLink}
                                alt='shareBtn'
                                className='size-3.5'
                              />
                            )}
                            <span>{b.text}</span>
                          </div>
                        )}
                      </div>
                      {allowToShowEdge &&
                        allowedCustomButtonTypes?.includes(b?.type) && (
                          <div className='w-auto h-[1.875rem] absolute top-2 right-1'>
                            <Edge
                              type='start'
                              onEdgeMouseDown={({ position }) => {
                                if (!isConnected)
                                  onEdgeMouseDown({
                                    nodeId: node?.id,
                                    connection_point,
                                    position: position,
                                  });
                              }}
                              isConnected={isConnected}
                              color={color}
                              allowToEditFlow={allowToEditFlow}
                            />
                          </div>
                        )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {category === 'CAROUSEL' && (
          <div className='flex flex-col gap-8 overflow-x-scroll listScroll'>
            {carousel?.cards?.map((c) => {
              return (
                <div className='w-full min-w-48 h-auto'>
                  <CarouselCard
                    data={c}
                    carousel={carousel}
                    checkIsEdgeConnected={checkIsEdgeConnected}
                    onEdgeMouseDown={({ connection_point, position }) => {
                      const isConnected = checkIsEdgeConnected({
                        start_node_id: node?.id,
                        connection_point,
                      });
                      if (!isConnected)
                        onEdgeMouseDown({
                          nodeId: node?.id,
                          connection_point,
                          position: position,
                        });
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
        {/* <div className='flex items-center justify-end my-2.5 px-2.5 weight-medium'>
          <button className='text-[0.7rem]'>Next Step</button>
        </div> */}
      </div>
    </div>
  );
};

export default Template;
