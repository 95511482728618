import { Modal } from "antd";
import React, { useState } from "react";

// hooks
import useToggle from "../../../../../hooks/useToggle";

// helpers
import { cn } from "../../../../../helper/cn";

// components
import Header from "../../../../../components/commonComponents/modals/Header";
import TemplateListContainer from "../../../../../components/commonComponents/templates/TemplateListContainer";
import { ICONS } from "../../../../../assets/icons";
import TemplatePreviewCard from "../../../../../components/commonComponents/templates/TemplatePreviewCard";

const TemplateMessage = ({
  title = "WhatsApp Templates",
  node,
  onTriggerClick,
  onChange,
  selectedTemplate,
  setSelectedTemplate,
  isConnected,
  allowToEditFlow,
}) => {
  const [isOpenModal, setIsOpenModal] = useToggle(false);

  const handleCanceltemplateModal = () => setIsOpenModal(false);

  return (
    <>
      <div className="flex flex-col gap-5 py-5">
        <h5
          className={cn(
            "text-sm weight-semibold text-[var(--font-600)] pb-2.5"
          )}
        >
          {title}
        </h5>
        {selectedTemplate ? (
          <TemplatePreviewCard
            category={selectedTemplate?.category}
            categoryType={selectedTemplate?.subcategory_type}
            name={selectedTemplate?.name}
            language={selectedTemplate?.language}
            onChange={() => {
              if (allowToEditFlow) setIsOpenModal(true);
            }}
          />
        ) : (
          <div
            className="flex items-center justify-center gap-2.5 text-sm weight-medium text-[var(--font-600)] border border-gray-200 py-2.5 rounded-lg"
            onClick={() => {
              if (allowToEditFlow) setIsOpenModal(true);
            }}
          >
            <img src={ICONS?.chooseTemplateInbox} alt="chooseTemplateInbox" />
            <p>Choose template</p>
          </div>
        )}
      </div>

      <Modal
        open={isOpenModal}
        centered
        footer={null}
        closable={false}
        mask={true}
        wrapClassName={"bg-[#00000095]"}
        onCancel={handleCanceltemplateModal}
        destroyOnClose
        width={"60vw"}
        style={{
          width: "100%",
          borderRadius: 20,
          boxShadow: "0px 10px 24px 0px rgba(79, 92, 128, 0.15)",
        }}
      >
        <Header
          title="Choose template"
          titleSize="largest"
          rootStyle="!px-[1.5vw] !py-[1vw]"
          handleCancel={handleCanceltemplateModal}
        />

        <TemplateListContainer
          className="h-[58vh]"
          sendTemplate={(data) => {
            if (allowToEditFlow) {
              setSelectedTemplate(data);
              // onChange({
              //   type: 'UPDATE_NODE',
              //   id: node?.uniq_id,
              //   key: 'data',
              //   data: initial_template_data,
              // });

              // onChange({
              //   type: 'UPDATE_NODE',
              //   id: node?.id,
              //   key: 'component_type',
              //   data: 'CUSTOM',
              // });
              handleCanceltemplateModal();
            }
          }}
        />
      </Modal>
    </>
  );
};

export default TemplateMessage;
