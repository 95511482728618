import React, { useCallback, useEffect, useRef, useState } from "react";
import { cn } from "../../../helper/cn";
import { dateConverter } from "../../../helper/dateConverter";
import {
  ACCOUNT_ROLE,
  ACCOUNT_STATUS,
  BRAND_ROLE,
  reAssignBrandData,
  reAssignData,
} from "../constants/home";
import { Modal, Popover, Skeleton, Tooltip } from "antd";
import { ICONS } from "../../../assets/icons";
import { ICONSS } from "../../../assets/icons/index1";
import Header from "../../../components/commonComponents/modals/Header";
import Mail from "assets/customSVG/Mail";
import DropDown from "assets/customSVG/accounts/DropDown";
import {
  getRandomColors,
  getRandomColorsBassedOnLetters,
} from "../../../helper/colors";
import InfiniteScroll from "react-infinite-scroll-component";
import { AssignBrandUsers, InviteTeamMembers } from "../../auth/api/OnBoarding";
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";
import { updateToggleToast } from "../../../reduxToolkit/appSlice";
import {
  activeSuspendUser,
  cancelInvitation,
  reAssignBrandRole,
  reAssignRole,
  removeBrandUser,
  removeUser,
} from "../api/Api";
import NewAlert from "../../../components/commonComponents/modal/alert/NewAlert";
import NoData from "../../../components/commonComponents/Empty/NoData";
import { images } from "../../../assets/images";
import { useLocation, useParams } from "react-router-dom";
import { validateEmail } from "../../../helper/validation";

import Avatar from "../../../assets/customSVG/Avatar";

const AccountUsersList = ({
  list,
  data,
  inviteModal,
  onChange,
  setInviteModal,
  isLoadingSkeleton,
  loadNextPage,
  count,
  fetchAccounts,
  setSearchKey,
  clearAccountStatusList,
  clearRoleList,
  activeNav,
  assignUsersList,
  setBrandPopover,
  searchKey,
  selectedRoleId,
  selectedAccountStatusId,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visiblePopovers, setVisiblePopovers] = useState({});
  const [switchRoles, setSwitchRoles] = useState(false);
  const [inviteManagers, setInviteManagers] = useState(
    activeNav ? "Brand Manager" : "Account Manager"
  );
  const [visiblePopoversInvite, setVisiblePopoversInvite] = useState(false);
  const [visiblePopoversActions, setVisiblePopoversActions] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [loadingText, setLoadingText] = useState(false);
  const [isModalVisibleRemove, setIsModalVisibleRemove] = useState(false);
  const [emailData, setEmailData] = useState({
    managers: [],
    users: [],
  });

  // brand assign popover
  const [assignPopoverOpen, setAssignPopoverOpen] = useState(false);
  const [assignPopoverOpenCampaign, setAssignPopoverOpenCampaign] =
    useState(false);

  const location = useLocation();
  const listContainerRef = useRef(null);
  const dispatch = useAspDispatch();
  const { currentAccount, currentBrand, toggleToast } = useAspSelector(
    (state) => state.app
  );

  const { id } = useParams();

  const accountManagersCount = list?.account_manager_count;

  const [errorMail, setErrorMail] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const managerEmails = emailData?.managers?.map(
      (manager) => manager.email_id
    );
    const userEmails = emailData?.users?.map((user) => user.email_id);

    const duplicateEmails = managerEmails?.filter((email) =>
      userEmails.includes(email)
    );

    if (duplicateEmails?.length > 0) {
      setError(`Duplicate email found: ${duplicateEmails?.join(", ")}`);
    } else {
      setError("");
    }
  }, [emailData]);

  const handleKeyPress = (role) => (e) => {
    if (e.key !== "Enter") return;

    e.preventDefault();
    const emailValue = e.target.value.trim();

    if (!emailValue) return;

    const isValid = validateEmail(emailValue);
    const isManager = role === "A_MNGR";

    setEmailData((prevData) => {
      const safeData = {
        managers: Array.isArray(prevData?.managers) ? prevData.managers : [],
        users: Array.isArray(prevData?.users) ? prevData.users : [],
      };

      const targetList = isManager ? "managers" : "users";
      const currentList = safeData[targetList];

      const newEmail = {
        id: currentList.length + 1,
        email_id: emailValue,
        status: true,
        role,
        isValid,
        ...(isManager && {
          count:
            accountManagersCount +
            currentList.filter((item) => item.role === "A_MNGR").length +
            1,
        }),
      };

      return {
        ...safeData,
        [targetList]: [...currentList, newEmail].map((email) => ({
          ...email,
          status: true,
        })),
      };
    });

    e.target.value = "";
  };

  const handleInputChange = (e, role) => {
    const inputValue = e.target.value.trim();
    if (!inputValue) {
      setErrorMail(false);
    }
  };

  const handleAssignRoles = (item, role) => {
    const emailValue = item?.email;

    const isValid = validateEmail(emailValue);

    const isManager = role === "B_MNGR";

    setEmailData((prevData) => {
      const safeData = {
        managers: Array.isArray(prevData?.managers) ? prevData.managers : [],
        users: Array.isArray(prevData?.users) ? prevData.users : [],
      };

      const targetList = isManager ? "managers" : "users";
      const currentList = safeData[targetList];

      const newEmail = {
        id: currentList.length + 1,
        email_id: emailValue,
        status: true,
        role_id: item?.role_id,
        accounts_users_id: item?.accounts_user_id,
        role,
        isValid,
        ...(isManager && {
          count:
            accountManagersCount +
            currentList.filter((item) => item.role === "B_MNGR").length +
            1,
        }),
      };

      return {
        ...safeData,
        [targetList]: [...currentList, newEmail].map((email) => ({
          ...email,
          status: true,
        })),
      };
    });
  };

  // const handleAssignRoles = (item) => {
  //   const emailValue = item?.email;
  //   if (emailValue !== "") {
  //     setEmailList((prevList) => {
  //       let newCount = accountManagersCount;

  //       if (inviteManagers === "Brand Manager") {
  //         newCount =
  //           accountManagersCount +
  //           prevList.filter((item) => item.role === "Brand Manager").length +
  //           1;
  //       }

  //       return [
  //         ...prevList,
  //         {
  //           id: prevList.length + 1,
  //           email_id: emailValue,
  //           status: true,
  //           role: inviteManagers,
  //           count: newCount,
  //           role_id: item?.role_id,
  //           accounts_users_id: item?.accounts_user_id,
  //         },
  //       ];
  //     });
  //   }
  //   setEmailList((prevList) =>
  //     prevList.map((email) => ({
  //       ...email,
  //       status: true,
  //     }))
  //   );
  // };

  const togglePopoverVisibility = (id) => {
    setVisiblePopovers((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const togglePopoverVisibilityActions = (id) => {
    setVisiblePopoversActions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const togglePopoverVisibilityInvite = (open) => {
    setVisiblePopoversInvite(open);
  };

  const handleCancel = () => {
    setInviteModal(false);
    setEmailData([]);
  };

  const showModal = (role, data) => {
    const rolesData = activeNav ? reAssignBrandData : reAssignData;
    const roleData = rolesData.find((r) => r.targetRole === role);
    setSelectedUser({ roleData, data });
    setIsModalVisible(true);
    setVisiblePopovers({});
    setSwitchRoles(false);
    setVisiblePopoversActions({});
  };

  const validation = useCallback(() => {
    if (!emailData?.managers || !emailData?.users) {
      return false;
    }

    const hasManagers = emailData.managers.length > 0;
    const hasUsers = emailData.users.length > 0;

    const allEmails = [...emailData.managers, ...emailData.users];
    const allValid =
      allEmails.length > 0 ? allEmails.every((email) => email?.isValid) : true;

    const managerCountValid =
      emailData.managers.length > 0
        ? !emailData.managers.some(
            (manager) => manager?.count > 5 && manager?.role === "A_MNGR"
          )
        : true;

    const allManagersAndUsersStatusValid =
      !emailData.managers.some((manager) => manager?.status === false) &&
      !emailData.users.some((user) => user?.status === false);

    return (
      (hasManagers || hasUsers) &&
      allValid &&
      managerCountValid &&
      allManagersAndUsersStatusValid
    );
  }, [emailData]);

  //---------invite users and resend invitation------------
  const handleInvitation = async (e, inviteData = [], isResend = false) => {
    e.preventDefault();

    const usersList = inviteData
      .map((item) => {
        const email = item?.email || item?.email_id;
        const roleCode =
          item?.role || item?.role_id?.text === "Account User"
            ? "A_USER"
            : item?.role_id?.text === "Account Manager"
            ? "A_MNGR"
            : "";

        if (email) {
          return { email, role_code: roleCode };
        }
        return null;
      })
      .filter(Boolean);
    const datas = { users_list: usersList };

    // const usersListBrand = inviteData
    //   .map((item) => item?.accounts_users_id)
    //   .filter(Boolean);

    // const role_id = inviteData.some((item) => item?.role === "B_MNGR") ? 4 : 5;

    // const dataBrand = {
    //   account_id: currentAccount?.account_id || acc_id,
    //   role_id: role_id,
    //   accounts_user_ids: usersListBrand,
    // };

    const usersListBrand = inviteData
      .map((item) => {
        const account_user_id = item?.accounts_users_id;
        const roleCode = item?.role;

        if (account_user_id) {
          return { account_user_id, role_code: roleCode };
        }
        return null;
      })
      .filter(Boolean);
    const dataBrand = {
      account_id: currentAccount?.account_id || acc_id,
      users_list: usersListBrand,
    };

    let res;

    if (assignUsersList && activeNav) {
      res = await AssignBrandUsers(id, dataBrand);
    } else {
      res = await InviteTeamMembers(
        currentAccount?.account_id || acc_id,
        datas
      );
    }

    if (!activeNav) {
      if (res?.status === 200) {
        const successfulInvites = res?.data?.email_list?.filter(
          (e) => e.status === true
        );

        const failedInvites = res?.data?.email_list
          ?.filter((e) => e.status === false)
          ?.map((e) => ({
            ...e,
            isValid: validateEmail(e.email_id),
          }));

        const successToast =
          successfulInvites?.length > 0
            ? [
                {
                  id: toggleToast?.length + 1,
                  content: `Invitation sent successfully`,
                  status: "Success",
                  duration: "",
                },
              ]
            : [];

        const errorToast = failedInvites?.map((e, i) => ({
          id: toggleToast?.length + 1 + successfulInvites?.length,
          content: `${e?.email_id} - ${
            e?.message || "Failed to send invitation"
          }`,
          status: "Error",
          duration: "",
        }));

        const hasError = failedInvites?.length > 0;

        if (isResend) {
          dispatch(
            updateToggleToast([...toggleToast, ...successToast, ...errorToast])
          );
          setVisiblePopoversActions(false);
        } else if (!hasError) {
          dispatch(updateToggleToast([...toggleToast, ...successToast]));
          setInviteModal(false);
        } else if (hasError) {
          dispatch(updateToggleToast([...toggleToast, ...successToast]));
          const failedManagers =
            failedInvites?.filter((invite) => invite.role_code === "A_MNGR") ||
            [];
          const failedUsers =
            failedInvites?.filter((invite) => invite.role_code === "A_USER") ||
            [];

          setEmailData((prevData) => ({
            managers: [...failedManagers],
            users: [...failedUsers],
          }));
          // setEmailData(failedInvites);
        }

        onChange("reset_teams");
      } else {
        const errorToast = {
          id: toggleToast?.length + 1,
          content: res?.response?.data?.error || "An error occurred",
          status: "Error",
          duration: "",
        };

        if (isResend) {
          setVisiblePopoversActions(false);
        } else {
          // setIsLoading(false);
        }

        dispatch(updateToggleToast([...toggleToast, errorToast]));
      }
    } else {
      if (res?.status === 200) {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.data?.message,
              status: "Success",
              duration: "",
            },
          ])
        );
        setInviteModal(false);
        setEmailData([]);
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error,
              status: "Error",
              duration: "",
            },
          ])
        );
      }
    }
  };

  const handleSubmit = (e) => {
    if (validation()) {
      const combinedEmailList = [...emailData.managers, ...emailData.users];
      handleInvitation(e, combinedEmailList);
    }
  };

  const handleResendInvitation = (e, data) => {
    handleInvitation(
      e,
      [{ email: data.email, role_id: ACCOUNT_ROLE[data?.role_id] }],
      true
    );
  };

  //-----------reassign the role--------------
  const handleReassign = async (selectedUser) => {
    const swicthPayloads = () => {
      if (location.pathname === `/profile/account/overview/${id}/brand_info`) {
        return {
          account_id: currentAccount?.account_id,
          brand_user_id: selectedUser?.data?.brand_user_id,
          role_id: selectedUser?.roleData?.target_role_id,
          user_id: selectedUser?.data?.user_id,
        };
      } else {
        return {
          account_id: currentAccount?.account_id,
          accounts_user_id: selectedUser?.data?.accounts_user_id,
          role_id: selectedUser?.roleData?.target_role_id,
          user_id: selectedUser?.data?.user_id,
        };
      }
    };

    const payLoad = swicthPayloads();

    const apiCalls = activeNav
      ? () => reAssignBrandRole(id, payLoad)
      : () => reAssignRole(payLoad);

    try {
      setLoadingText(true);
      const res = await apiCalls();

      if (res?.status === 200) {
        setSwitchRoles(true);
      } else {
        setIsModalVisible(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error || "An error occurred",
              status: "Error",
              duration: "",
            },
          ])
        );
      }
    } catch (error) {
      setIsModalVisible(false);
      dispatch(
        updateToggleToast([
          ...toggleToast,
          {
            id: toggleToast?.length + 1,
            content: error.message || "An unexpected error occurred",
            status: "Error",
            duration: "",
          },
        ])
      );
    } finally {
      setLoadingText(false);
    }
  };

  //---------------active user, suspend user, remove user-----------
  const handleUserAction = async (actionType, selectedUser) => {
    let payLoad = {};
    let apiCall;

    if (actionType === "removeUser") {
      payLoad = {
        user_id: selectedUser?.user_id,
      };

      apiCall = () =>
        activeNav
          ? removeBrandUser(
              id,
              currentAccount?.account_id,
              selectedUser?.brand_user_id,
              payLoad
            )
          : removeUser(
              currentAccount?.account_id,
              selectedUser?.accounts_user_id,
              payLoad
            );
    } else if (actionType === "cancelInvitation") {
      payLoad = {
        user_id: selectedUser?.user_id,
      };
      apiCall = () =>
        cancelInvitation(
          currentAccount?.account_id,
          selectedUser?.accounts_user_id,
          payLoad
        );
    } else if (actionType === "activateSuspend") {
      payLoad = {
        account_id: currentAccount?.account_id,
        user_id: selectedUser?.user_id,
        status:
          selectedUser?.status === 10 || selectedUser?.status === 5 ? 4 : 10,
        accounts_user_id: selectedUser?.accounts_user_id,
      };
      apiCall = () => activeSuspendUser(payLoad);
    }

    try {
      const res = await apiCall();

      if (res?.status === 200) {
        if (actionType === "removeUser") setIsModalVisibleRemove(false);
        if (actionType === "activateSuspend") setVisiblePopoversActions(false);
        if (actionType === "cancelInvitation") setVisiblePopoversActions(false);
        fetchAccounts();
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.data?.message,
              status: "Success",
              duration: "",
            },
          ])
        );
      } else {
        throw new Error(res?.response?.data?.error || "An error occurred");
      }
    } catch (error) {
      dispatch(
        updateToggleToast([
          ...toggleToast,
          {
            id: toggleToast?.length + 1,
            content: error.message,
            status: "Error",
            duration: "",
          },
        ])
      );
    }
  };

  const handleRemoveEmail = (role, index) => {
    setEmailData((prevData) => {
      const targetList = role === "A_MNGR" ? "managers" : "users";
      const newList = [...prevData[targetList]];
      newList.splice(index, 1);
      return {
        ...prevData,
        [targetList]: newList,
      };
    });
  };

  const bodyStyle =
    "text-sm weight-medium text-[var(--font-600)] text-left pl-2";

  // role changes popover
  const assign = activeNav ? reAssignBrandData : reAssignData;

  return (
    <>
      <div className="w-full relative overflow-hidden">
        <Skeleton loading={isLoadingSkeleton} active paragraph={{ rows: 6 }}>
          {list?.users?.length > 0 ? (
            <>
              <div className="w-full h-14 sticky top-0 flex items-center pr-1">
                <div
                  className={cn(
                    "new-header2",
                    `${activeNav ? "w-[25%]" : "w-[35%]"}`
                  )}
                >
                  Name
                </div>
                <div className={cn("new-header2", "w-[25%]")}>Email</div>
                <div className={cn("new-header2", "w-[14%]")}>Status</div>
                <div className={cn("new-header2", "w-[17%]")}>Role</div>
                <div className={cn("new-header2", "w-[17%]")}>Last active</div>
                <div className={cn("new-header2", "w-[9%]")}>Actions</div>
              </div>
              <div
                className={`min-w-full  overflow-y-scroll ${
                  activeNav ? "h-[40vh]" : "h-[71vh]"
                }`}
                id="scrollableDiv"
                ref={listContainerRef}
              >
                <InfiniteScroll
                  dataLength={list?.users?.length || 0}
                  next={() => {
                    loadNextPage();
                  }}
                  hasMore={list?.users?.length < count}
                  scrollableTarget="scrollableDiv"
                >
                  <>
                    {list?.users?.map((l) => {
                      const date = dateConverter(l?.last_active_at);
                      const status = ACCOUNT_STATUS[l?.status];
                      const lastActive = `${date?.monthInShort} ${date?.date},${date?.year} - ${date?.timeWithMeridiem}`;
                      const role = activeNav
                        ? BRAND_ROLE[l?.role_id]
                        : ACCOUNT_ROLE[l?.role_id];
                      const colors = l?.user_name
                        ? getRandomColorsBassedOnLetters(l?.user_name)
                        : "";

                      return (
                        <div
                          key={l?.user_id}
                          className="w-full odd:bg-gray-50 my-1 py-4 flex items-center rounded-lg pointer"
                        >
                          <div
                            className={cn(
                              bodyStyle,
                              ` cursor-pointer pl-3 flex ${
                                activeNav ? "w-[25%]" : "w-[35%]"
                              }`
                            )}
                          >
                            {l?.user_name !== "No Name" &&
                            l?.user_name !== null ? (
                              <div
                                className="w-9 h-9 rounded-full flex justify-center text-white items-center capitalize"
                                style={{
                                  background: colors?.backgroundColor,
                                  color: colors?.color,
                                }}
                              >
                                {l?.user_name?.charAt(0)}
                              </div>
                            ) : (
                              <div className="size-[2.18vw] rounded-full flex items-center justify-center font-largest weight-medium">
                                <Avatar color="#ffffff" />
                              </div>
                            )}
                            <div className="flex items-center gap-2 pl-2">
                              {l?.user_name?.length > 15 ? (
                                <Tooltip title={l?.user_name} placement="top">
                                  <div className="new-header truncate max-w-[15ch] capitalize">
                                    {l?.user_name &&
                                      l.user_name
                                        .trim()
                                        .charAt(0)
                                        .toUpperCase() +
                                        l.user_name
                                          .trim()
                                          .slice(1)
                                          .toLowerCase()}
                                  </div>
                                </Tooltip>
                              ) : (
                                <>
                                  <div className="new-header capitalize">
                                    {l?.user_name !== null &&
                                    l?.user_name !== "No Name"
                                      ? l.user_name
                                          .trim()
                                          .charAt(0)
                                          .toUpperCase() +
                                        l.user_name
                                          .trim()
                                          .slice(1)
                                          .toLowerCase()
                                      : "--"}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>

                          <div className={cn("new-body-text", "w-[25%]")}>
                            {l?.email?.length > 30 ? (
                              <div className="!max-w-[40%] w-full flex-1 truncate">
                                <Tooltip title={l?.email} placement="top">
                                  {l?.email &&
                                    l?.email.trim().charAt(0).toUpperCase() +
                                      l?.email.trim().slice(1).toLowerCase()}
                                </Tooltip>
                              </div>
                            ) : (
                              <div>{l?.email !== null ? l?.email : "--"}</div>
                            )}
                          </div>

                          <div
                            className={cn(
                              "new-body-text",
                              "w-[14%] flex items-center gap-1.5"
                            )}
                            style={{
                              color: status?.color,
                            }}
                          >
                            <div
                              className="flex-row align-center p-0.5 pl-10 pr-10 b-radius-6 gap-1"
                              style={{
                                background: status?.background,
                                border: `2px solid ${status?.border}`,
                              }}
                            >
                              {status?.text}
                            </div>
                          </div>

                          <div className={cn("new-body-text", "w-[17%]")}>
                            {currentAccount?.role !== "Account User" &&
                            currentBrand?.role !== "Campaign Manager" ? (
                              <Popover
                                content={
                                  role?.text !== "Account Owner" && (
                                    <>
                                      {assign?.map((role) => (
                                        <div
                                          key={role?.role}
                                          className="flex-row align-center pointer tags-border py-4 px-6"
                                          onClick={() =>
                                            showModal(role?.role, l)
                                          }
                                        >
                                          <span className="font14px-400 text-[var(--textBlack)]">
                                            {role?.role}
                                          </span>
                                        </div>
                                      ))}
                                    </>
                                  )
                                }
                                trigger="click"
                                arrow={false}
                                open={!!visiblePopovers[l?.user_id]}
                                onOpenChange={() =>
                                  togglePopoverVisibility(l.user_id)
                                }
                                placement="bottom"
                              >
                                <div
                                  className={`flex items-center gap-2 ${
                                    role?.text === "Account Owner"
                                      ? "cursor-default"
                                      : "cursor-pointer"
                                  }`}
                                >
                                  <div
                                    style={{ backgroundColor: role?.border }}
                                    className="w-[0.23vw] h-[3vh]"
                                  ></div>
                                  <p>{role?.text}</p>
                                  <div
                                    className={cn(
                                      "transition-transform duration-200 ease-linear"
                                    )}
                                  >
                                    <div
                                      className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent ${
                                        role?.text === "Account Owner"
                                          ? "border-t-[#D1D3D8]"
                                          : "border-t-[#777]"
                                      } translate-y-[25%]`}
                                    ></div>
                                  </div>
                                </div>
                              </Popover>
                            ) : (
                              <div
                                className={`flex items-center gap-2 cursor-not-allowed`}
                              >
                                <div
                                  style={{ backgroundColor: role?.border }}
                                  className="w-[0.23vw] h-[3vh]"
                                ></div>
                                <p>{role?.text}</p>
                                <div
                                  className={cn(
                                    "transition-transform duration-200 ease-linear"
                                  )}
                                >
                                  <div
                                    className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent ${
                                      role?.text === "Account Owner"
                                        ? "border-t-[#D1D3D8]"
                                        : "border-t-[#777]"
                                    } translate-y-[25%]`}
                                  ></div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={cn("new-body-text", "w-[17%]")}>
                            {l?.last_active_at !== null ? lastActive : "--"}
                          </div>

                          <div
                            className={cn(
                              bodyStyle,
                              "w-[9%] flex items-center gap-3"
                            )}
                          >
                            {currentAccount?.role !== "Account User" &&
                            currentBrand?.role !== "Campaign Manager" ? (
                              <Popover
                                content={
                                  <>
                                    <div className="flex-column p-[15px] mb-3.5">
                                      {!activeNav && (
                                        <div
                                          className={`campaign-edit-test flex-row align-center m-5 pointer tags-border pb-10`}
                                          onClick={(e) => {
                                            if (
                                              l?.status === 10 ||
                                              l?.status === 4 ||
                                              l?.status === 5
                                            ) {
                                              handleUserAction(
                                                "activateSuspend",
                                                l
                                              );
                                            } else {
                                              handleResendInvitation(e, l);
                                            }
                                          }}
                                        >
                                          {l?.status === 4 ? (
                                            <>
                                              <img
                                                src={ICONS?.completed}
                                                alt="active Icon"
                                                className=""
                                              />
                                              <span className="pl-10 text-[0.8vw] text-[var(--contentText)] font-500">
                                                Activate User
                                              </span>
                                            </>
                                          ) : l?.status === 10 ||
                                            l?.status === 5 ? (
                                            <>
                                              <img
                                                src={ICONSS?.removeUser}
                                                alt="Suspend Icon"
                                                className="w-5"
                                              />
                                              <span className="pl-10 text-[0.8vw] text-[var(--contentText)] font-500">
                                                Suspend User
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src={ICONS?.syncChange}
                                                alt="Resend Icon"
                                                className=""
                                              />
                                              <span className="pl-10 text-[0.8vw] text-[var(--contentText)] font-500">
                                                Resend Invitation
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      )}

                                      <div
                                        className="campaign-delete-test flex-row align-center pr-10  pointer"
                                        onClick={() => {
                                          if (l?.status === 1) {
                                            handleUserAction(
                                              "cancelInvitation",
                                              l
                                            );
                                          } else {
                                            setIsModalVisibleRemove(true);
                                            setVisiblePopoversActions(false);
                                            setSelectedUser(l);
                                          }
                                        }}
                                      >
                                        <img
                                          src={ICONS?.RedDeleteIcon}
                                          alt=""
                                          className="mt-10"
                                        />
                                        <span className="text-[0.8vw] text-[--fontRed] font-500 mt-10">
                                          {l?.status === 1
                                            ? "Cancel Invitation"
                                            : "Remove User"}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                }
                                trigger="click"
                                arrow={false}
                                placement="bottom"
                                open={!!visiblePopoversActions[l?.user_id]}
                                onOpenChange={() =>
                                  togglePopoverVisibilityActions(l.user_id)
                                }
                              >
                                <div
                                  className={`dots group/dots w-fit min-w-11 pl-10 py-4 flex items-center gap-0.5 hover:bg-gray-100 rounded-md`}
                                >
                                  <img src={ICONSS?.dotStyleIcon} />
                                </div>
                              </Popover>
                            ) : (
                              <div
                                className={`dots group/dots w-fit min-w-11 pl-10 py-4 flex items-center gap-0.5 cursor-not-allowed rounded-md`}
                              >
                                <img src={ICONSS?.dotStyleIcon} />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </>
                </InfiniteScroll>
              </div>
            </>
          ) : (searchKey ||
              selectedAccountStatusId?.length === 0 ||
              selectedRoleId?.length === 0) &&
            !activeNav ? (
            <div className="h-[60vh] flex justify-center mt-20 pt-20">
              <div className="flex-row h-10 mt-20">
                <img
                  src={ICONS?.emptySearch}
                  alt="emptySearch"
                  className="w-14"
                />
                <div className="flex-column gap-3 pl-10">
                  <span className="new-header3 ">
                    Sorry, there are no matching results
                  </span>
                  <span className="no-search-body">
                    Use different filters or{" "}
                    <span
                      className="text-[#6940F2] pointer"
                      onClick={() => {
                        setSearchKey("");
                        clearAccountStatusList();
                        clearRoleList();
                      }}
                    >
                      reset to default.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ) : !activeNav ? (
            <div className="w-full h-[61vh] flex-row justify-center">
              <NoData
                title="No Brands Found!"
                content="You don’t have any brands under your account. Create new brand and start using GainWix.ai."
                modal={
                  <div>
                    {(currentAccount?.role === "Account Owner" ||
                      currentAccount?.role === "Account Manager") && (
                      <button
                        className="button-primary2 px-4 py-2.5 mb-20"
                        onClick={() => setBrandPopover(true)}
                      >
                        Add new brand
                      </button>
                    )}
                  </div>
                }
                icon={ICONS?.campaignNoData}
              />
            </div>
          ) : (
            <div className=" h-[45vh] flex flex-col items-center justify-center">
              <img src={ICONS?.noChartData} alt="noChartData" />
              <p className="text-[#616874]">There’s no data yet</p>
            </div>
          )}
        </Skeleton>
      </div>

      <Modal
        footer={null}
        open={isModalVisible}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}
      >
        {!switchRoles && selectedUser && (
          <div className="p-8">
            <div>
              <img src={ICONSS?.changeRole} alt="image" />
              <h2 className="font18px-600 py-3">Change Role?</h2>
              <p className="font14px-500 text-[#616874]">
                {selectedUser?.roleData?.description}
              </p>
            </div>

            <div className="flex gap-7 justify-end pt-8">
              <button
                className="text-[#616874] font16px-600"
                onClick={() => setIsModalVisible(false)}
              >
                Nah, don’t!
              </button>
              <button
                className="button-primary p-2"
                onClick={() => handleReassign(selectedUser)}
              >
                Sure, switch it up!
              </button>
            </div>
          </div>
        )}

        {switchRoles && (
          <div className="p-8">
            {loadingText ? (
              <div>
                <img src={ICONSS?.alertRole} />
                <h2 className="font18px-600 py-3">
                  Switching roles, hang tight!
                </h2>
                <p className="font14px-500 text-[#616874]">
                  Just a moment, please!
                </p>
              </div>
            ) : (
              <>
                <div>
                  <img src={ICONSS?.roleSuccessIcon} />
                  <h2 className="font18px-600 py-3">
                    The role has been successfully changed.
                  </h2>
                </div>

                <div className="flex gap-7 justify-end pt-8">
                  <button
                    className="button-primary py-2 px-4"
                    onClick={() => {
                      setIsModalVisible(false);
                      fetchAccounts();
                    }}
                  >
                    OK
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </Modal>

      <Modal
        footer={null}
        open={inviteModal ? inviteModal : isModalVisibleRemove}
        className={`asp-modal-popup-small ${inviteModal && "!w-[45vw]"}`}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}
      >
        {inviteModal ? (
          <div className="">
            <Header
              title={
                activeNav
                  ? "Assign Users to Brand"
                  : "Invite Users to Your Account"
              }
              titleStyle="border-b-none"
              titleSize="none"
              subTitleSize="none"
              handleCancel={handleCancel}
              className="font20px-500"
            />
            <form className="w-100 flex flex-col px-8 pb-8">
              {/* {accountManagersCount < 5 && ( */}
              <div className="flex-row gap-4">
                <div className="w-[30%] h-12 font14px-400 text-[#4C515B] border border-[var(--border-input)] mt-10 b-radius-6  flex-row align-center  space-between prl-10">
                  {`${activeNav ? "Brand Manager" : "Account Manager"}`}
                </div>
                <div className="flex flex-col w-full">
                  <div
                    className={`h-[15vh] w-full rounded-lg mt-10 overflow-auto px-2 border ${
                      emailData?.managers?.some(
                        (data) =>
                          data?.status === false && data?.role === "A_MNGR"
                      )
                        ? "border-[#FF543E]"
                        : "border-[#E8E8EA]"
                    }`}
                    onClick={() => {
                      if (assignUsersList && activeNav) {
                        setAssignPopoverOpen(true);
                      }
                    }}
                  >
                    <div className="flex flex-wrap pt-10 gap-3">
                      {emailData?.managers?.length > 0 &&
                        emailData?.managers?.map((emailObj, index) => (
                          <div
                            key={index}
                            className={`rounded-xl px-2 py-1 flex items-center ${
                              !emailObj.isValid
                                ? "bg-white border border-[#FF543E]"
                                : "bg-[#F4F6F8]"
                            }`}
                          >
                            <p>{emailObj?.email_id} </p>
                            <img
                              src={ICONS?.popupX}
                              alt="icons"
                              className="w-[1.2vw] h-[1.2vw] pointer"
                              onClick={() => handleRemoveEmail("A_MNGR", index)}
                            />
                          </div>
                        ))}

                      <div
                        className={`w-100 mr-1.5 ${
                          emailData?.managers?.length > 0 &&
                          "border-l-2 border-[#000000]"
                        }`}
                      >
                        {!assignUsersList?.length > 0 ? (
                          <>
                            {/* {emailData?.managers?.length < 5 && ( */}
                            <input
                              type="text"
                              placeholder={
                                activeNav ? "Select Email" : "Type your email"
                              }
                              onChange={(e) => handleInputChange(e, "A_MNGR")}
                              onKeyDown={handleKeyPress("A_MNGR")}
                              className={"w-100 ml-1.5"}
                            />
                            {/* )} */}
                          </>
                        ) : (
                          <div className="popover-overlay-custom">
                            <Popover
                              trigger={"click"}
                              arrow={false}
                              open={assignPopoverOpen}
                              placement="bottom"
                              overlayClassName="popover-overlay-custom"
                              onOpenChange={(open) =>
                                setAssignPopoverOpen(open)
                              }
                              content={
                                <div className="h-[30vh] p-15 overflow-scroll w-[25vw]">
                                  {assignUsersList?.map((item) => {
                                    const colors =
                                      getRandomColorsBassedOnLetters(
                                        item?.user_name
                                      );

                                    return (
                                      <div
                                        key={item?.id}
                                        className="flex-row align-center p-10 mt-5 mb-5 pointer hover:bg-[var(--BG-25)] b-radius-6"
                                        onClick={() => {
                                          handleAssignRoles(item, "B_MNGR");
                                        }}
                                      >
                                        <span
                                          className="capitalize px-2.5 py-1 rounded-full"
                                          style={{
                                            color: colors.color,
                                            backgroundColor:
                                              colors.backgroundColor,
                                          }}
                                        >
                                          {item?.user_name?.charAt(0)}
                                        </span>
                                        <div className="flex-column pl-10">
                                          <span className="font13px-400 text-[var(--textBlack)]">
                                            {item?.user_name}
                                          </span>
                                          <span className="mt-5 font11px-400 text-[var(--font-600)]">
                                            {item?.email}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              }
                            >
                              {activeNav && (
                                <span className="invisible relative">hii</span>
                              )}
                            </Popover>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {emailData?.managers?.some(
                    (data) => data?.status === false
                  ) && (
                    <p className="text-[#FF543E] font14px-500">
                      {
                        emailData?.managers.find(
                          (data) => data?.status === false
                        )?.message
                      }
                    </p>
                  )}
                  <p className="text-[var(--font-600)] font14px-500">
                    Add upto 5 members
                  </p>

                  {/* {emailData?.managers?.some((data) => data?.count > 5) && (
                    <p className="text-[#FF543E] font14px-500">
                      The number of account managers has exceeded the limit
                    </p>
                  )} */}
                </div>
              </div>
              {/* )} */}

              <div className="flex-row gap-4">
                <div className="w-[30%] h-12 font14px-400 text-[#4C515B] border border-[var(--border-input)] mt-10 b-radius-6  flex-row align-center  space-between prl-10">
                  {`${activeNav ? "Campaign Manager" : "Account User"}`}
                </div>
                <div className="flex-col w-full">
                  <div
                    className={`h-[20vh] rounded-lg mt-10 overflow-auto px-2 border ${
                      emailData?.users?.some(
                        (data) =>
                          data?.status === false && data?.role === "A_USER"
                      )
                        ? "border-[#FF543E]"
                        : "border-[#E8E8EA]"
                    }`}
                    onClick={() => {
                      if (assignUsersList && activeNav) {
                        setAssignPopoverOpenCampaign(true);
                      }
                    }}
                  >
                    <div className="flex flex-wrap pt-10 gap-3">
                      {emailData.users?.length > 0 &&
                        emailData.users?.map((emailObj, index) => (
                          <div
                            key={index}
                            className={`rounded-xl px-2 py-1 flex items-center ${
                              !emailObj.isValid
                                ? "bg-white border border-[#FF543E]"
                                : "bg-[#F4F6F8]"
                            }`}
                          >
                            <p>{emailObj?.email_id} </p>
                            <img
                              src={ICONS?.popupX}
                              alt="icons"
                              className="w-[1.2vw] h-[1.2vw] pointer"
                              onClick={() => handleRemoveEmail("A_USER", index)}
                            />
                          </div>
                        ))}

                      <div
                        className={`w-100 mr-1.5 ${
                          emailData.users?.length > 0 &&
                          "border-l-2 border-[#000000]"
                        }`}
                      >
                        {!assignUsersList?.length > 0 ? (
                          <input
                            type="text"
                            placeholder={
                              activeNav ? "Select Email" : "Type your email"
                            }
                            onChange={(e) => handleInputChange(e, "A_USER")}
                            onKeyDown={handleKeyPress("A_USER")}
                            className="w-100 ml-1.5"
                          />
                        ) : (
                          <div className="popover-overlay-custom1">
                            <Popover
                              trigger={"click"}
                              arrow={false}
                              open={assignPopoverOpenCampaign}
                              placement="bottom"
                              overlayClassName="popover-overlay-custom1"
                              onOpenChange={(open) =>
                                setAssignPopoverOpenCampaign(open)
                              }
                              content={
                                <div className="h-[30vh] p-15 overflow-scroll w-[25vw]">
                                  {assignUsersList?.map((item) => {
                                    return (
                                      <div
                                        key={item?.id}
                                        className="flex-column  mt-5 mb-5 pointer hover:bg-[var(--BG-25)] p-10 b-radius-6"
                                        onClick={() =>
                                          handleAssignRoles(item, "C_MNGR")
                                        }
                                      >
                                        <span className="font13px-400 text-[var(--textBlack)]">
                                          {item?.user_name}
                                        </span>
                                        <span className="mt-5 font11px-400 text-[var(--font-600)]">
                                          {item?.email}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              }
                            >
                              {activeNav && (
                                <span className="invisible relative">hii</span>
                              )}
                            </Popover>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="text-[var(--font-600)] font14px-500">
                    Add upto 20 members
                  </p>
                  <div>
                    {emailData?.users?.some(
                      (data) => data?.status === false
                    ) && (
                      <p className="text-[#FF543E] font14px-500">
                        {
                          emailData?.users?.find(
                            (data) => data?.status === false
                          )?.message
                        }
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {error && <p style={{ color: "red" }}>{error}</p>}

              <div className="flex gap-7 justify-end">
                <button
                  disabled={!validation() || !!error}
                  className={cn(
                    "px-4 py-1.5 b-radius-6 font16px-600 mt-9 flex items-center justify-center",
                    !validation() ||
                      !!error ||
                      (activeNav && assignUsersList?.length === 0)
                      ? "bg-[var(--BG-100)] text-[var(--font-600)] cursor-not-allowed"
                      : "bg-[var(--new-primary)] text-[var(--white)] cursor-pointer"
                  )}
                  onClick={handleSubmit}
                >
                  {activeNav ? "Assign User(s)" : "Send Invite"}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <NewAlert
            type="new"
            open={isModalVisibleRemove}
            icon={ICONSS?.removeUser}
            setOpen={setIsModalVisibleRemove}
            content={
              <>
                <h2 className="font18px-600 pb-2">Remove User?</h2>
                <p>{`Are you sure that you want to remove the user ${selectedUser?.user_name} ?`}</p>
              </>
            }
            noName="Yes, Remove"
            yesName="No, Keep it"
            noButtonClassName="!font-md !weight-semibold"
            handleNo={() => {
              setIsModalVisibleRemove(false);
            }}
            handleYes={() => {
              handleUserAction("removeUser", selectedUser);
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default AccountUsersList;
