import React, { useEffect, useState } from "react";
import { ICONSS } from "../../../assets/icons/index1";
import { ICONS } from "../../../assets/icons";
import { dateConverter } from "../../../helper/dateConverter";
import moment from "moment";
const invoiceList = [
  // {
  //   id: 1,
  //   label: "Invoice No.",
  // },
  // {
  //   id: 2,
  //   label: "Invoice Date",
  //   key: "transaction_date",
  // },
  {
    id: 3,
    label: "Paid at",
    key: "transaction_date",
  },
  {
    id: 4,
    label: "Transaction ID",
    key: "transaction_id",
  },
  {
    id: 5,
    label: "Payment Method",
    key: "payment_method",
  },
];

const billed = [
  {
    id: 1,
    label: "Billed By",
    name: "GainWix.ai",
    address:
      "455 A, kali amman kovil street, Koyambedu Chennai, Tamil Nadu, India-600107.",
    emailLabel: "Email",
    // phoneLabel: "Phone",
    email: "sam@aptonworks.com",
    // phone: "+91",
  },
  {
    id: 2,
    label: "Billed To",
    name: "",
    address:
      "455 A, kali amman kovil street, Koyambedu Chennai, Tamil Nadu, India-600107.",
    emailLabel: "Email",
    // phoneLabel: "Phone",
    email: "sam@aptonworks.com",
    // phone: "+91",
    key: "address",
  },
];

const plans = [
  {
    id: 1,
    label: "Plan Details",
    name: "GainWix.ai",
    address:
      "455 A, kali amman kovil street, Koyambedu Chennai, Tamil Nadu, India-600107.",
    emailLabel: "Email",
    phoneLabel: "Phone",
    email: "sam@aptonworks.com",
    phone: "+91",
  },
  {
    id: 2,
    label: "Amount",
    name: "",
    address:
      "455 A, kali amman kovil street, Koyambedu Chennai, Tamil Nadu, India-600107.",
    emailLabel: "Email",
    phoneLabel: "Phone",
    email: "sam@aptonworks.com",
    phone: "+91",
  },
  {
    id: 3,
    label: "Tax",
    name: "",
    address:
      "455 A, kali amman kovil street, Koyambedu Chennai, Tamil Nadu, India-600107.",
    emailLabel: "Email",
    phoneLabel: "Phone",
    email: "sam@aptonworks.com",
    phone: "+91",
  },
  {
    id: 4,
    label: "Total",
    name: "",
    address:
      "455 A, kali amman kovil street, Koyambedu Chennai, Tamil Nadu, India-600107.",
    emailLabel: "Email",
    phoneLabel: "Phone",
    email: "sam@aptonworks.com",
    phone: "+91",
  },
];

const InvoiceDetails = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const invoiceData = localStorage.getItem("invoiceData");
    if (invoiceData) {
      const invoiceData = JSON.parse(localStorage.getItem("invoiceData"));
      setData(invoiceData);
    }
  }, []);

  const date = dateConverter(data?.transaction_date);

  const endDate = moment
    .utc(data?.items?.[0]?.settings?.subscription?.end_date)
    .add(5, "hours")
    .add(30, "minutes")
    .format("MMM DD, YYYY");

  const startDate = moment
    .utc(data?.items?.[0]?.settings?.subscription?.start_date)
    .add(5, "hours")
    .add(30, "minutes")
    .format("MMM DD, YYYY");

  return (
    <div className="w-100 h-[100vh] overflow-scroll bg-black">
      <div className="w-65 mx-auto bg-white ">
        <div
          style={{
            background: "linear-gradient(90deg, #0ACD95 40%, #6940F2 100%)",
            borderBottomLeftRadius: "16px",
            borderBottomRightRadius: "16px",
            height: "10px",
          }}
        >
          <span className="invisible">hii</span>
        </div>
        <div className="p-[35px]">
          <div className="flex-row align-center space-between">
            <span className="font28px-600 text-[var(--textBlack)]">
              Billing
            </span>
            <img src={ICONS?.logoGain} alt="logoGain" />
          </div>
          <div className="pb-10">
            {invoiceList?.map((item) => {
              return (
                <div className="grid grid-cols-2 w-40 mt-15" key={item?.id}>
                  <span className="font16px-400 text-[var(--font-600)]">
                    {item?.label}
                  </span>
                  <span className="font16px-500 text-[var(--textBlack)]">
                    {item.key
                      ? item.key === "transaction_date"
                        ? `${date?.monthInShort} ${date?.date}, ${date?.year}`
                        : data?.[item.key] || "N/A"
                      : "N/A"}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="w-100 flex-row align-center space-between gap-6 mt-20">
            {billed?.map((item) => (
              <div
                className={`border border-[#E8E8EA] bg-[#F9F9FC] b-radius flex-column p-15 ${
                  item?.id === 1 ? "w-50" : "w-50"
                }`}
                key={item?.id}
              >
                <span className="pb-20 font18px-400 text-[#6940F2]">
                  {item?.label}
                </span>
                <span className="pb-10 font18px-600 text-[var(--textBlack)]">
                  {item?.name || "--"}
                </span>
                <span className="w-80 pt-5 font16px-400 text-[var(--textBlack)]">
                  {item?.id === 2
                    ? `${
                        data?.address !== null
                          ? `${data?.address?.address_line_1}, ${data?.address?.city}, ${data?.address?.province}, ${data?.address?.country} - ${data?.address?.postal_index_code}`
                          : "--"
                      }`
                    : item?.address}
                </span>
                <div className="flex-row w-50 pt-10 font16px-400">
                  <span className="w-70 text-[var(--font-600)]">
                    {item?.emailLabel}
                  </span>
                  <span className="w-100 text-[var(--textBlack)]">
                    {item?.email}
                  </span>
                </div>
                <div className="flex-row w-50 gap-5 pt-10 font16px-400">
                  <span className="text-[var(--font-600)]">
                    {item?.phoneLabel}
                  </span>
                  <span className="text-[var(--textBlack)]">{item?.phone}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-20">
            <div
              className="flex-row alin-center bg-[#74CAA9] font16px-500 text-white ptb-15"
              style={{
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
              }}
            >
              <span className="w-40 pl-20">Plan Details</span>
              <span className="w-20">Amount</span>
              <span className="w-[33%]">Tax</span>
              <span className="">Total</span>
            </div>
            <div className="flex-row alin-center border font16px-600 text-[var(--textBlack)] border-[#E8E8EA] ptb-20">
              {/* <span className="w-40 pl-20">
                {data?.items?.[0]?.transaction_type === "CREDITS"
                  ? "Credits"
                  : `Growth Plan ${(
                      <span className="font16px-500 w-40">
                        {
                          data?.items?.[0]?.settings?.subscription
                            ?.billing_cycle
                        }
                      </span>
                    )}`}
              </span> */}
              <div className="w-40 pl-20">
                <p>
                  {data?.items?.[0]?.transaction_type === "CREDITS" ? (
                    "Credits"
                  ) : (
                    <>
                      Growth Plan
                      <span className="font16px-500 w-40 capitalize pl-1">
                        (
                        {
                          data?.items?.[0]?.settings?.subscription
                            ?.billing_cycle
                        }
                        )
                      </span>
                    </>
                  )}
                </p>
                <p className="font16px-400 text-[var(--font-600)] mt-1">
                  {startDate} to {endDate}
                </p>
              </div>

              <span className="w-20">₹{data?.items?.[0]?.amount}</span>
              <span className="w-[33%]">18%</span>
              <span className="pr-20">₹{data?.items?.[0]?.total_cost}</span>
            </div>
            <div className="w-100 mt-20 flex-row space-between">
              <div className="flex-column mt-20">
                <span className="font15px-400 text-[var(--font-600)]">
                  Payment Method:
                </span>
                <span className="font15px-600 text-[var(--textBlack)] italic capitalize pt-10">
                  {data?.payment_method}
                </span>
              </div>
              <div className="w-50 mt-20">
                {/* <div className="gap-y-3">
                <span className="font16px-400 text-[var(--font-600)]">
                  Sub Total
                </span>
                <span></span>
              </div> */}
                <div className="flex-row space-between">
                  <span className="font16px-400 text-[var(--font-600)]">
                    GST(18%)
                  </span>
                  <span>₹{data?.tax_amount}</span>
                </div>
                <div className="mb-20 flex-row space-between pt-10">
                  <span className="font16px-400 text-[var(--font-600)]">
                    Total
                  </span>
                  <span>₹{data?.total_cost}</span>
                </div>

                <div className="border-[var(--textBlack)] border-b-2 border-t-2 flex-row space-between">
                  <span className="font16px-600 text-[var(--textBlack)] mt-10 mb-10">
                    Amount Due
                  </span>
                  <span className="mt-10 mb-10">₹{data?.total_cost}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mb-20 mt-10 font14px-400 text-[#4C515B]">
          This bill has been generated automatically by our system. An
          authorized signature is not required.
        </div>
        <div
          style={{
            background: "linear-gradient(90deg, #0ACD95 40%, #6940F2 100%)",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            height: "10px",
          }}
          className="flex-column justify-end align-end"
        >
          <span className="invisible text-end">hii</span>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
