import React, { useCallback, useEffect, useState } from "react";
import AccountOverview from "../../../assets/customSVG/accounts/AccountOverview";
import { ICONS } from "../../../assets/icons/index";
import { ICONSS } from "../../../assets/icons/index1";

import { Modal, Popover, Tooltip } from "antd";

import Loader from "../../../components/commonComponents/Loader/Index";

// constant
import {
  brandAccountOverview,
  conversationPricing,
  credits,
  pricing,
} from "../../../constant/app/account/account";

import { dateConverter } from "../../../helper/dateConverter";

// components
import Header from "../../../components/commonComponents/modals/Header";
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";
import {
  brandCreditsReportApi,
  brandPaymentApi,
  brandsInfoApi,
  brandUserEditApi,
  creditsCancelApi,
  creditsSendApi,
  setupApi,
  transactionsApi,
  transactionsResponseApi,
} from "../api/Api";
import AccountUsersTable from "./AccountUsersTable";
import Credits from "./Credits";
import Overview from "./Overview";
import Subscription from "./Subscription";

import moment from "moment";
import { useRazorpay } from "react-razorpay";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Arrow from "../../../assets/customSVG/Arrow";
import FullArrow from "../../../assets/customSVG/FullArrow";
import { numberOnly, textOnly } from "../../../helper/validation";
import { useSelect } from "../../../hooks/useSelect";
import useToggle from "../../../hooks/useToggle";
import { updateToggleToast } from "../../../reduxToolkit/appSlice";
import StatusLists from "./StatusLists";
import { inputsArray } from "../constants/home";
import { cityListApi, countryListApi, stateListApi } from "../../crm/api/Api";
import { category } from "../../auth/components/onboarding/BrandCreation";
import { cn } from "../../../helper/cn";
import Select from "../../../components/commonComponents/select";
import TimeCircle from "../../../assets/customSVG/accounts/TimeCircle";
import { validateURL } from "../../../helper/checkUrls";
import Invoice from "./Invoice";

const icons = {
  "Starter Plan": ICONSS?.starterPlanIcon,
  "Magnum Plan": ICONSS?.magnumPlanIcon,
  "Growth Plan": ICONSS?.growthPlanIcon,
};

const filters = [
  {
    id: 1,
    value: "Starter",
  },
  {
    id: 2,
    value: "Growth",
  },
  {
    id: 3,
    value: "Magnum",
  },
];

const minimumValue = {
  "+ 1,000.00": 1000,
  "+ 2,500.00": 2500,
  "+ 10,000.00": 10000,
  "+ 50,000.00": 50000,
};

const filterValuesUpgrade = {
  Starter: "Starter Plan",
  "+ 2,500.00": 2500,
  "+ 10,000.00": 10000,
  "+ 50,000.00": 50000,
};

const BrandOverview = () => {
  const { Razorpay } = useRazorpay();
  const navigate = useNavigate();
  const { currentBrand, toggleToast, currentAccount } = useAspSelector(
    (state) => state?.app
  );
  const dispatch = useAspDispatch();

  const location = useLocation();
  const { id } = useParams();

  const [brandsData, setBrandsData] = useState(
    location?.state?.brandsData || []
  );
  const [costPopover, setCostPopover] = useState(false);

  const [dynamicInputs, setDynamicInputs] = useState([]);
  const [editInfo, setEditInfo] = useState(false);
  const [locationError, setLocationError] = useState("");
  const [dynamicError, setDynamicError] = useState("");
  // LOCATIONS
  const [countryListss, setCountryListss] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [filterValue, setFilterValue] = useState("");
  const [activeNav, setActiveNav] = useState("Overview");
  const [hoveredNav, setHoveredNav] = useState(null);
  const [addCredits, setAddCredits] = useState(false);
  const [creditValue, setCreditValue] = useState(null);
  const [creditsSend, setCreditsSend] = useState(false);
  const [brandPopover, setBrandPopover] = useState(false);
  const [proceed, setProceed] = useState(false);

  const [creditsResponse, setCreditsResponse] = useState([]);
  const [createLoader, setCreateLoader] = useToggle(false);

  const [isUpgrade, setIsUpgrade] = useToggle(false);
  const [isApiSuccess, setIsApiSuccess] = useToggle(false);
  const [proceedTrue, setproceedTrue] = useState(false);

  const [selectedPrice, setSelectedPrice] = useSelect("Quarterly");
  const [isPlanSelected, setIsPlanSelected] = useToggle(false);
  const [selectedPlan, setSelectedPlan] = useSelect(null);

  // setup status
  const [setupData, setSetupData] = useState([]);

  const Planscard = ({ id, plan }) => {
    return (
      <button
        className={`w-[8vw] px-[1vw] py-[0.5vw] rounded-[2vw] font-normal weight-semibold text-center transition-colors ease-linear duration-300 ${
          selectedPrice === plan
            ? "text-[var(--new-primary)] bg-white"
            : "bg-transparent text-[#202421]"
        }`}
        onClick={() => {
          setSelectedPrice(plan);
        }}
      >
        {plan}
      </button>
    );
  };

  // Payment history
  const [paymentHistoryData, setPaymentHistoryData] = useState([]);
  const [yearFilterOpen, setYearFilterOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [listLoading, setListLoading] = useToggle(false);

  const baseYear = 2019;
  const currentYear = new Date().getFullYear();
  const endYear = currentYear > 2024 ? currentYear : 2024;
  const years = Array.from(
    { length: endYear - baseYear + 1 },
    (_, i) => baseYear + i
  ).reverse();

  // Credits Report
  const todayDate = new Date();

  const [creditReportData, setCreditReportData] = useState([]);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(todayDate);
  const today = moment();
  const campaignEndDate = moment(customEndDate);
  const referenceEndDate = campaignEndDate?.isBefore(today)
    ? campaignEndDate
    : today;

  const initialEndDate = referenceEndDate.format("YYYY-MM-DD");
  const initialStartDate = referenceEndDate
    .clone()
    .subtract(6, "days")
    .format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [filter, setFilter] = useState([]);
  // const [proceedToCheckEnable, setProceedToCheckEnable] = useState(true);

  // oveview

  useEffect(() => {
    if (brandsData) {
      setDynamicInputs(updateData({ datas: inputsArray, value: brandsData }));
    }
  }, [brandsData]);

  const updateData = ({ datas, value }) => {
    const res = datas?.map((data) => {
      const { key, secondaryKey } = data;
      if (secondaryKey) {
        const data_id =
          secondaryKey === "country"
            ? value[key]?.[0]?.["country_id"]
            : secondaryKey === "city"
            ? value[key]?.[0]?.["city_id"]
            : secondaryKey === "province"
            ? value[key]?.[0]?.["province_id"]
            : "";
        return {
          ...data,
          value: value[key]?.[0]?.[secondaryKey],
          locations_id: data_id,
        };
      }
      return { ...data, value: value[key] };
    });
    return res;
  };

  const handleUpdate = () => {
    const payLoad = {
      brand_name: dynamicInputs?.[0]?.value,
      website_url: dynamicInputs?.[2]?.value,
      category: dynamicInputs?.[1]?.value,
      address: {
        id: brandsData?.locations_with_names?.[0]?.location,
        address_line_1: dynamicInputs?.[3]?.value,
        country_id: dynamicInputs?.[4]?.locations_id,
        province_id: dynamicInputs?.[5]?.locations_id,
        city_id: dynamicInputs?.[6]?.locations_id,
        postal_index_code: dynamicInputs?.[7]?.value,
      },
    };

    if (buttonEnable()) {
      brandUserEditApi(id, payLoad).then((res) => {
        setIsApiSuccess(true);

        if (res?.status === 200) {
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content: "Brand Information Updated",
                status: "Success",
                duration: "",
              },
            ])
          );
          setEditInfo(false);
          setproceedTrue(true);
          setDynamicError("");
          brandsInfoApi(id).then((res) => {
            if (res?.status === 200) {
              setBrandsData(res?.data);
            }
          });
          // setProceedToCheckEnable(true);
        } else {
          setDynamicError(res?.response?.data?.error);
        }
      });
    }
  };

  useEffect(() => {
    brandsInfoApi(id)
      .then((res) => {
        if (res?.status === 200) {
          setBrandsData(res?.data);
          setIsApiSuccess(false);
        } else {
          console.error(
            "Error fetching brand info:",
            res?.response?.data?.error
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching brand info:", error);
      });
  }, [isApiSuccess, id]);

  const countryLists = () => {
    countryListApi().then((res) => {
      if (res && res.data?.data?.length > 0) {
        const updatedData = res?.data?.data?.[0]
          ? { ...res.data.data[0], label: res.data.data[0].name }
          : null;
        setCountryListss([updatedData]);
        return updatedData;
      }
    });
  };

  const stateLists = async () => {
    stateListApi(dynamicInputs?.[4]?.locations_id).then((res) => {
      if (res && res?.data?.data?.length > 0) {
        const updateState = res?.data?.data?.map((item) => {
          return { ...item, label: item?.name };
        });
        setStateList(updateState);
      }
    });
  };

  const cityLists = async () => {
    cityListApi(dynamicInputs?.[5]?.locations_id).then((res) => {
      if (res && res?.data?.data?.length > 0) {
        const updateCity = res?.data?.data?.map((item) => {
          return { ...item, label: item?.name };
        });
        setCityList(updateCity);
      }
    });
  };

  useEffect(() => {
    countryLists();
    const countryData = getData(6);
    if (countryData?.locations_id) stateLists();
    const stateData = getData(7);
    if (stateData?.locations_id) cityLists();
  }, [dynamicInputs?.[4], dynamicInputs?.[5], dynamicInputs?.[6], editInfo]);

  const getData = useCallback(
    (id) => dynamicInputs?.find((input) => input?.id === id),
    [dynamicInputs]
  );

  const handleBrandChange = ({ id, value, locations_id = null }) => {
    setDynamicError("");
    setLocationError("");
    if (id === 9 && value?.length > 6) {
      return;
    }

    if (id === 1 && value) {
      value = textOnly(value);
    }

    const res = dynamicInputs?.map((input) => {
      if (id === input?.id)
        if (locations_id) return { ...input, value, locations_id };
        else return { ...input, value };
      return input;
    });
    setDynamicInputs(res);
    // setProceedToCheckEnable(false);
  };

  const buttonEnable = useCallback(() => {
    return dynamicInputs.every((input) => {
      const isValid =
        input?.value?.trim() !== "" &&
        input?.value !== undefined &&
        input?.value !== null &&
        !dynamicError;

      if (input?.label === "Brand Name") {
        return (
          isValid &&
          !input?.value.endsWith("'") &&
          !input?.value.endsWith("-") &&
          !input?.value.endsWith(".")
        );
      }

      if (input?.id === 4) {
        return validateURL(input?.value);
      }

      if (input?.id === 9) {
        return isValid && input.value?.length === 6;
      }

      return isValid && !locationError;
    });
  }, [dynamicInputs, dynamicError, locationError]);

  const buttonClass = () => {
    if (editInfo && buttonEnable()) return "button-primary";
    if (!buttonEnable())
      return " border border-[var(--border-input)] bg-[var(--BG-50)] text-[var(--disable-color)] b-radius-6 font15px-500 text-[var(--font-600)] cursor-not-allowed";
    return "border border-[var(--border-input)]  b-radius-6 font15px-500 text-[var(--font-600)]";
  };
  // Credits Report

  const creditReport = () => {
    if (id) {
      const payLoad = {
        start_date: startDate,
        end_date: endDate,
        limit: 50,
      };
      if (activeNav === "Credits") {
        brandCreditsReportApi(id, payLoad).then((res) => {
          setCreditReportData(res?.data);
        });
      }
    }
  };

  const handleFilterChange = useCallback(
    (filter) => {
      let newStartDate, newEndDate;

      newEndDate = referenceEndDate.format("YYYY-MM-DD");

      switch (filter) {
        case "Last 7 days":
          newStartDate = referenceEndDate
            .clone()
            .subtract(6, "days")
            .format("YYYY-MM-DD");
          break;
        case "Last 14 days":
          newStartDate = referenceEndDate
            .clone()
            .subtract(13, "days")
            .format("YYYY-MM-DD");
          break;
        case "Last 28 days":
          newStartDate = referenceEndDate
            .clone()
            .subtract(27, "days")
            .format("YYYY-MM-DD");
          break;
        default:
          newStartDate = referenceEndDate
            .clone()
            .subtract(30, "days")
            .format("YYYY-MM-DD");
      }

      setStartDate(newStartDate);
      setEndDate(newEndDate);
    },
    [filter, customEndDate]
  );

  useEffect(() => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  }, [initialStartDate, initialEndDate]);

  const handleCreditClick = (item) => {
    if (parseFloat(creditValue || 0) >= 100000) {
      return;
    }

    const updatedValue =
      parseFloat(creditValue || 0) + parseFloat(minimumValue[item?.label] || 0);

    if (updatedValue > 100000) {
      setCreditValue(100000);
    } else {
      setCreditValue(updatedValue);
    }
  };

  const handleCreditValue = (e) => {
    let val = numberOnly(e.target.value, true);

    const parts = val.split(".");
    if (parts.length > 1 && parts[1].length > 2) {
      val = parts[0] + "." + parts[1].slice(0, 2);
    }

    if (val < 0 || val > 100000) {
      return;
    }

    setCreditValue(val);
  };

  const handleNavigate = (item) => {
    setActiveNav(item?.name);
  };

  const handleCreditSend = () => {
    setCreateLoader(true);
    setProceed(true);
    const payLoad = {
      brand_id: id,
      amount: parseFloat(creditValue),
      transaction_type: "CREDITS",
    };

    creditsSendApi(payLoad).then((res) => {
      if (res?.status === 200) {
        setCreateLoader(false);
        // setProceed(false);
        setCreditsSend(true);
        // setAddCredits(false);
        setCreditsResponse(res?.data?.credit_item);
      } else {
        setCreditsSend(false);
        setCreateLoader(false);
        setLocationError(res?.response?.data?.error);
      }
    });
  };

  const handleBackPlan = () => {
    const payLoad = {
      brand_id: id,
      transaction_item_id: creditsResponse?.id,
    };

    creditsCancelApi(payLoad).then((res) => {
      if (res?.status === 200) {
        setIsPlanSelected(false);
      }
    });
  };

  const handleCancelUpgradePlans = () => {
    const payLoad = {
      brand_id: id,
      transaction_item_id: creditsResponse?.id,
    };
    if (isPlanSelected) {
      creditsCancelApi(payLoad).then((res) => {
        if (res?.status === 200) {
          setIsUpgrade(false);
          setIsPlanSelected(false);
        }
      });
    } else {
      setIsUpgrade(false);
      setIsPlanSelected(false);
    }
  };

  const handleSelectPlans = (p) => {
    setCreateLoader(true);

    const payLoad = {
      brand_id: id,
      transaction_type: "SUBSCRIPTION",
      billing_plan_id: 2,
      billing_cycle: selectedPrice?.toLowerCase(),
    };

    creditsSendApi(payLoad).then((res) => {
      if (res?.status === 200) {
        setCreateLoader(false);
        setIsPlanSelected(true);
        setSelectedPlan(p);
        setCreditsResponse(res?.data?.credit_item);
      } else {
        setIsPlanSelected(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error,
              status: "Error",
              duration: "",
            },
          ])
        );
        setCreateLoader(false);
      }
    });
  };

  const handleProceedPlans = (p) => {
    setProceed(true);

    const payLoad = {
      brand_id: id,
      transaction_type: "SUBSCRIPTION",
      billing_plan_id: 2,
      billing_cycle: brandsData?.billing_cycle,
    };

    creditsSendApi(payLoad).then((res) => {
      if (res?.status === 200) {
        // setProceed(false);

        // setIsPlanSelected(true);
        setSelectedPlan(p);
        setCreditsResponse(res?.data?.credit_item);
      } else {
        setIsPlanSelected(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error,
              status: "Error",
              duration: "",
            },
          ])
        );
        setCreateLoader(false);
      }
    });
  };

  const handlePayment = () => {
    setCreateLoader(true);

    const payLoad = {
      brand_id: id,
      transaction_item_id: creditsResponse?.id,
      type: isPlanSelected ? "SUBSCRIPTIONS" : "CREDITS",
    };

    transactionsApi(payLoad).then((res) => {
      if (res?.status === 200) {
        setCreateLoader(false);
        setProceed(false);
        setAddCredits(false);
        const key = "rzp_test_s49RKylg4aRLXf";
        const options = {
          key: key,
          amount: res?.data?.transaction?.total_cost * 100,
          currency: res?.data?.transaction?.currency,
          order_id: res?.data?.transaction?.razerpay_order_id,
          notes: {
            transaction_id: creditsResponse?.id,
          },
          handler: (response) => {
            transactionsResponseApi(response).then((res) => {
              if (res?.status === 200) {
                setCreateLoader(false);
                setIsApiSuccess(true);
                setAddCredits(false);
                setCreditValue(null);
                dispatch(
                  updateToggleToast([
                    ...toggleToast,
                    {
                      id: toggleToast?.length + 1,
                      content: "Payment Success",
                      status: "Success",
                      duration: "",
                    },
                  ])
                );
                setCreditsSend(false);
                setIsUpgrade(false);
                setIsPlanSelected(false);
                // brandsInfoApi(id);
              }
            });
          },
        };
        const razorpayInstance = new Razorpay(options);
        razorpayInstance.open();
      } else {
        setLocationError(res?.response?.data?.error);
      }
    });
  };

  const fetchPaymentHistory = () => {
    setListLoading(true);

    if (id) {
      const payLoad = {
        year: selectedYear,
        type: activeNav !== "Credits" ? "SUBSCRIPTION" : "CREDITS",
        limit: 50,
      };

      if (activeNav === "Credits" || activeNav === "Subscriptions") {
        brandPaymentApi(id, payLoad).then((res) => {
          if (res?.status === 200) {
            setListLoading(false);
            setPaymentHistoryData(res?.data?.bbti);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (activeNav === "Credits") {
      creditReport(id);
      fetchPaymentHistory(id);
    } else {
      fetchPaymentHistory(id);
    }
  }, [id, activeNav, selectedYear, startDate, endDate]);

  // setup status

  useEffect(() => {
    if (activeNav === "Overview") {
      setupApi(id).then((res) => {
        if (res?.status === 200) {
          setSetupData(res?.data);
        }
      });
    }
  }, [id, activeNav]);

  const date = dateConverter(brandsData?.last_active_at);
  const next_billing = dateConverter(brandsData?.next_billing_date);

  const inputContainer = "w-full py-2 gap-2 b-radius-6 mt-5";
  const input =
    "w-full text-[20vw] font14px-500 text-[var(--textBlack)] rounded-lg  py-2";

  const utcDate = new Date(brandsData?.end_date);

  const currentDate = new Date();
  const differenceInMs = utcDate.getTime() - currentDate.getTime();
  const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

  return (
    <div className="w-100 gap-3 flex-column space-between pl-20 pr-20 ptb-10">
      <div className="ptb-20 flex-row align-center gap-2">
        <span
          className="font14px-400 text-[var(--font-400)] pointer hover:text-[#6940F2] hover:underline"
          onClick={() => navigate("/profile/account/overview")}
        >
          Account Overview
        </span>
        <img
          src={ICONS?.chevronRightIcon}
          alt="arrowLeftCampaign"
          className="pointer"
        />
        <p className="font16px-600 text-[var(--font-600)]">Brand Info</p>
      </div>

      <div className="w-100 gap-3 flex-row space-between">
        <div className="bg-white w-50 b-radius flex-column p-20">
          <div className="w-100 flex-row space-between">
            <div className="flex-row">
              {brandsData?.logo_photo ? (
                <img
                  src={brandsData?.logo_photo}
                  alt="ICONSS?.starterPlanIcon"
                  className="uploadStep-image2"
                />
              ) : (
                <img
                  src={ICONSS?.brandLogo}
                  alt="ICONSS?.starterPlanIcon"
                  className="w-15"
                />
              )}
              <div>
                <div className="flex-column pl-15">
                  {brandsData?.name?.length > 40 ? (
                    <Tooltip title={brandsData?.name} placement="top">
                      <span className="font20px-600 text-[var(--textBlack)] truncate max-w-[20ch] capitalize">
                        {brandsData?.name}
                      </span>
                    </Tooltip>
                  ) : (
                    <span className="font20px-600 text-[var(--textBlack)] truncate max-w-[40ch] capitalize">
                      {brandsData?.name}
                    </span>
                  )}
                </div>

                <div className="flex-row align-center gap-3 pl-10">
                  <div
                    className={`${
                      brandsData?.last_active_at ? "border-r-[1px]" : ""
                    }`}
                  >
                    <p className="bg-[#EDEFF1] flex-row align-center gap-2 font13px-500 text-[#566F8F] px-2.5 py-1 rounded-[4px] mr-10">
                      <AccountOverview
                        color={"#566F8F"}
                        lineColor={"#566F8F"}
                        fillColor={"#566F8F"}
                      />
                      {brandsData?.category}
                    </p>
                  </div>
                  {brandsData?.last_active_at && (
                    <span className="text-[#6564CA] font14px-500 italic">
                      (Last login:
                      {date?.monthInShort} {date?.date}, {date?.year})
                    </span>
                  )}
                </div>
              </div>
            </div>
            {(currentAccount?.role === "Account Owner" ||
              currentAccount?.role === "Account Manager") && (
              <>
                {differenceInDays < 3 &&
                  brandsData?.current_plan !== "Starter Plan" && (
                    <button
                      className="button-primary py-0 px-3 h-8"
                      onClick={() => handleProceedPlans()}
                    >
                      Pay Now
                    </button>
                  )}
              </>
            )}
          </div>

          <div className="mt-[15px]">
            <div className="w-100 flex-row align-center space-between pt-20">
              <div className=" flex-row align-center">
                <img
                  src={icons[brandsData?.current_plan]}
                  alt="icons"
                  className="w-12 mt-0.5"
                />
                <div className="flex-column">
                  <div className="flex-row align-center">
                    <span className="font22px-600 text-[var(--textBlack)] pl-15">
                      {brandsData?.current_plan}
                    </span>
                    <span className=" font13px-600 text-[var(--font-600)] b-radius-6 ml-10 capitalize">
                      (
                      {brandsData?.billing_cycle === "default"
                        ? "Free"
                        : brandsData?.billing_cycle}
                      )
                    </span>
                  </div>

                  <div className="  text-center pl-15">
                    <StatusLists
                      status={brandsData?.status}
                      style={{
                        fontWeight: 500,
                        fontSize: "0.8rem",
                      }}
                    />
                  </div>
                </div>
              </div>
              {brandsData?.current_plan !== "Starter Plan" && (
                <div className="flex-column">
                  <span className="font14px-400 text-[var(--font-600)] capitalize">
                    Next Billing Date
                  </span>
                  <span className="font18px-600 text-[var(--textBlack)]">
                    {next_billing?.monthInShort} {next_billing?.date},{" "}
                    {next_billing?.year}
                  </span>
                  {differenceInDays < 3 && (
                    <div className="flex-row align-center gap-2 pt-5">
                      <TimeCircle color={"#FF543E"} />
                      <span className="font14px-400 text-[#FF543E]">
                        {differenceInDays < 3
                          ? `${differenceInDays} Days left`
                          : ""}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-white w-50 b-radius p-20">
          <div className="w-100 flex-row align-center space-between ">
            <div className="flex-row align-center gap-2">
              <span className="font16px-600 text-[var(--textBlack)]">
                Cost Per Conversation
              </span>

              <Tooltip
                title={
                  "Amount charged for each conversation initiated through the platform, based on your subscribed plan."
                }
              >
                <img
                  src={ICONS?.conversationAction}
                  alt="performanceIcon"
                  className="pointer"
                />
              </Tooltip>
            </div>
            <div>
              <Popover
                trigger="click"
                arrow={false}
                open={costPopover}
                onOpenChange={(open) => setCostPopover(open)}
                content={
                  <div className="prl-10 py-0.5 w-[12vw]">
                    {filters?.map((item) => {
                      return (
                        <div
                          onClick={() => {
                            setFilterValue(item?.value);
                            setCostPopover(false);
                          }}
                          key={item?.id}
                          className="border-b-[1px] last:border-b-0"
                        >
                          <span
                            className={`flex-column pl-10 ptb-10 mt-10 mb-10  pointer   b-radius-6 ${
                              filterValue === item?.value
                                ? "bg-[var(--new-primary-bg)] text-[var(--new-primary)] hover:bg-none "
                                : "hover:bg-[var(--BG-25)] text-[var(--textBlack)]"
                            }`}
                          >
                            {item?.value}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                }
              >
                <div className="w-[14vw] font15px-400 text-[var(--textBlack)] border border-[var(--border-input)] flex-row align-center space-between pl-10 pr-10 py-1.5 b-radius-6 pointer">
                  {filterValue ||
                    brandsData?.current_plan?.replace("Plan", "") +
                      "(Current Plan)"}
                  <img
                    src={ICONS?.dropDownIcon}
                    alt="dropDownIcon"
                    className="w-4"
                  />
                </div>
              </Popover>
            </div>
          </div>
          <div className="w-100 flex-row space-between">
            {conversationPricing?.map((item) => {
              return (
                <div key={item?.id} className="flex-row pt-20">
                  <div className="flex-column ">
                    <span className="font15px-400 text-[var(--textBlack)]">
                      {item?.title}
                    </span>
                    <span className="font16px-600 text-[var(--textBlack)] w-[8vw] pt-5">
                      {filterValue === "Starter"
                        ? item?.msg
                        : filterValue === "Growth"
                        ? item?.msg1
                        : item?.msg2}
                    </span>
                  </div>
                  {item?.id !== 4 && (
                    <div class="border-r-[1px] border-[var(--font-400)] h-[2vh] mt-10" />
                  )}
                </div>
              );
            })}
          </div>
          <div className="w-100 flex-row align-center space-between pt-20">
            <a href="https://gainwix.ai/pricing" target="blank">
              <span className="font16px-400 text-[#6564CA] border-b-2 border-[#6564CA] pointer">
                Check out the pricing page!
              </span>
            </a>
            {filterValue &&
              `${filterValue} Plan` !== brandsData?.current_plan && (
                <button
                  className="w-[14vw] font15px-500 text-white px-4 py-1.5 b-radius-6"
                  style={{
                    background:
                      "linear-gradient(96.34deg, #FBBF24 0%, #6940F2 100%)",
                  }}
                  onClick={() => setIsUpgrade(true)}
                >
                  Upgrade
                </button>
              )}
          </div>
        </div>
      </div>
      <div className="w-100 pb-20 h-[63vh] ">
        <div className="flex-row align-center">
          <span className="font16px-600 text-[var(--textBlack)] gap-2">
            Brand Information
          </span>
        </div>
        <div className="w-100 h-full flex-row mt-10 gap-4">
          <div className="w-15 bg-white b-radius p-10 ">
            {brandAccountOverview?.map((item) => {
              return (
                <div
                  className={`w-100 b-radius-6 px-[1vw] py-[0.4vw]  flex-row align-center cursor-pointer gap-2.5 mt-10 ${
                    activeNav === item?.name
                      ? "sidebar-sub-header-active ptb-10"
                      : "hover:bg-slate-100 sidebar-sub-header hover:text-[var(--contentBlack)] hover:weight-medium mr-10 ptb-10"
                  }`}
                  onMouseEnter={() => setHoveredNav(item?.name)}
                  onMouseLeave={() => setHoveredNav(null)}
                  onClick={() => handleNavigate(item)}
                >
                  <item.icon
                    color={
                      activeNav === item?.name
                        ? "var(--new-primary)"
                        : hoveredNav === item?.name
                        ? "var(--textBlack)"
                        : "#616874"
                    }
                    fillColors={
                      activeNav === item?.name
                        ? "var(--new-primary)"
                        : hoveredNav === item?.name
                        ? "none"
                        : "none"
                    }
                    stroke={
                      activeNav === item?.name
                        ? "white"
                        : hoveredNav === item?.name
                        ? "var(--textBlack)"
                        : "var(--font-600)"
                    }
                  />
                  <span>{item?.name}</span>
                </div>
              );
            })}
          </div>
          <div
            className={`w-100  ${
              activeNav === "Users" || activeNav === "Subscriptions"
                ? ""
                : "h-[61vh] overflow-scroll"
            }`}
          >
            {activeNav === "Overview" && (
              <Overview
                setIsUpgrade={setIsUpgrade}
                setAddCredits={setAddCredits}
                brandsData={brandsData}
                dynamicInputs={dynamicInputs}
                setDynamicInputs={setDynamicInputs}
                setEditInfo={setEditInfo}
                editInfo={editInfo}
                handleUpdate={handleUpdate}
                countryListss={countryListss}
                setCountryListss={setCountryListss}
                stateList={stateList}
                setStateList={setStateList}
                setCityList={setCityList}
                cityList={cityList}
                handleBrandChange={handleBrandChange}
                buttonClass={buttonClass}
                dynamicError={dynamicError}
                setupData={setupData}
              />
            )}
            {activeNav === "Credits" && (
              <Credits
                setAddCredits={setAddCredits}
                addCredits={addCredits}
                brandsData={brandsData}
                handleFilterChange={handleFilterChange}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                customStartDate={customStartDate}
                customEndDate={customEndDate}
                startDate={startDate}
                endDate={endDate}
                creditReportData={creditReportData}
                paymentHistoryData={paymentHistoryData}
                setYearFilterOpen={setYearFilterOpen}
                yearFilterOpen={yearFilterOpen}
                setSelectedYear={setSelectedYear}
                selectedYear={selectedYear}
                years={years}
                filterValue={filterValue}
                listLoading={listLoading}
              />
            )}
            {activeNav === "Users" && (
              <AccountUsersTable
                activeNav={activeNav}
                setBrandPopover={setBrandPopover}
              />
            )}
            {activeNav === "Subscriptions" && (
              <Subscription
                setIsUpgrade={setIsUpgrade}
                brandsData={brandsData}
                isUpgrade={isUpgrade}
                paymentHistoryData={paymentHistoryData}
                setYearFilterOpen={setYearFilterOpen}
                yearFilterOpen={yearFilterOpen}
                setSelectedYear={setSelectedYear}
                selectedYear={selectedYear}
                years={years}
                activeNav={activeNav}
                setProceed={setProceed}
                handleProceedPlans={handleProceedPlans}
                listLoading={listLoading}
              />
            )}
            {activeNav === "Invoices" && (
              <Invoice
                activeNav={activeNav}
                setYearFilterOpen={setYearFilterOpen}
                yearFilterOpen={yearFilterOpen}
                setSelectedYear={setSelectedYear}
                selectedYear={selectedYear}
                years={years}
              />
            )}
          </div>
        </div>
        <Modal
          open={addCredits}
          footer={null}
          closable={false}
          centered={true}
          wrapClassName={"bg-[#00000095]"}
          width={"45%"}
        >
          <div className="flex-row align-center space-between  p-20">
            <div className="flex-row align-center gap-2">
              <img
                src={ICONSS?.conversationCredits}
                alt="conversationCredits"
              />
              <span className="font22px-600 text-[var(--textBlack)]">
                WhatsApp Conversation Credits
              </span>
            </div>
            <img
              className="popup-exit  mr-10 pointer"
              src={ICONS.popupX}
              alt="popup"
              onClick={() => {
                setCreditValue(""), setAddCredits(false), setLocationError("");
              }}
            />
          </div>

          <div className="pl-20 pb-20">
            <span className="font15px-500 text-[var(--textBlack)]">
              Hey, do you send out broadcasts to your contacts? You should add
              some more credits!
            </span>
            <div className="pr-20 ptb-20 ">
              <div className="flex-row align-center border border-[#E8E8EA] p-5 b-radius-6  ">
                <p className="flex-row border-r-[1px] pl-10 pr-[12px] font14px-600 text-[var(--font-600)]">
                  INR
                  <img
                    src={ICONS?.dropDownIcon}
                    alt="dropDownIcon"
                    className="w-4 pl-5"
                  />
                </p>
                <div className="w-100 pl-10 font14px-500 text-[var(--textBlack)]">
                  <input
                    // type="number"
                    onChange={handleCreditValue}
                    value={creditValue}
                    placeholder="Minimum ₹1,000.00 credits required."
                    className="w-100"
                  />
                </div>
              </div>
              {locationError && (
                <div className="flex-row align-center pt-5">
                  <img src={ICONS?.alertIconError} alt="" />
                  <span className="capitalize error-message pl-5">
                    {locationError}
                  </span>
                </div>
              )}
              <div className="w-80 flex-row align-center mt-20 pb-20 space-between">
                {credits?.map((item) => {
                  return (
                    <span
                      className=" gap-2 bg-[#EDEFF1] py-1 px-5 b-radius-6 pointer"
                      key={item?.id}
                      onClick={() => handleCreditClick(item)}
                    >
                      {item?.label}
                    </span>
                  );
                })}
              </div>
              <div className="w-100 flex-row justify-end">
                {creditValue > 999 ? (
                  <button
                    className="button-primary pointer  text-[var(--font-600)] border border-[#E8E8EA] px-10 py-1 b-radius-6"
                    onClick={handleCreditSend}
                    // disabled={isLoading}
                  >
                    {createLoader ? (
                      <div
                        className="flex-row align-center justify-center"
                        style={{ width: "70px", height: "18px" }}
                      >
                        <Loader Width={20} Height={20} loaderBg="white" />
                      </div>
                    ) : (
                      "Buy Now"
                    )}
                  </button>
                ) : (
                  <button className="text-[var(--font-600)] border border-[#E8E8EA] px-10 py-1 b-radius-6 cursor-not-allowed">
                    Buy Now
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal>
        {/* <Modal
          open={creditsSend}
          footer={null}
          closable={false}
          centered={true}
          wrapClassName={"bg-[#00000095]"}
          width={"45%"}
        >
          <Header
            title={"Payment Confirmation"}
            titleStyle="font24px-500 text-[var(--textBlack)] pl-10"
            subTitleStyle="text-sm"
            titleSize="none"
            subTitleSize="none"
            handleCancel={() => {
              setCreditsSend(false);
            }}
            prefix={ICONS?.leftArrow}
            iconClick={() => setCreditsSend(false)}
            prefixStyle="pointer"
          />
          <div className="p-20">
            <span className="font16px-600 text-[var(--textBlack)]">
              Billing Summary:
            </span>
            <div className="w-100 flex-row align-center space-between p-5 mt-10 mb-10">
              <span className="font16px-500 text-[var(--textBlack)]">
                Brand Name
              </span>
              <span>Details</span>
              <span>Amount</span>
            </div>
            <div className="w-100 bg-[#FAFCFF] border border-[#DEDEDE] flex-column  p-15 b-radius">
              <div className="flex-row align-center space-between mb-20 border-b-[1px]">
                <span className="font16px-600 text-[var(--textBlack)] mb-5 capitalize truncate max-w-[15ch]">
                  {brandsData?.name}
                </span>
                <div className="flex-column text-center font14px-400 text-[var(--textBlack)] gap-y-4 mb-20">
                  <div className="flex-row align-center justify-center text-center ">
                    <span className=" ">
                      {brandsData?.current_plan?.replace("Plan", "")}
                    </span>
                    <span className="font14px-400 text-[var(--font-400)] b-radius-6 capitalize pl-5 ">
                      ({brandsData?.setting?.billing_invoice_cadence})
                    </span>
                  </div>
                  <span className="">Conversation Credit Cost</span>
                </div>
                <div className="flex-column mb-5">
                  <span className="font16px-600 text-[var(--textBlack)]">
                    ₹{creditsResponse?.amount}
                  </span>
                </div>
              </div>
              <div className="w-100 flex-row align-center space-between  ">
                <div className="w-20 invisible">hii</div>
                <div className=" flex-column justify-center text-center gap-y-4">
                  <span className="font14px-400 text-[var(--font-400)]">
                    Bill Amount
                  </span>
                  <span className="font14px-400 text-[var(--font-400)]">
                    GST (18%)
                  </span>
                  <span className="font16px-600 text-[var(--textBlack)]">
                    Sub Total
                  </span>
                </div>
                <div className=" flex-column  gap-y-4">
                  <span className="font14px-400 text-[var(--font-400)]">
                    ₹{creditsResponse?.amount}
                  </span>
                  <span className="font14px-400 text-[var(--font-400)]">
                    ₹{creditsResponse?.tax_amount}
                  </span>
                  <span className="font16px-600 text-[var(--textBlack)]">
                    {creditsResponse?.amount}
                  </span>
                </div>
              </div>
            </div>

            <div className="w-100 flex-column space-between border-t-[1px] border-[#D7D7D7] bg-[#FFFFFF] mt-20">
              <div className="flex-row">
                <div className="w-20 invisible">hii</div>

                <span className="mt-20 font16px-600 text-[var(--textBlack)] text-center w-80">
                  Total Amount
                </span>
                <span className=" font20px-600 text-[var(--textBlack)] mt-20">
                  ₹{creditsResponse?.total_cost}
                </span>
              </div>

              <div className="flex-row space-between text-end mt-20">
                <div className="w-20 invisible">hii</div>

                <span
                  onClick={handlePayment}
                  className="pointer button-primary px-8 py-1.5 mt-5"
                >
                  {createLoader ? (
                    <div
                      className="flex-row align-center justify-center"
                      style={{ width: "70px", height: "18px" }}
                    >
                      <Loader Width={20} Height={20} loaderBg="white" />
                    </div>
                  ) : (
                    "Proceed to Pay"
                  )}
                </span>
              </div>
            </div>
          </div>
        </Modal> */}
        <Modal
          open={isUpgrade}
          afterOpenChange={(open) => {
            setIsUpgrade(open);
          }}
          centered
          footer={null}
          closable={false}
          mask={true}
          wrapClassName={"bg-[#00000095]"}
          destroyOnClose
          width={"75vw"}
          style={{
            width: "100%",
            borderRadius: 20,
            boxShadow: "0px 10px 24px 0px rgba(79, 92, 128, 0.15)",
            overflow: "hidden",
          }}
        >
          <Header
            title={isPlanSelected ? "Proceed to Checkout" : "Select Plans"}
            prefix={
              isPlanSelected ? (
                <div
                  className="rotate-180 size-9 flex items-center justify-center border border-[var(--border-100)] rounded-lg cursor-pointer"
                  onClick={() => {
                    handleBackPlan();
                  }}
                >
                  <FullArrow width={14} height={14} />
                </div>
              ) : null
            }
            prefixStyle="mr-4"
            titleSize="largest"
            handleCancel={handleCancelUpgradePlans}
          />
          <div className="bg-[#FFFFFF] p-[2vw]">
            {isPlanSelected ? (
              <div className="w-full h-[62vh] flex gap-5 text-[var(--contentText)]">
                <div className="w-3/5 h-auto max-h-[10vh] flex-column  border border-[#E8E8EA] p-20 b-radius">
                  <div className="flex-row">
                    <div className="flex-column pb-7 w-100">
                      <h4 className="font15px-400 text-[var(--font-600)] ">
                        Your selected plan is
                      </h4>
                      <span className=" font16px-500 text-[var(--textBlack)] mt-5">
                        {selectedPlan?.title}({selectedPrice})
                      </span>
                    </div>
                    <p className="weight-bold justify-end text-end w-80">
                      ₹{creditsResponse?.amount}
                    </p>
                  </div>
                  <div className="w-100 bg-white b-radius mt-5 pt-10">
                    <span className="font16px-600 text-[var(--textBlack)]">
                      Brand Information
                    </span>

                    <div className="grid grid-cols-2 gap-3 mt-15">
                      {dynamicInputs?.map((item) => {
                        return (
                          <div className="mb-10" key={item?.id}>
                            <span className="font14px-500 text-[var(--font-600)]">
                              {item?.label}{" "}
                              <span className="text-[red]">*</span>
                            </span>
                            {editInfo ? (
                              <div className="w-full flex items-center mt-5 pl-10 gap-2.5 border border-[var(--border-input)] rounded-lg">
                                {item?.id !== 6 &&
                                  item?.id !== 7 &&
                                  item?.id !== 8 &&
                                  item?.id !== 2 && (
                                    <input
                                      type={item?.id === 9 ? "number" : "text"}
                                      className={cn(input)}
                                      name="name"
                                      value={item?.value || ""}
                                      placeholder="E.g. Spring sale offer"
                                      onChange={(e) =>
                                        handleBrandChange({
                                          id: item?.id,
                                          value: e.target.value,
                                        })
                                      }
                                      autoComplete="off"
                                      maxLength={item?.id === 9 ? 6 : 64}
                                    />
                                  )}
                                {(item?.id === 6 ||
                                  item?.id === 7 ||
                                  item?.id === 8 ||
                                  item?.id === 2) && (
                                  <Select
                                    value={item?.value || ""}
                                    options={
                                      item?.id === 6
                                        ? countryListss || []
                                        : item?.id === 7
                                        ? stateList || []
                                        : item?.id === 8
                                        ? cityList || []
                                        : category || []
                                    }
                                    onChange={(value) => {
                                      handleBrandChange({
                                        id: item?.id,
                                        value: value?.label,
                                        locations_id: value?.id,
                                      });
                                    }}
                                    rootClassName={` border-none w-100`}
                                    placeholder={"--Select option--"}
                                    position="bottom"
                                    optionClassName="p-20 border-b last:border-none"
                                    labelClassName="!font14px-500 !text-[var(--textBlack)]"
                                  />
                                )}
                              </div>
                            ) : (
                              <>
                                {item?.value?.length > 40 ? (
                                  <Tooltip title={item?.value} placement="top">
                                    <div
                                      className={cn(
                                        inputContainer,
                                        "bg-[var(--BG-25)] pl-10 font14px-500 text-[var(--textBlack)]"
                                      )}
                                    >
                                      <p className="max-w-[30ch] truncate">
                                        {item?.value}
                                      </p>
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <div
                                    className={cn(
                                      inputContainer,
                                      "bg-[var(--BG-25)] pl-10  font14px-500 text-[var(--textBlack)]"
                                    )}
                                  >
                                    {item?.value || "--"}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {(currentAccount?.role === "Account Owner" ||
                      currentAccount?.role === "Account Manager") && (
                      <div className="w-100 justify-end flex-row mt-20">
                        <button
                          className={`px-2 py-1.5 mb-20 ${buttonClass()}`}
                          onClick={() => {
                            if (editInfo) {
                              handleUpdate();
                            } else {
                              setEditInfo(true);
                            }
                          }}
                        >
                          Edit Brand Info
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-2/5 h-100 flex-column space-between bg-[#FBFBFB] px-7 pb-7 pt-2.5 rounded-2xl">
                  <div>
                    <h6 className="text-base weight-bold">Bill Summary:</h6>
                    <div className="flex flex-col gap-7 pt-7 pb-9">
                      <div className="min-h-9 h-auto flex flex-col gap-1.5">
                        <div className="flex items-center justify-between gap-2.5 text-base">
                          <h5 className="weight-semibold">Growth Plan</h5>
                          <p className="weight-bold">
                            ₹{creditsResponse?.amount}
                          </p>
                        </div>
                        <p className="text-sm weight-medium text-[var(--font-400)]">
                          Quarterly
                        </p>
                      </div>

                      {/* <div className="min-h-9 h-auto">
                      {isApplyCoupon ? (
                        <div className="w-full">
                          <div className="flex items-center justify-between gap-2.5">
                            <p className="text-sm weight-semibold text-[var(--font-600)]">
                              Add coupon
                            </p>
                            <img
                              src={ICONS?.cancelX}
                              alt="cancel icons"
                              className="size-5 rounded-md cursor-pointer hover:bg-[var(--BG-50)]"
                              onClick={() => {
                                setIsApplyCoupon(false);
                              }}
                            />
                          </div>
                          <div
                            className={cn(
                              "w-full h-9 bg-white border-[1.5px] rounded-md flex items-center justify-between gap-2.5 px-2.5 mt-2.5",
                              couponCodeRes === "success"
                                ? "border-[var(--BrandLink)]"
                                : couponCodeRes === "error"
                                ? "border-[var(--fontRed)]"
                                : "border-[#E8E8EA]"
                            )}
                          >
                            <input
                              type="text"
                              name="coupon"
                              id="coupon"
                              value={couponCode}
                              placeholder="Enter coupon code"
                              className="w-full "
                              onChange={(e) => {
                                setCouponCode(e.target.value);
                              }}
                            />
                            <div className="flex items-center justify-center">
                              {couponCodeRes === "success" ? (
                                <div>s</div>
                              ) : couponCodeRes === "error" ? (
                                <div>e</div>
                              ) : (
                                <button
                                  className={cn(
                                    "text-[var(--BrandLink)] texty-sm weight-semibold",
                                    couponCode
                                      ? "cursor-pointer"
                                      : "cursor-not-allowed"
                                  )}
                                  onClick={() => {
                                    if (couponCode) {
                                    }
                                  }}
                                >
                                  Apply
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="w-fit text-sm weight-semibold text-[var(--BrandLink)] cursor-pointer flex items-center px-2.5 py-1.5 rounded-lg hover:bg-[#ECE8FA]"
                          onClick={() => {
                            setIsApplyCoupon(true);
                          }}
                        >
                          <p>Add a coupon code</p>
                        </div>
                      )}
                    </div>
                    <div className="min-h-9 h-auto">
                      <div className="flex items-center justify-between gap-2.5 text-base">
                        <h5 className="text-base weight-medium">
                          Discounts & Offers
                        </h5>
                        <p className="weight-bold">$ 0.00</p>
                      </div>
                    </div> */}
                    </div>
                    <div className="min-h-9 h-auto flex flex-col gap-1.5">
                      <div className="min-h-9 h-auto flex flex-col gap-1.5 border-b-2 border-[var(--border-100)]">
                        {/* <div className="flex items-center justify-between gap-2.5 text-[var(--textBlack)] ">
                        <span className="font16px-400">Sub Total</span>
                        <p className="font16px-600">
                          ₹{creditsResponse?.total_cost}
                        </p>
                      </div> */}
                        <div className="flex items-center justify-between gap-2.5 text-[var(--textBlack)] pt-10 pb-20">
                          <span className="font16px-400 ">GST(18%)</span>
                          <p className="font16px-600">
                            ₹{creditsResponse?.tax_amount}
                          </p>
                        </div>
                      </div>
                      <div className="min-h-9 h-auto flex flex-col gap-1.5 pt-20">
                        <div className="flex items-center justify-between gap-2.5 text-[var(--textBlack)]">
                          <span className="font16px-600">Total</span>
                          <p className="font20px-600">
                            ₹{creditsResponse?.total_cost}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className={`w-full h-12 rounded-md ${
                      buttonEnable()
                        ? "bg-[var(--new-primary)] text-white"
                        : "bg-[var(--disable-bg)] text-[var(--disable-color)] cursor-not-allowed"
                    }  text-base weight-bold mt-9`}
                    onClick={() => buttonEnable() && handlePayment()}
                  >
                    Proceed to Checkout
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="flex flex-col items-center justify-center pb-[2vw] relative">
                  <div className="w-fit relative">
                    <div className="flex flex-col items-center">
                      <div className="w-fit flex items-center justify-center px-[1vw] py-[0.6vw] bg-[#F1F4EE] rounded-[2vw]">
                        {/* <Planscard id={1} plan={"Monthly"} /> */}
                        <Planscard id={2} plan={"Quarterly"} />
                        <Planscard id={3} plan={"Annual"} />
                      </div>
                      <p className="mt-[0.8vw] font18px-600 text-[#6940F2]">
                        🎉 Get{" "}
                        <span className="text-[#F6A723]"> 2 months free </span>{" "}
                        with annual subscription
                      </p>
                      <span className="mt-5 font14px-400 text-[var(--font-600)]">
                        **Business verification & Green tick are available
                        Quarterly / Annual plans only.
                      </span>
                    </div>
                    <div className="absolute top-[1vw] -right-[2.1vw]">
                      <img
                        src={ICONS?.PricingArrow}
                        alt="arrow"
                        className="size-[3.7vw] "
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`w-full h-fit flex  ${
                    brandsData?.current_plan === "Starter Plan"
                      ? "justify-around"
                      : "justify-center gap-7"
                  }`}
                >
                  {pricing
                    ?.filter((p, index) => {
                      if (brandsData?.current_plan === "Starter Plan") {
                        return [0, 1, 2].includes(index);
                      }
                      return [1, 2].includes(index);
                    })
                    ?.map((p) => {
                      return (
                        <div
                          key={p.id}
                          className={`min-w-[20vw] rounded-[1.5vw] p-[0.25vw] ${
                            p.popular
                              ? "shadow-[0px_4px_26px_0px_#CAC5FF] bg-[#6940F2]"
                              : "shadow-[0px_4px_16px_0px_#2D303630] bg-white"
                          }`}
                        >
                          <div className="w-auto h-full bg-[var(--white)] rounded-[1.3vw] px-[1vw] py-[1.2vw]">
                            <div className="flex items-center justify-between pb-[1vw]">
                              <img
                                src={p.icon}
                                alt={p.title}
                                className="size-[3vw]"
                              />
                              {p.popular && (
                                <div className="px-[1vw] py-[0.25vw] bg-[#EDAB4033] rounded-[2vw] flex items-center gap-[0.4vw]">
                                  <img
                                    src={ICONS?.MostPopular}
                                    alt="most popular"
                                  />
                                  <p className="font-md weight-bold text-[#EDAB40]">
                                    Most Popular
                                  </p>
                                </div>
                              )}
                            </div>
                            <h3 className="text-[1.5vw] weight-bold text-[#202421] mt-[0.2vw]">
                              {p.title}
                            </h3>
                            <div className="mt-[0.7vw]">
                              <span className="text-[2vw] weight-semibold text-[#202421]">
                                {p?.custom
                                  ? "Custom"
                                  : p?.[selectedPrice?.toLowerCase()]?.price}
                              </span>
                              {p?.id === 2 && (
                                <span className="font-normal weight-medium text-[#616874]">
                                  / month
                                </span>
                              )}
                            </div>
                            <div className="flex-column mt-5 border-b-[1px] border-[var(--border-input)]">
                              <div className="flex-row align-center gap-1 mb-10">
                                {p?.title === "Starter" ? (
                                  <img src={p?.icon2} alt="icon1" />
                                ) : (
                                  <img src={p?.icon1} alt="icon1" />
                                )}
                                <span className="font16px-400 text-[var(--font-600)]">
                                  Business verification
                                </span>
                              </div>
                              <div className="flex-row align-center gap-1 mb-20">
                                {p?.title === "Starter" ? (
                                  <img
                                    src={p?.icon1}
                                    alt="icon1"
                                    className="pr-5"
                                  />
                                ) : (
                                  <img src={p?.icon1} alt="icon2" />
                                )}
                                <span className="font16px-400 text-[var(--font-600)]">
                                  Green Tick
                                </span>
                              </div>
                            </div>
                            <p className="font-normal weight-small text-[#616874] mt-20 leading-[1.4vw]">
                              {p?.content}
                            </p>
                            <button
                              className={`w-full h-[2.5vw] flex items-center justify-center gap-[1vw] bg-[var(--new-primary)] font-normal weight-semibold text-[var(--white)] mt-[1.5vw] rounded-[0.5vw] tracking-[0.02vw] ${
                                p?.btnText === "Choose Plan"
                                  ? "cursor-pointer"
                                  : "cursor-default"
                              }`}
                              onClick={() => {
                                if (p?.btnText === "Choose Plan") {
                                  handleSelectPlans(p);
                                }
                              }}
                            >
                              <p>{p?.btnText}</p>
                              <div className="rotate-180">
                                <Arrow color="#ffffff" />
                              </div>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className="flex flex-col items-center justify-center mt-[2vw]">
                  <a
                    href="https://gainwix.ai/pricing"
                    target="_blank"
                    className="px-[0.8vw] py-[0.4vw] border-[0.08vw] border-[var(--border-50)] font-md weight-medium rounded-[0.3vw] !text-[var(--font-600)]"
                  >
                    Compare Plans
                  </a>
                  <p className="font-md weight-medium text-center py-[1vw]">
                    You'll be billed once a year for the entire year's access.
                    This is a non-refundable plan, so please be sure it meets
                    your needs before
                    <br />
                    subscribing. Taxes may apply depending on your location. For
                    more information, please go to{" "}
                    <a
                      href="https://gainwix.ai/pricing"
                      target="_blank"
                      className="!text-[#6940F2]"
                    >
                      https://gianwix.ai/pricing
                    </a>
                  </p>
                </div>
              </>
            )}
          </div>
        </Modal>
        <Modal
          open={proceed}
          afterOpenChange={(open) => {
            setProceed(open);
          }}
          centered
          footer={null}
          closable={false}
          mask={true}
          wrapClassName={"bg-[#00000095]"}
          destroyOnClose
          width={"65vw"}
          style={{
            width: "100%",
            borderRadius: 20,
            boxShadow: "0px 10px 24px 0px rgba(79, 92, 128, 0.15)",
            overflow: "hidden",
          }}
        >
          <Header
            title={isPlanSelected ? "Proceed to Checkout" : "Select Plans"}
            prefix={
              isPlanSelected ? (
                <div
                  className="rotate-180 size-9 flex items-center justify-center border border-[var(--border-100)] rounded-lg cursor-pointer"
                  onClick={() => {
                    handleBackPlan();
                  }}
                >
                  <FullArrow width={14} height={14} />
                </div>
              ) : null
            }
            prefixStyle="mr-4"
            titleSize="largest"
            handleCancel={() => setProceed(false)}
          />
          <div
            className={`w-full  flex gap-5 text-[var(--contentText)] p-20 ${
              locationError ? "h-[70vh]" : "h-[67vh]"
            }`}
          >
            <div className="w-3/4 h-auto max-h-[10vh] flex-column border border-[#E8E8EA] p-20 b-radius">
              <div className="flex-row space-between">
                <div className="flex-column pb-7">
                  <h4 className="font15px-400 text-[var(--font-600)]">
                    Your selected plan is
                  </h4>
                  <span className="font16px-500 text-[var(--textBlack)] mt-5">
                    {selectedPlan?.title}({selectedPrice})
                  </span>
                </div>
                <p className="weight-bold">₹{creditsResponse?.amount}</p>
              </div>
              <div className="w-100 bg-white b-radius mt-10">
                <span className="font16px-600 text-[var(--textBlack)]">
                  Brand Information
                </span>

                <div className="grid grid-cols-2 gap-3 mt-15">
                  {dynamicInputs?.map((item) => {
                    return (
                      <div className="mb-10" key={item?.id}>
                        <span className="font14px-500 text-[var(--font-600)]">
                          {item?.label} <span className="text-[red]">*</span>
                        </span>
                        {editInfo ? (
                          <div className="w-full flex items-center mt-5 pl-10 gap-2.5 border border-[var(--border-input)] rounded-lg">
                            {item?.id !== 6 &&
                              item?.id !== 7 &&
                              item?.id !== 8 &&
                              item?.id !== 2 && (
                                <input
                                  type={item?.id === 9 ? "number" : "text"}
                                  className={cn(input)}
                                  name="name"
                                  value={item?.value || ""}
                                  placeholder="E.g. Spring sale offer"
                                  onChange={(e) =>
                                    handleBrandChange({
                                      id: item?.id,
                                      value: e.target.value,
                                    })
                                  }
                                  autoComplete="off"
                                  maxLength={item?.id === 9 ? 6 : 64}
                                />
                              )}
                            {(item?.id === 6 ||
                              item?.id === 7 ||
                              item?.id === 8 ||
                              item?.id === 2) && (
                              <Select
                                value={item?.value || ""}
                                options={
                                  item?.id === 6
                                    ? countryListss || []
                                    : item?.id === 7
                                    ? stateList || []
                                    : item?.id === 8
                                    ? cityList || []
                                    : category || []
                                }
                                onChange={(value) => {
                                  handleBrandChange({
                                    id: item?.id,
                                    value: value?.label,
                                    locations_id: value?.id,
                                  });
                                }}
                                rootClassName={` border-none w-100`}
                                placeholder={"--Select option--"}
                                position="bottom"
                                optionClassName="p-20 border-b last:border-none"
                                labelClassName="!font14px-500 !text-[var(--textBlack)]"
                              />
                            )}
                          </div>
                        ) : (
                          <>
                            {item?.value?.length > 40 ? (
                              <Tooltip title={item?.value} placement="top">
                                <div
                                  className={cn(
                                    inputContainer,
                                    "bg-[var(--BG-25)] pl-10 font14px-500 text-[var(--textBlack)]"
                                  )}
                                >
                                  <p className="max-w-[30ch] truncate">
                                    {item?.value}
                                  </p>
                                </div>
                              </Tooltip>
                            ) : (
                              <div
                                className={cn(
                                  inputContainer,
                                  "bg-[var(--BG-25)] pl-10  font14px-500 text-[var(--textBlack)]"
                                )}
                              >
                                {item?.value || "--"}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
                {locationError && (
                  <div className="flex-row align-center">
                    <img src={ICONS?.alertIconError} alt="" />
                    <span className="capitalize error-message pl-5">
                      {locationError}
                    </span>
                  </div>
                )}
                {(currentAccount?.role === "Account Owner" ||
                  currentAccount?.role === "Account Manager") && (
                  <div className="w-100 justify-end flex-row mt-20">
                    <button
                      className={`px-2 py-1.5 mb-20 ${buttonClass()}`}
                      onClick={() => {
                        if (editInfo) {
                          handleUpdate();
                        } else {
                          setEditInfo(true);
                        }
                      }}
                    >
                      Edit Brand Info
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="w-2/4 flex-column space-between bg-[#FBFBFB] px-7 pb-7 pt-2.5 rounded-2xl">
              <div>
                <h6 className="font16px-600">Bill Summary:</h6>
                <div className="flex flex-col gap-7 pt-7 pb-9">
                  <div className="min-h-9 h-auto flex flex-col gap-1.5">
                    <div className="flex items-center justify-between gap-2.5 text-[var(--textBlack)]">
                      <h5 className="font16px-500">Growth Plan</h5>
                      <p className="font16px-600">₹{creditsResponse?.amount}</p>
                    </div>
                    <p className="font14px-400 text-[var(--font-400)]">
                      Quarterly
                    </p>
                  </div>
                </div>
                <div className="min-h-3 h-auto flex flex-col gap-1.5 border-b-2 border-[var(--border-100)">
                  {/* <div className="flex items-center justify-between gap-2.5 text-[var(--textBlack)] ">
                  <span className="font16px-400">Sub Total</span>
                  <p className="font16px-600">₹{creditsResponse?.total_cost}</p>
                </div> */}
                  <div className="flex items-center justify-between gap-2.5 text-[var(--textBlack)] pb-20">
                    <span className="font16px-400 ">GST(18%)</span>
                    <p className="font16px-600">
                      ₹{creditsResponse?.tax_amount}
                    </p>
                  </div>
                </div>
                <div className="min-h-9 h-auto flex flex-col gap-1.5 pt-20">
                  <div className="flex items-center justify-between gap-2.5 text-[var(--textBlack)]">
                    <span className="font16px-600">Total</span>
                    <p className="font20px-600">
                      ₹{creditsResponse?.total_cost}
                    </p>
                  </div>
                </div>
              </div>
              <button
                className={`w-full h-12 rounded-md ${
                  buttonEnable()
                    ? "bg-[var(--new-primary)] text-white"
                    : "bg-[var(--disable-bg)] text-[var(--disable-color)] cursor-not-allowed"
                }  text-base weight-bold mt-9`}
                onClick={() => buttonEnable() && handlePayment()}
              >
                Proceed to Checkout
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default BrandOverview;
