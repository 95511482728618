import { Popover } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { createNode, createTextNode } from '../../../api/sequence';

// assets
import IconContainer from 'assets/customSVG/sequence/actions/IconContainer';
import Tick from '../../../../../assets/customSVG/Tick';

// helpers
import { cn } from 'helper/cn';
import { isObjectEmpty } from '../../../helpers';

// constants
import {
  ALLOWED_BODY_NODE_TYPES,
  ALLOWED_BUTTONS_NODE_TYPES,
  ALLOWED_FOOTER_NODE_TYPES,
  ALLOWED_HEADER_NODE_TYPES,
  BUTTON_TYPES,
  DEFAULT_MEDIA_SIZE,
  FOOTER_TYPES,
  maxButtonCount,
  MEDIA_TYPES,
  NONE_TYPE,
  TEXT_TYPE,
} from '../../../constants/sequence';
import {
  ACTIONS_LIST,
  NODE_TYPES_CONVERTION,
} from '../../../constants/triggers';

// redux
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../../test/jest-redux-hooks';

// components
import { useParams } from 'react-router-dom';
import AddIcon from '../../../../../assets/customSVG/AddIcon';
import Xmark from '../../../../../assets/customSVG/Xmark';
import Loader from '../../../../../components/commonComponents/Loader/Index';
import useToggle from '../../../../../hooks/useToggle';
import { updateToggleToast } from '../../../../../reduxToolkit/appSlice';
import {
  isValidBody,
  isValidButton,
  isValidFooter,
  isValidHeader,
  isValidList,
  isValidTemplate,
} from '../../../helpers/sequence/node';
import Input from '../Input';
import BodyMessage from './BodyMessage';
import DefaultReplay from './DefaultReplay';
import HeaderMessage from './HeaderMessage';
import ListMessage from './list';
import QuickReplyButtons from './QuickReplyButtons';
import Tabs from './Tabs';
import TemplateMessage from './TemplateMessage';

const EditMessages = ({
  originalData,
  node,
  onTriggerClick,
  onChange,
  findEmptySpace,
  toggleNodeEditMode,
  toggleLastNode,
  allowToEditFlow,
  handleOnDeleteEdge,
  removeEdges,
  updateEdgesCoordinates,
}) => {
  const { sequence_id, campaign_id } = useParams();
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const mediaRef = useRef(null);

  const [isUpdating, setIsUpdating] = useToggle(false);
  const [isHeaderTypeChange, setIsHeaderTypeChange] = useToggle(false);
  const [headerList, setHeaderList] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [removedEdges, setRemovedEdges] = useState([]);
  const [footerTab, setFooterTab] = useState(FOOTER_TYPES?.[0]);
  const [buttonTab, setButtonTab] = useState(BUTTON_TYPES?.[0]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [error, setError] = useToggle(false);

  useEffect(() => {
    if (
      ['BUTTON', 'LIST']?.includes(node?.type) &&
      node?.data?.interactive?.footer?.text
    ) {
      const footer = node?.data?.interactive?.footer;
      const keys = Object?.keys(footer);

      const current = FOOTER_TYPES?.filter(
        (f) => f?.key?.toLowerCase() === keys?.[0]
      );
      setFooterTab(current?.[0] || FOOTER_TYPES?.[0]);
    }

    if (['TEMPLATE']?.includes(node?.type)) {
      const datas = {
        ...node?.data,
        name: node?.data?.template_name,
      };

      const value = datas?.template?.length > 0 ? datas : null;
      setSelectedTemplate(value);
    }
  }, [node]);

  useEffect(() => {
    const { selected, list } = headerTypes();
    setHeaderList(list);
  }, [node?.type, selectedTab]);

  useEffect(() => {
    const { selected, list } = headerTypes();

    if (selected?.length === 0) {
      setSelectedTab(list?.[0]);
    } else {
      setSelectedTab(selected?.[0]);
    }
  }, [node?.type]);

  const headerTypes = useCallback(() => {
    const HEADER_TYPES = ['BUTTON']?.includes(node?.type)
      ? [NONE_TYPE, TEXT_TYPE, ...MEDIA_TYPES]
      : ['LIST']?.includes(node?.type)
      ? [NONE_TYPE, TEXT_TYPE]
      : MEDIA_TYPES;

    const types = ['BUTTON', 'LIST']?.includes(node?.type)
      ? node?.data?.interactive?.header?.type
      : node?.data?.type;

    const selected = HEADER_TYPES?.filter(
      (h) => h?.key === types?.toUpperCase()
    );

    return { selected, list: HEADER_TYPES };
  }, [node]);

  const MessageTypesList = (
    <div className='w-full min-w-full flex flex-col gap-5 p-4'>
      {ACTIONS_LIST?.map((t) => {
        return (
          <div
            className='w-full flex items-center gap-2.5 cursor-pointer'
            onClick={() => {
              onTriggerClick({ data: t, node: node });
              setIsHeaderTypeChange(false);
            }}>
            <IconContainer color={t?.bg}>
              <t.icon color={t?.color} />
            </IconContainer>
            <p>{t.label}</p>
          </div>
        );
      })}
    </div>
  );

  const handleToastError = (message) => {
    dispatch(
      updateToggleToast([
        ...toggleToast,
        {
          id: toggleToast?.length + 1,
          content: message,
          status: 'Error',
          duration: '',
        },
      ])
    );
  };

  const showSubmit = useCallback(() => {
    const isHeader = isValidHeader({ node, selectedTab });
    const isBody = isValidBody({ node });
    const isFooter = isValidFooter({ node, footerTab });
    const isTemplate = isValidTemplate({ node });
    const isList = isValidList({ node });
    const isButtons = isValidButton({ node });

    return (
      !error &&
      isHeader &&
      isBody &&
      isFooter &&
      isTemplate &&
      isList &&
      isButtons
    );
  }, [error, node, isHeaderTypeChange, footerTab]);

  const handleBodyChange = (value) => {
    let payload = {};

    if (['BUTTON', 'LIST']?.includes(node?.type)) {
      payload = {
        primaryKey: 'data',
        secondaryKey: 'interactive',
        key: 'body',
        data: {
          text: value,
        },
      };
    } else {
      payload = {
        key: 'data',
        data: {
          type: node?.type?.toLowerCase(),
          [`${node?.type?.toLowerCase()}`]: {
            body: value,
          },
        },
      };
    }

    onChange({
      type: 'UPDATE_NODE',
      id: node?.uniq_id,
      ...payload,
    });
  };

  const handleFooterChanges = (value) => {
    let payload = {
      primaryKey: 'data',
      secondaryKey: 'interactive',
      key: 'footer',
      data: {
        text: value,
      },
    };

    onChange({
      type: 'UPDATE_NODE',
      id: node?.uniq_id,
      ...payload,
    });
  };

  const buildPayloadData = useCallback(() => {
    const payloadData = {};

    const isSameNode = originalData?.uniq_id === node?.uniq_id;
    const isSameType = originalData?.type === node?.type;

    if (node?.settings?.other_replies) {
      payloadData['other_replies'] = node?.settings?.other_replies;
    } else if (isObjectEmpty(node?.settings?.other_replies))
      payloadData['other_replies'] = {};

    if (node?.type === 'TEMPLATE') {
      payloadData['message_body'] = {
        type: selectedTemplate?.subcategory_type || selectedTemplate?.category,
        template_name: selectedTemplate?.name,
      };
      removeEdges(node?.uniq_id);
    } else {
      const data = { ...node?.data };

      if (data?.interactive?.header?.type === 'none')
        delete data?.['interactive']?.['header'];

      payloadData['message_body'] = data;
    }

    return { payloadData, isSameNode, isSameType };
  }, [originalData, node, selectedTemplate]);

  const submitNewNode = useCallback(
    async ({ payloadData }) => {
      const settings = {
        ...node?.settings,
        defaults: { ...node?.settings?.defaults, isEditMode: false },
      };

      const payload = {
        brand_id: currentBrand?.brand_id,
        campaign_id: campaign_id,
        node_type: node?.type,
        label: 'Send Message',
        coordinates: findEmptySpace(),
        save: true,
        message_body: payloadData?.message_body,
        settings,
      };

      if (payloadData?.other_replies)
        payload['other_replies'] = payloadData?.other_replies;
      try {
        const newNode = await createNode(sequence_id, payload);

        if (newNode?.status === 200) {
          const node = newNode?.data?.data;

          onChange({
            type: 'ADD_NODE',
            data: {
              ...node,
              data: !node?.data
                ? {
                    type: ['BUTTON', 'LIST']?.includes(node?.type)
                      ? 'interactive'
                      : node?.type?.toLowerCase(),
                  }
                : { ...node?.data },
            },
          });

          onChange({
            type: 'UPDATE_DATA',
            data: { selectedNode: null },
          });

          if (
            originalData?.uniq_id === node?.uniq_id &&
            originalData?.type !== node?.type
          )
            removeEdges(node?.uniq_id);
          setIsUpdating(false);
        } else {
          setIsUpdating(false);
          handleToastError(`Failed to create ${data?.key} NODE`);
        }
      } catch (error) {
        setIsUpdating(false);
        handleToastError(res?.data?.message);
      }
    },
    [node, originalData]
  );

  // Calculate the height difference
  const calculateHeight = (originalSize, updatedSize) => {
    return originalSize === updatedSize
      ? 0
      : originalSize < updatedSize
      ? updatedSize - originalSize - 10
      : updatedSize - originalSize + 10;
  };

  const updatedExistingNode = useCallback(
    async ({ payloadData, isSameNode, isSameType }) => {
      let updatedEdgesData = null;
      const originalNodeMedia = originalData?.data?.interactive?.header;
      const updatedNodeMedia = node?.data?.interactive?.header;

      // Check if original or updated nodes have a link
      const isOrginalNodeHasLink =
        originalNodeMedia?.[originalNodeMedia?.type]?.link !== '';
      const isUpdatedNodeHasLink =
        updatedNodeMedia?.[updatedNodeMedia?.type]?.link !== '';
      const isSameHeaderType =
        originalNodeMedia?.type === updatedNodeMedia?.type;

      if (isSameNode && isSameType) {
        const originalHeaderSize = DEFAULT_MEDIA_SIZE[originalNodeMedia?.type];
        const updatedHeaderSize = DEFAULT_MEDIA_SIZE[updatedNodeMedia?.type];

        if (isOrginalNodeHasLink && isUpdatedNodeHasLink && !isSameHeaderType) {
          const height = calculateHeight(originalHeaderSize, updatedHeaderSize);
          updatedEdgesData = updateEdgesCoordinates({
            startNodeId: originalData?.uniq_id,
            removedEdges: removedEdges,
            data: { height },
          });
        } else if (isOrginalNodeHasLink && !isUpdatedNodeHasLink) {
          const height =
            originalNodeMedia?.type === 'audio'
              ? originalHeaderSize + 20
              : originalHeaderSize + 10;
          updatedEdgesData = updateEdgesCoordinates({
            startNodeId: originalData?.uniq_id,
            removedEdges: removedEdges,
            data: { height: -height },
          });
        } else if (
          updatedNodeMedia &&
          !isOrginalNodeHasLink &&
          isUpdatedNodeHasLink
        ) {
          const height =
            updatedNodeMedia?.type === 'audio'
              ? updatedHeaderSize + 20
              : updatedHeaderSize + 10;
          updatedEdgesData = updateEdgesCoordinates({
            startNodeId: originalData?.uniq_id,
            removedEdges: removedEdges,
            data: { height },
          });
        } else if (removedEdges?.length > 0) {
          updatedEdgesData = updateEdgesCoordinates({
            startNodeId: originalData?.uniq_id,
            removedEdges: removedEdges,
            data: { height: 0 },
          });
        }
      }

      if (showSubmit() && allowToEditFlow) {
        let payload = {
          brand_id: currentBrand?.brand_id,
          campaign_id: campaign_id,
          node_type: node?.type,
          label: 'Whatsapp',
          save: true,
          message_body: {},
          ...payloadData,
        };

        if (updatedEdgesData?.currentNodeUpdatedEdges?.length > 0)
          payload.edges = updatedEdgesData.currentNodeUpdatedEdges;

        if (removeEdges?.length > 0) payload.deleted_edges = removedEdges;

        const sendNode = await createTextNode(
          sequence_id,
          node?.uniq_id,
          payload
        );

        if (sendNode?.status === 200) {
          onChange({
            type: 'UPDATE_NODE_DATA',
            id: node?.uniq_id,
            data: sendNode?.data?.data,
          });

          if (removeEdges?.length > 0) setRemovedEdges([]);

          const stateData = { selectedNode: null };

          if (isSameNode && isSameType) {
            const edgeData = updatedEdgesData?.edges;
            if (edgeData) stateData.edges = edgeData;
          } else {
            removeEdges(node?.uniq_id);
          }

          onChange({
            type: 'UPDATE_DATA',
            data: stateData,
          });

          setIsUpdating(false);
        } else {
          setIsUpdating(false);
          handleToastError(res?.data?.message);
        }
      }
    },
    [node, showSubmit, originalData, removedEdges, removeEdges, setRemovedEdges]
  );

  const allowToUpdateTemplateNode = useCallback(() => {
    return (
      ['TEMPLATE']?.includes(node?.type) &&
      (node?.data?.template_name === selectedTemplate?.name ||
        node?.data?.template_name === selectedTemplate?.template_name)
    );
  }, [node]);

  const handleOk = async () => {
    setIsUpdating(true);

    const { payloadData, isSameNode, isSameType } = buildPayloadData();

    try {
      if (showSubmit() && node?.status === 'UN_SAVED') {
        await submitNewNode({ payloadData });
      } else {
        if (allowToUpdateTemplateNode()) {
          if (toggleNodeEditMode)
            toggleNodeEditMode({
              id: node?.id,
              uniqId: node?.uniq_id,
              value: false,
              defaults: node?.settings?.defaults,
            });
          return;
        }

        await updatedExistingNode({ payloadData, isSameNode, isSameType });
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const allowToAddButton = () => {
    return node?.data?.interactive?.action?.buttons?.length < maxButtonCount;
  };

  const getEdgeId = (id) => {
    let res = node?.settings?.connections?.points?.filter((c) =>
      id?.includes(c?.id)
    );
    res = res?.map((res) => res?.edge?.slug);
    return res;
  };

  const onDeleteEdge = async (id) => {
    const edgeId = getEdgeId(id);
    if (edgeId?.length > 0) setRemovedEdges([...removedEdges, ...edgeId]);
  };

  const handleCancel = useCallback(() => {
    if (showSubmit() && node?.status !== 'UN_SAVED') {
      onChange({
        type: 'UPDATE_NODE_DATA',
        id: originalData?.uniq_id,
        data: {
          ...originalData,
          settings: {
            ...originalData?.settings,
            defaults: {
              ...originalData?.settings?.defaults,
              isEditMode: false,
            },
          },
        },
      });
    }

    if (node?.status === 'UN_SAVED') {
    }

    onChange({
      type: 'UPDATE_DATA',
      data: { selectedNode: null },
    });
  }, [node]);

  const title = 'text-sm weight-semibold text-[var(--font-600)] pb-2.5';

  return (
    <div className='w-full h-fit rounded-2xl bg-white overflow-hidden'>
      <div className='p-2.5 bg-[#04B8A2] rounded-t-2xl rounded-b-md'>
        <div className='flex items-center justify-between'>
          <p className='text-white'>Send Message</p>
          <button
            className={cn(
              'size-4 flex items-center justify-center cursor-pointer',
              node?.status === 'UN_SAVED' || showSubmit()
                ? 'cursor-pointer'
                : 'cursor-not-allowed'
            )}
            onClick={handleCancel}>
            <Xmark
              color={
                node?.status === 'UN_SAVED' || showSubmit()
                  ? 'white'
                  : 'var(--BG-100)'
              }
              width={12}
              height={12}
            />
          </button>
        </div>
      </div>
      <div className='px-3.5 bg-white rounded-b-2xl max-h-[60vh] overflow-y-scroll listScroll'>
        <div className='py-5 border-b border-gray-200'>
          <h5 className={cn('', title)}>Message Type</h5>
          <Popover
            open={isHeaderTypeChange}
            content={MessageTypesList}
            trigger={['click']}
            className='border border-gray-200 p-1 text-base rounded-md'
            arrow={false}
            onOpenChange={(open) => {
              if (allowToEditFlow) setIsHeaderTypeChange(open);
            }}>
            <div className='text-sm weight-semibold text-[#2D3036] py-2.5 px-4'>
              <p>{NODE_TYPES_CONVERTION[node?.type]}</p>
            </div>
          </Popover>
        </div>

        {node?.data?.type === 'template' ? (
          <TemplateMessage
            node={node}
            onChange={onChange}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            allowToEditFlow={allowToEditFlow}
          />
        ) : (
          <div className='flex flex-col gap-5 py-5'>
            {ALLOWED_HEADER_NODE_TYPES?.includes(node?.type) && (
              <HeaderMessage
                labelClassName={title}
                node={node}
                onChange={onChange}
                setError={setError}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                headerList={headerList}
                setHeaderList={setHeaderList}
                mediaRef={mediaRef}
              />
            )}

            {ALLOWED_BODY_NODE_TYPES?.includes(node?.type) && (
              <BodyMessage
                labelClassName={title}
                value={
                  ['BUTTON', 'LIST']?.includes(node?.type)
                    ? node?.data?.interactive?.body?.text || ''
                    : node?.data?.text?.body || ''
                }
                // node={node}
                limit={1024}
                onChange={handleBodyChange}
              />
            )}

            {ALLOWED_FOOTER_NODE_TYPES?.includes(node?.type) && (
              <div className=''>
                <h5 className={cn('pb-2.5', title)}>Footer</h5>
                <Tabs
                  tabs={FOOTER_TYPES}
                  selected={footerTab}
                  onTabChange={(data) => {
                    if (allowToEditFlow) {
                      setFooterTab(data);
                      const interactive = { ...node?.data?.interactive };
                      if (
                        data?.key === 'NONE' &&
                        Object?.keys(interactive)?.includes('footer')
                      ) {
                        delete interactive['footer'];
                        console.log('interactive', interactive);
                        onChange({
                          type: 'UPDATE_NODE',
                          id: node?.uniq_id,
                          primaryKey: 'data',
                          key: 'interactive',
                          data: interactive,
                        });
                      }
                    }
                  }}
                />
              </div>
            )}

            {ALLOWED_FOOTER_NODE_TYPES?.includes(node?.type) &&
              footerTab?.key === 'TEXT' && (
                <Input
                  value={node?.data?.interactive?.footer?.text}
                  limit={60}
                  onInputChange={handleFooterChanges}
                />
              )}

            {ALLOWED_BUTTONS_NODE_TYPES?.includes(node?.type) && (
              <div className=''>
                <h5 className={cn('pb-2.5', title)}>
                  {node?.type === 'LIST'
                    ? 'Button text'
                    : 'Quick Reply Buttons'}
                </h5>
                {node?.type === 'LIST' && (
                  <Input
                    value={node?.data?.interactive?.action?.button}
                    limit={60}
                    onInputChange={(value) => {
                      onChange({
                        type: 'UPDATE_LIST_NODE',
                        id: node?.id,
                        key: 'button',
                        value: value,
                      });
                    }}
                  />
                )}

                {node?.type === 'BUTTON' && (
                  <div className='my-2.5 flex flex-col gap-2.5'>
                    {node?.data?.interactive?.action?.buttons?.map((b, i) => {
                      return (
                        <QuickReplyButtons
                          index={i + 1}
                          onChange={onChange}
                          showDelete={
                            node?.data?.interactive?.action?.buttons?.length > 1
                          }
                          title={b?.reply?.title}
                          limit={25}
                          onInputChange={(value) => {
                            if (allowToEditFlow)
                              onChange({
                                type: 'UPDATE_INTERACTIVE_BUTTON',
                                id: node?.uniq_id,
                                key: i + 1,
                                value: value,
                              });
                          }}
                          deleteButton={async () => {
                            if (allowToEditFlow) {
                              await onDeleteEdge([b?.reply?.id]);
                              onChange({
                                type: 'DELETE_INTERACTIVE_BUTTON',
                                id: node?.id,
                                key: i + 1,
                              });
                            }
                          }}
                        />
                      );
                    })}
                    <button
                      className={cn(
                        'w-full h-8 text-sm text-[var(--font-600)] flex items-center justify-center border border-gray-200 border-dashed rounded-md',
                        allowToEditFlow && allowToAddButton()
                          ? 'opacity-100 cursor-pointer'
                          : 'opacity-50 cursor-not-allowed'
                      )}
                      onClick={() => {
                        if (allowToAddButton() && allowToEditFlow) {
                          onChange({
                            type: 'ADD_INTERACTIVE_BUTTON',
                            id: node?.id,
                          });
                        }
                      }}>
                      <AddIcon
                        stroke='var(--font-600)'
                        rect={false}
                        width={22}
                        height={22}
                      />
                      <p>Add button</p>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {node?.type === 'LIST' && (
          <div className='pb-4'>
            <ListMessage
              nodeId={node?.id}
              sections={node?.data?.interactive?.action?.sections}
              onChange={onChange}
              btnText={node?.data?.interactive?.action?.button}
              allowToEditFlow={allowToEditFlow}
              onDeleteEdge={onDeleteEdge}
            />
          </div>
        )}

        <div className=''>
          <DefaultReplay
            data={node?.settings?.other_replies}
            onChange={onChange}
            nodeId={node?.id}
          />
        </div>
      </div>

      <div
        className='w-full h-12 flex items-center justify-end px-5'
        style={{
          boxShadow: '-1px -3px 31px -11px rgba(0,0,0,0.75)',
          WebkitBoxShadow: '1px -3px 31px -11pxx rgba(0,0,0,0.75)',
          MozBoxShadow: '1px -3px 31px -11px rgba(0,0,0,0.75)',
        }}>
        {/* <div className='flex items-center gap-2.5'>
          <CheckBoxSqure
            value={node?.is_last_node}
            onChange={(value) => {
              if (allowToEditFlow)
                onChange({
                  type: 'UPDATE_NODE',
                  id: node?.uniq_id,
                  key: 'is_last_node',
                  value,
                });
            }}
            className='size-4'
          />
          <p className='text-sm weight-medium text-[var(--font-600)]'>
            Set as end node
          </p>
        </div> */}
        <button
          className={cn(
            'w-14 h-8 text-sm weight-semibold bg-[var(--primary)] text-white flex items-center justify-center border border-[var(--primary)] rounded-md',
            showSubmit()
              ? 'cursor-pointer opacity-100'
              : 'opacity-50 cursor-not-allowed'
          )}
          onClick={handleOk}>
          {isUpdating ? (
            <Loader
              Width={14}
              Height={14}
              loaderBg='white'
            />
          ) : (
            <div className='flex items-center justify-center gap-1.5'>
              <Tick
                width='12'
                height='10'
                strokeWidth='2.5'
                stroke='white'
              />
              <span>OK</span>
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default EditMessages;
